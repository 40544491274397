import { useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../../api/axios';
import { Button, Form, Input, Label } from "../../styles";
import { useUserContext } from '../../context/user_context';
import { ICON_MARK_STYLE, ICON_PEN } from '../../utils/icons';
import { useWalletContext } from '../../context/wallet_context';

const UpdateBVN = ({ setIsBVNAdded }) => {

    const [sending, setsending] = useState(false);
    const [toggleShow, settoggleShow] = useState(false);

    const {
        usertoken, userData, getUserData
    } = useUserContext();
    const {
        getAcctNo: { acctNo },
    } = useWalletContext();
    const [formField, setformField] = useState({
        bvn: userData?.bvn
    });

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (formField?.bvn?.length < 11) {
            toast.warn("BVN is not complete");
            return;
        }
        if (formField?.bvn?.length > 11) {
            toast.warn("BVN is not valid");
            return;
        }
        if (
            formField.bvn
        ) {
            setsending(true);
            const data = {

                usertoken,
                bvn: formField.bvn,
            };
            Api.post(`/updateBVN`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        getUserData();
                        setsending(false);
                        clear();
                        setIsBVNAdded(true);
                        settoggleShow(false);
                        toast.success(res.message);
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }

    }
    function clear() {
        setformField({ bvn: "" });
    }
    // Submit Function

    return (
        <>

            <div className="mt-5">
                <div className="row ">
                    <div className="col-md-8">
                        <h5 className="grey-bold-text">
                            Update Bank Verification Number (BVN)
                            <span className="small fw-light">  (compulsory)</span>

                        </h5>
                    </div>
                    {(userData.bvn) ?
                        <>
                            {acctNo ?
                                <>
                                    <div className="col-md-4">
                                        <h6 className="text-success fw-bold pointer"
                                        >
                                            Verified {ICON_MARK_STYLE}
                                        </h6>
                                    </div>
                                </> :
                                <>
                                    <div className="col-md-4">
                                        <h6 className="text-warning fw-bold pointer"
                                        >
                                            Pending {ICON_MARK_STYLE}
                                        </h6>
                                    </div>
                                </>
                            }
                        </>
                        :
                        <>
                            {!toggleShow &&
                                <div className="col-md-4">
                                    <h6 className="pry-text fw-bold pointer"
                                        onClick={() => settoggleShow(true)}
                                    >
                                        Update BVN {ICON_PEN}
                                    </h6>
                                </div>
                            }
                        </>
                    }
                </div>
                {toggleShow &&
                    <Form onSubmit={(e) => submitFn(e)}>

                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    BVN Digits
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 password-input">
                                <div className="input-group">
                                    <Input
                                        type='number'
                                        name="bvn"
                                        placeholder="12345678901"
                                        onChange={handleChange}
                                        value={formField.bvn}
                                        required
                                    // disabled
                                    />
                                </div>
                            </div>


                        </div>
                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">

                            </div>
                            <div className="col-md-7 col-lg-7">
                                <Button
                                    pry
                                    type="submit"
                                    disabled={sending}
                                    className="btn m-0 w-100"  >
                                    {sending ? 'loading...' : "Update"}
                                </Button>
                            </div>
                        </div>

                    </Form>
                }
            </div>
        </>
    );
};

export default UpdateBVN;