import React, { useContext } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../api/axios';
import FetchApi from '../api/getAxios';
import { useUserContext } from './user_context';

export const DisputeContext = React.createContext();


export const DisputeProvider = ({ children }) => {

    // Create new Dispute Actions
    const { usertoken } = useUserContext();

    const [historyData, sethistoryData] = useState([]);
    const [endpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [DisputeLoading, setDisputeLoading] = useState(false);
    const [allDispute, setallDispute] = useState([]);

    // Get all Dispute Trx
    const getDispute = async (e) => {
        setDisputeLoading(true);
        const data = { usertoken };
        FetchApi.post(`${ endpoint }/getDisputeList`, data)
            .then((res) => {
                if (res.success === false) {
                    setDisputeLoading(false);
                } else {
                    setDisputeLoading(false);
                    setallDispute(res.data.data);
                    sethistoryData(res.data.data);
                }
            })
            .catch((error) => {
                setDisputeLoading(false);
            });
    };
    // Get all Dispute Trx


    // Sort Dispute Trx
    function selectData(e, setselectedLink) {
        if (e === 'successful') {
            setselectedLink('successful');
            sethistoryData(allDispute?.filter((e) => e.resolved === "1"));
        } else if (e === 'pending') {
            setselectedLink('pending');
            sethistoryData(allDispute?.filter((e) => e.status === "0"));
        } else if (e === 'closed') {
            setselectedLink('closed');
            sethistoryData(allDispute?.filter((e) => (e.cancelled === "1") || (e.refunded === "1")));
        } else {
            setselectedLink('all');
            sethistoryData(allDispute);
        }
    }

    // Sort Dispute Trx

    // Search Dispute Trx
    function searchTrx(key, setselectedLink) {
        if (key) {
            setselectedLink('all');
            const filteredTrx = allDispute.filter((trx) => {
                const titleMatch = trx?.title.toLowerCase().includes(key.toLowerCase());
                const amountMatch = trx?.amount.includes(key);
                const tokenMatch = trx?.token.includes(key);
                return titleMatch || amountMatch || tokenMatch;
            });
            sethistoryData(filteredTrx);
        } else {
            setselectedLink('all');
            sethistoryData(allDispute);
        }
    }
    // Search Dispute Trx

    const [chatSending, setchatSending] = useState(false);

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    // Send a chat
    function sendChat(e, formField, props, clear) {
        const { disputeToken, getDisputeChat, getDisputeDetails } = props;
        e.preventDefault();
        if (
            formField.chat && disputeToken
        ) {
            setchatSending(true);
            const data = {
                usertoken,
                disputeToken,
                chat: formField.chat,
                file: formField.file,
                filetype: formField.type,
            };
            Api.post(`/chatDispute`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setchatSending(false);
                    } else {
                        getDisputeChat();
                        getDisputeDetails();
                        toast.success(res.message);
                        setchatSending(false);
                        clear();
                        scrollTop();
                    }
                })
                .catch((error) => {
                    setchatSending(false);
                });
        } else {
            setchatSending(false);
            toast.warn("Enter a message!");
        }
    }

    // Send a chat



    return (
        <DisputeContext.Provider value={{
            getAllDispute: { DisputeLoading, allDispute, historyData, getDispute, selectData, searchTrx, setallDispute },
            chat: { sendChat, chatSending },

        }}>
            {children}
        </DisputeContext.Provider>
    );
};

// make sure use
export const useDisputeContext = () => {
    return useContext(DisputeContext);
};
