import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_EXCLAMATION } from "../../../utils/icons";
import { Button, Form, PinInput } from "../../../styles";
import SuccessTransfer from "../SuccessTransfer";
import { StyleConfirmFunds } from "../Wallet.styled";
import { useSendFundContext } from "../../../context/send_fund_context";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import AppLoader from "../../../utils/AppLoader";


const ConfirmSendToUsername = (props) => {
  const [issending, setissending] = useState(false);
  const { usertoken } = useUserContext();

  const { formField } = props;
  const [trxPin, setTrxPin] = useState('');

  const {
    confirmUsername: { showConfirmUsername, handleCloseConfirmUsername },
    success: { handleShowSuccess },

  } = useSendFundContext();

  const openSuccess = () => handleShowSuccess();

  // Send Money to Username
  function submitFn(e) {
    e.preventDefault();
    if (trxPin.length !== 4) {
      toast.warn('PIN must be 4 digits');
      return;
    }
    if (
      formField.u_name, formField.amount
    ) {
      setissending(true);
      const data = {

        usertoken,
        amount: formField.amount,
        r_username: formField.u_name,
        pin: trxPin
      };
      Api.post(`/transferToUsername`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
          } else {
            toast.success(res.message);
            setissending(false);
            openSuccess();
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Input Account Number!");
    }
  }
  // Send Money to Username

  return (
    <>
      <Modal
        show={showConfirmUsername}
        onHide={handleCloseConfirmUsername}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            CONFIRM PAYMENT
          </p>
        </Modal.Header>
        <Modal.Body>
          <AppLoader
            load={issending}
          />
          <StyleConfirmFunds>
            <div className="mb-5 pb-5">
              <h1 className="yellow-text display-2">
                {ICON_EXCLAMATION}
              </h1>

              <h5 className="">
                Are you sure?
              </h5>
              <h5 className="fw-light">
                You are about to send
                <span className="fw-bold"> {naira}{th(formField.amount)}</span> to
                <span className="fw-bold"> {formField.r_name} ({formField.u_name}) </span>
              </h5>
            </div>

            <Form onSubmit={(e) => submitFn(e)}>

              <div className="col-md-12">
                <div className="input-group">
                  <PinInput
                    type='password'
                    name="pin"
                    placeholder="PIN"
                    onChange={(e) => setTrxPin(e.target.value)}
                    value={trxPin}
                    autoComplete="off"
                    maxLength='4'
                    required
                  />
                </div>
              </div>

              <Button
                pry
                type="submit"
                disabled={issending}
                className="btn w-100"
              >
                {issending ? 'loading...' : "Proceed"}
              </Button>
            </Form>

          </StyleConfirmFunds>
        </Modal.Body>
      </Modal>

      <SuccessTransfer
        title={'Transfer'}
        data={formField}

      />
      <AppLoader
        load={issending}
      />

    </>
  );
};

export default ConfirmSendToUsername;


