import TransactionHistory from '../../components/transactions/TransactionHistory';
import WalletLinks from '../../components/wallet/WalletLinks';
import { StyledWallet } from '../../components/wallet/Wallet.styled';
import WalletHeader from '../../components/wallet/WalletHeader';
import AppWrapper from '../../layout/AppWrapper';

const Wallet = () => {
  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledWallet>
        <WalletHeader />
        <WalletLinks />

        <div className="row">
          <div className="col-md-8 mb-3">
            <TransactionHistory
              reduce={10}
            />
          </div>
        </div>

      </StyledWallet>
    </AppWrapper >
  );
};

export default Wallet;