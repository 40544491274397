import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useFetch } from '../api/useFetch';
import { useUserContext } from './user_context';
import FetchApi from '../api/getAxios';


export const SendFundContext = React.createContext();


export const SendFundProvider = ({ children }) => {

    const { usertoken } = useUserContext();

    // Banks
    const { loading: allBanksLoading, data: bankList, fetchData: getBank } = useFetch('getBankList');

    const allBanks = bankList?.bank?.map(({ bank_name, bank_code }, i) => {
        return (
            { label: bank_name, value: bank_code }
        );
    });

    // ConfirmBankModal
    const [showConfirmBank, setShowConfirmBank] = useState(false);
    const handleCloseConfirmBank = () => setShowConfirmBank(false);
    const handleShowConfirmBank = (e, data) => {
        const { selectUser, formField } = data;
        e.preventDefault();
        if (!selectUser) {
            toast.warn('Unable to verify Bank Account!');
            return;
        }
        if (!formField?.amount) {
            toast.warn('Amount Field is empty!');
            return;
        }
        if (!formField.narration) {
            toast.warn('Please add a narration!');
            return;
        }
        setShowConfirmBank(true);
    };
    // ConfirmBankModal

    // Banks

    // Username
    const { loading: allUserLoading, data: userList, fetchData: getUser } = useFetch('getBankList');


    const [loadingName, setloadingName] = useState(false);
    const [message, setmessage] = useState('');


    function searchUser(username, setformField, setselectUser) {
        if (
            username
        ) {
            setloadingName(true);
            const data = {
                usertoken,
                username: username,
            };
            FetchApi.post(`/check.Username`, data)
                .then((res) => {
                    if (res.success === false) {
                        setloadingName(false);
                        setselectUser('');
                        setformField(prevState => ({ ...prevState, r_name: '' }));
                        setmessage(res.message);
                    } else {
                        setloadingName(false);
                        setselectUser(res.data.data);
                        setformField(prevState => ({ ...prevState, r_name: res.data.data.fullname, u_name: res.data.data.username }));

                    }
                })
                .catch((error) => {
                    setloadingName(false);
                });
        } else {
            setloadingName(false);
        }
    }



    // ConfirmUsernameModal
    const [showConfirmUsername, setShowConfirmUsername] = useState(false);
    const handleCloseConfirmUsername = () => setShowConfirmUsername(false);
    const handleShowConfirmUsername = (e, data) => {
        const { selectUser, formField } = data;
        e.preventDefault();
        if (!selectUser) {
            toast.warn('Unable to verify Username!');
            return;
        }
        if (!formField?.amount) {
            toast.warn('Amount Field is empty!');
            return;
        }
        setShowConfirmUsername(true);
    };
    // ConfirmUsernameModal

    // Username

    // Beneficiary
    const { loading: allBenLoading, data: benList, fetchData: getBen } = useFetch('getBeneficiaries-bank');


    // ConfirmBeneficiaryModal
    const [showConfirmBen, setShowConfirmBen] = useState(false);
    const handleCloseConfirmBen = () => setShowConfirmBen(false);
    const handleShowConfirmBen = () => setShowConfirmBen(true);
    // ConfirmBeneficiaryModal

    // Beneficiary

    // Pay Request
    const { loading: allRequestLoading, data: requestList, setdata: setrequestList, fetchData: getRequestList } = useFetch('getFundRequests');

    // ConfirmPayRequestModal
    const [showConfirmPayRequest, setShowConfirmPayRequest] = useState(false);
    const handleCloseConfirmPayRequest = () => setShowConfirmPayRequest(false);
    const handleShowConfirmPayRequest = () => setShowConfirmPayRequest(true);
    // ConfirmPayRequestModal

    // ConfirmDeclineRequestModal
    const [showConfirmDeclineRequest, setShowConfirmDeclineRequest] = useState(false);
    const handleCloseConfirmDeclineRequest = () => setShowConfirmDeclineRequest(false);
    const handleShowConfirmDeclineRequest = () => setShowConfirmDeclineRequest(true);
    // ConfirmDeclineRequestModal
    // Pay Request

    // Request QR
    // SuccessRequestQrModal
    const [showSuccessRequestQr, setShowSuccessRequestQr] = useState(false);
    const handleCloseSuccessRequestQr = () => setShowSuccessRequestQr(false);
    const handleShowSuccessRequestQr = () => setShowSuccessRequestQr(true);
    // SuccessRequestQrModal

    // ClaimQrFundModal
    const [showClaimQrFund, setShowClaimQrFund] = useState(false);
    const handleCloseClaimQrFund = () => setShowClaimQrFund(false);
    const handleShowClaimQrFund = () => setShowClaimQrFund(true);
    // ClaimQrFundModal
    // Request QR


    // SuccessModal
    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = (e) => {
        setShowSuccess(true); handleCloseConfirmUsername(); handleCloseConfirmBank(); handleCloseConfirmPayRequest(); handleCloseConfirmDeclineRequest(); handleCloseClaimQrFund();
    };
    // SuccessModal
    // PendingModal
    const [showPending, setShowPending] = useState(false);
    const handleClosePending = () => setShowPending(false);
    const handleShowPending = (e) => {
        setShowPending(true);  handleCloseConfirmBank(); handleCloseConfirmPayRequest();
    };
    // SuccessModal

    return (
        <SendFundContext.Provider value={{
            getData: { getUser, getRequestList, getBank, getBen },
            banks: { allBanks, allBanksLoading, getBank },
            confirmBank: { showConfirmBank, handleCloseConfirmBank, handleShowConfirmBank },

            user: { searchUser, message, loadingName, allUserLoading, userList, getUser },
            confirmUsername: { showConfirmUsername, handleCloseConfirmUsername, handleShowConfirmUsername },

            beneficiary: { benList, allBenLoading, getBen },
            confirmBen: { showConfirmBen, handleCloseConfirmBen, handleShowConfirmBen },

            payRequest: { allRequestLoading, requestList, getRequestList, setrequestList },
            confirmPayRequest: { showConfirmPayRequest, handleCloseConfirmPayRequest, handleShowConfirmPayRequest },
            confirmDeclineRequest: { showConfirmDeclineRequest, handleCloseConfirmDeclineRequest, handleShowConfirmDeclineRequest },

            successRequestQr: { showSuccessRequestQr, handleCloseSuccessRequestQr, handleShowSuccessRequestQr, setShowSuccessRequestQr },

            claimQrFund: { showClaimQrFund, handleCloseClaimQrFund, handleShowClaimQrFund, setShowClaimQrFund },

            success: { showSuccess, handleCloseSuccess, handleShowSuccess, setShowSuccess },
            pending: { showPending, handleClosePending, handleShowPending, setShowPending },
        }}>
            {children}
        </SendFundContext.Provider>
    );
};

// make sure use
export const useSendFundContext = () => {
    return useContext(SendFundContext);
};
