import { useState } from "react";
import { toast } from "react-toastify";
import { user } from "../../assets";
import { useProfileContext } from "../../context/profile_context";
import { useUserContext } from "../../context/user_context";
import { Button, Form, Input, Label } from "../../styles";
import AppLoader from "../../utils/AppLoader";
import { ICON_LOCK, ICON_PEN, ICON_UNVERIFIED, ICON_VERIFIED } from "../../utils/icons";
import { StyledAccount } from "./Profile.styled";


const AccountSettings = () => {
    const {
        userData,
        userPhoto,
    } = useUserContext();

    const {
        editData: { editUsername, editPhone, editAddress, editAvatar }
    } = useProfileContext();

    const [formField, setformField] = useState({
        u_name: userData?.username,
        phone: userData?.phone,
        email: userData?.email,
        address: userData?.address,
        photo: userPhoto,
    });
    const [loading, setloading] = useState(false);

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    const [u_edit, setu_edit] = useState(false);
    const showUsernameEdit = () => setu_edit(!u_edit);
    const [p_edit, setp_edit] = useState(false);
    const showPhoneEdit = () => setp_edit(!p_edit);
    const [a_edit, seta_edit] = useState(false);
    const showAddressEdit = () => seta_edit(!a_edit);


    // Select Image for Upload
    function convert(e) {
        // check max. file size is not exceeded
        // size is in bytes
        if (e.target.files[0]?.size > 2000000) {
            toast.warn("File too large");
            return;
        }
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            editAvatar(e, reader.result, setloading);
            setformField({ ...formField, photo: reader.result });

        };
        reader.onerror = error => {
        };
    };
    // Select Image for Upload

    return (
        <StyledAccount>

            <AppLoader
                load={loading}
            />

            <div className="my-2 row text-center text-lg-start">
                <div className="col d-flex mx-auto justify-content-center ">
                    <img
                        src={(formField?.photo ? formField?.photo : userPhoto) || user} alt="profile_pic" />
                    <div class="file-upload">
                        <input
                            accept="image/*"
                            type="file"
                            onChange={(e) => convert(e)}
                        />
                        <span className="pry-text ms-2"
                        >
                            {ICON_PEN}
                        </span>
                    </div>
                </div>

                <div className="col-md-10 mt-4 mt-lg-0 mx-auto me-lg-auto">

                    <h5>
                        {userData?.fullname}
                        {userData?.isBVN ?
                            <span className="pry-text ms-2 m-0">
                                {ICON_VERIFIED}
                            </span>
                            :
                            <span className="grey-text ms-2 m-0">
                                {ICON_UNVERIFIED}
                            </span>
                        }
                    </h5>

                    <p>
                        {userData?.email}
                    </p>
                </div>
            </div>


            <div className="mt-5">
                <h5 className="grey-bold-text">
                    Personal info
                </h5>

                <div className="row justify-content-start">
                    <div className="col-md-5 col-lg-2">
                        <Label>
                            Email Address
                        </Label>
                    </div>
                    <div className="col-md-7 col-lg-7 d-flex">
                        <Input
                            type='email'
                            name="email"
                            value={formField.email}
                            className='mb-0'
                            onChange={handleChange}
                            required
                            disabled
                        />
                        <span className="pry-text ms-2 mt-2"
                        >
                            {ICON_LOCK}
                        </span>
                    </div>
                </div>

                <Form onSubmit={(e) => editUsername(e, formField.u_name, setloading, showUsernameEdit)}>
                    <div className="row justify-content-start">
                        <div className="col-md-5 col-lg-2">
                            <Label>
                                Username
                            </Label>
                        </div>
                        <div className="col-md-7 col-lg-7 d-flex">

                            <Input
                                type='text'
                                name="u_name"
                                value={formField.u_name}
                                className='mb-0'
                                onChange={handleChange}
                                required
                                disabled={!u_edit}
                            />
                            <span className="pry-text ms-2 mt-2"
                                onClick={() => showUsernameEdit()}
                            >
                                {ICON_PEN}
                            </span>
                        </div>
                        {u_edit &&
                            <div className="row justify-content-start mb-4">
                                <div className="col-md-5 col-lg-2">

                                </div>
                                <div className="col-md-7 col-lg-7 ">
                                    <Button
                                        pry
                                        type="submit"
                                        disabled={loading}
                                        className="btn m-0 w-100"  >
                                        {loading ? 'loading...' : "Save"}
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </Form>

                <div className="row justify-content-start">
                    <div className="col-md-5 col-lg-2">
                        <Label>
                            Phone Number
                        </Label>
                    </div>
                    <div className="col-md-7 col-lg-7 d-flex">

                        <Input
                            type='number'
                            name="phone"
                            value={formField.phone}
                            className='mb-0'
                            onChange={handleChange}
                            required
                            disabled={!p_edit}
                        />
                        <span className="pry-text ms-2 mt-2"
                            onClick={() => showPhoneEdit()}
                        >
                            {ICON_PEN}
                        </span>
                    </div>
                    {p_edit &&
                        <div className="row justify-content-start mb-4">
                            <div className="col-md-5 col-lg-2">

                            </div>
                            <div className="col-md-7 col-lg-7 ">
                                <Button pry className='btn btn-sm w-100'
                                    onClick={(e) => editPhone(e, formField.phone, setloading, showPhoneEdit)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>

                    }
                </div>
                <div className="row justify-content-start">
                    <div className="col-md-5 col-lg-2">
                        <Label>
                            Address
                        </Label>
                    </div>
                    <div className="col-md-7 col-lg-7 d-flex">

                        <Input
                            type='text'
                            name="address"
                            value={formField.address}
                            className='mb-0'
                            onChange={handleChange}
                            required
                            disabled={!a_edit}
                        />
                        <span className="pry-text ms-2 mt-2"
                            onClick={() => showAddressEdit()}
                        >
                            {ICON_PEN}
                        </span>
                    </div>
                    {a_edit &&
                        <div className="row justify-content-start mb-4">
                            <div className="col-md-5 col-lg-2">

                            </div>
                            <div className="col-md-7 col-lg-7 ">
                                <Button pry className='btn btn-sm w-100'
                                    onClick={(e) => editAddress(e, formField.address, setloading, showAddressEdit)}
                                >
                                    Saven
                                </Button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </StyledAccount>
    );
};

export default AccountSettings;
