import AppWrapper from "../../layout/AppWrapper";
import TransactionHistory from "./TransactionHistory";


const AllTransactions = () => {

    return (
        <>
            <AppWrapper
                pagename={"Transactions"}
            >
                <div className="col-lg-8 mx-auto">
                    <TransactionHistory
                    />
                </div>
            </AppWrapper>

        </>
    );
};

export default AllTransactions;