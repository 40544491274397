import styled from "styled-components";
import { Button } from "../../styles";
import { ICON_ARROW_RIGHT } from "../../utils/icons";
import { Link } from "react-router-dom";
import { features } from "../../utils/data";

const Features = () => {

      return (
            <StyledFeatures>
                  <div className="row">
                        <div className="col-md-4 my-5">
                              <h4 className="mb-4">
                                    All-in-one payment
                                    system powered
                                    by escrow
                              </h4>
                              <p className="mb-4 small">
                                    Save time, resource and energy by utilizing the
                                    amazing features provided by Renitrust
                              </p>
                              <Link to='/register'>
                                    <Button dark className="btn br-md">
                                          Get Started
                                    </Button>
                              </Link>
                        </div>
                        <div className="col-md-8">
                              <div className="row">
                                    {features?.map(({ title, content, icon, link, to, color }, i) => {
                                          return (
                                                <div className="col-md-6 my-3" key={i}>
                                                      <div className="feature-card h-100 container-fluid br-md py-4 shadow-sm">
                                                            <span className={`${ color } icon`}>
                                                                  {icon}
                                                            </span>
                                                            <h5>
                                                                  {title}
                                                            </h5>
                                                            <p>
                                                                  {content}
                                                            </p>

                                                            <div className="mt-5">
                                                                  <Link to={link}>
                                                                        <span className={`${ color } p-1 br-xlg`}>{ICON_ARROW_RIGHT} </span>

                                                                        <small className="dark-text ps-2 m-0 text-decoration-underline">{to} </small>
                                                                  </Link>
                                                            </div>
                                                      </div>
                                                </div>
                                          );
                                    })
                                    }
                              </div>
                        </div>
                  </div>
            </StyledFeatures>
      );
};

export default Features;


const StyledFeatures = styled.section`
      padding: 5rem 0;

      .feature-card{
      background-color: var(--home-grey) ;

      .icon {
            border-radius: 10px  10px 10px 0 ;
            padding:10px;
            
      }
      
      .primary {
            color: #003399;
            background: #E4FFF0;
      }
      .green {
            color: #069B40;
            background: #E0EFFF;
      }
      .blue {
            color: #3296FF;
            background: #E3F5FF;
      }
      .pink {
            color: #E5288E;
            background: #FDE7F5;
      }

      h5{
            margin:30px 0 20px;
            font-size: 18px;
      }
      p{
            font-size: 12px;
            line-height: 20px;

      }
      }


`;