import styled from "styled-components";
import AppWrapper from "../../../layout/AppWrapper";
import ConfirmSendToUsername from "../SendToUsername/ConfirmSendToUsername";
import { StyledSendFund } from "../Wallet.styled";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input, Label } from "../../../styles";
import { useSendFundContext } from "../../../context/send_fund_context";
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { user } from "../../../assets";
import { ICON_PLANE, ICON_RECEIVE_MONEY } from "../../../utils/icons";



const QrProfile = () => {

      let location = useLocation();
      let navigate = useNavigate();
      const [locationData, setlocationData] = useState({ state: { fname: '', username: '', photo: '', email: '', } });

      const { state: { fname, username, photo, email } } = locationData;


      const [formField, setformField] = useState({
            u_name: username, amount: '', r_name: fname
      });
      const [proceed, setproceed] = useState(false);
      const [selectUser, setselectUser] = useState('');

      // Form Fields
      function handleChange(e) {
            setformField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value,
            }));
      }
      // Form Field

      const {
            confirmUsername: { handleShowConfirmUsername },
      } = useSendFundContext();

      function checkSendDetails(e) {
            setformField({
                  u_name: username, amount: '', r_name: fname
            });
            setselectUser({
                  u_name: username, amount: '', r_name: fname
            });
            setproceed(true);
      }

      const data = { formField, selectUser };

      useEffect(() => {
            if (!location.state) {
                  toast.error('page not valid, try again!');
                  navigate('/user/dashboard');
                  return;
            } else {
                  setlocationData(location);
            }
      }, []);

      return (
            <AppWrapper
                  pagename={"Wallet"}
            >

                  <StyledSendFund>

                        <SendLayout
                              title={''}
                        >

                              <div className="text-center">

                                    <img src={photo || user} alt="User Profile" width='100%' />

                                    <div className="my-4">
                                          <h4 className="pry-text m-0">
                                                {fname || 'Jane Doe'}
                                          </h4>
                                          <p className="grey-bold-text">
                                                @{username || 'jane'}
                                          </p>
                                    </div>
                              </div>
                              {!proceed &&
                                    <div className="d-flex">
                                          <div className="col-lg-12 pointer fit-content mx-auto"
                                                onClick={(e) => checkSendDetails(e)}
                                          >
                                                <h1 className="text-success p-2 br-xlg fit-content mx-auto m-0">
                                                      {ICON_PLANE}
                                                </h1>
                                                <small className="mx-auto fit-content">
                                                      Send Money
                                                </small>
                                          </div>
                                          <div className="col-lg-12 pointer fit-content mx-auto"
                                          // onClick={(e) => checkSendDetails(e)}
                                          >
                                                <h1 className="red-text p-2 br-xlg fit-content mx-auto m-0">
                                                      {ICON_RECEIVE_MONEY}
                                                </h1>
                                                <small className="mx-auto fit-content">
                                                      Request Money
                                                </small>
                                          </div>
                                    </div>
                              }

                              {/* Send Form */}
                              <Form
                                    className="mb-5"
                              >
                                    <div className="justify-content-center text-left">

                                          <div className="col-lg-12 ">


                                                {proceed &&
                                                      <>
                                                            <Label htmlFor="amount">
                                                                  Amount:
                                                            </Label>

                                                            <Input
                                                                  type="number"
                                                                  name="amount"
                                                                  id="amount"
                                                                  placeholder={`Input Amount`}
                                                                  className='mb-1'
                                                                  onChange={handleChange}
                                                                  value={formField.amount}
                                                                  required
                                                            />

                                                            <Label htmlFor="details">
                                                                  Narration:
                                                            </Label>
                                                            <Input
                                                                  type="text"
                                                                  name="details"
                                                                  id="details"
                                                                  placeholder="Input Narration"
                                                                  onChange={handleChange}
                                                                  value={formField.details}
                                                                  required
                                                            />
                                                            <div className="col-lg-12">
                                                                  <Button
                                                                        pry
                                                                        className="btn w-100"
                                                                        onClick={(e) => handleShowConfirmUsername(e, data)}
                                                                  >
                                                                        Proceed
                                                                  </Button>
                                                            </div>
                                                      </>
                                                }
                                          </div>
                                    </div>
                              </Form>

                              {/* Send Form */}


                        </SendLayout>
                  </StyledSendFund>

                  <ConfirmSendToUsername
                        {...data}
                  />

            </AppWrapper >
      );
};

export default QrProfile;

const StyledSQrProfile = styled.div`

`;


