import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logo_blue_bg, logo_white } from "../../assets";
import { useDisputeContext } from "../../context/dispute_context";
import { useEscrowContext } from "../../context/escrow_context";
import { useSendFundContext } from "../../context/send_fund_context";
import { useWalletContext } from "../../context/wallet_context";
import { Button } from "../../styles";
import { navLink } from "../../utils/data";
import { removeData } from "../../utils/helpers";
import { ICON_LOGOUT } from "../../utils/icons";
import { useThemeContext } from "../../context/theme_context";

const NavLinks = () => {

  const navigate = useNavigate();

  const {
    getAllEscrow: { setallEscrow },
  } = useEscrowContext();

  const {
    getAllDispute: { setallDispute },
  } = useDisputeContext();
  const {
    getAcctBal: { setacctBal },
    getTrxHist: { settrxHist }

  } = useWalletContext();
  const {
    payRequest: { setrequestList },
  } = useSendFundContext();
  const {
    darkMode: { darkTheme }
  } = useThemeContext();


  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); };
  // Modal

  function logout() {
    setallEscrow([]);
    setallDispute([]);
    setacctBal([]);
    settrxHist([]);
    setrequestList([]);
    removeData('userData');
    toast.success('Logout successful! Redirecting...');
    setTimeout(() => {
      navigate("/");
      // window.location.reload();
    }, 2000);
  }

  return (
    <>
      <div className="ml-3">
        <Link to="/user/dashboard" className="navbar-brand white-text">
          <img src={darkTheme === 'dark' ? logo_white : logo_blue_bg} alt="Reni Logo" width='50%' className="mx-auto" />
        </Link>
      </div>
      <ul className="navbar-nav ml-auto mr-auto mt-3">

        {navLink.map(({ title, to, icon }, i) => {
          return (
            <li className="nav-item" key={i}>
              <NavLink to={to}>
                {icon}
                {title}
              </NavLink>
            </li>
          );
        })}

        <li className="nav-item">
          <a className="red-text pointer"
            onClick={() => handleShow()}
          >
            {ICON_LOGOUT}
            Logout
          </a>
        </li>
      </ul>
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body >
          <div className=" text-center ">
            <div className="red-text br-lg shadow fit-content mx-auto px-3 mb-3" style={{ fontSize: '3rem' }}>
              {ICON_LOGOUT}
            </div>
            <h5 className="my-5">
              Are you sure you want to logout?
            </h5>

            <div className="d-flex gap-2">
              <Button className="btn red-bg light-text w-50"
                onClick={() => logout()}>
                Confirm
              </Button>
              <Button className="btn border border-danger red-text w-50"
                onClick={() => handleClose()}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};

export default NavLinks;
