import { useEffect, useState } from "react";
import { useBillsContext } from "../../../context/bill_context";
import { Form, Label, Input, Button } from "../../../styles";
import Select from 'react-select';
import { toast } from "react-toastify";
import ConfirmTv from "./ConfirmTv";
import { naira } from "../../../utils/data";
import BillWrapper from "../../../layout/BillWrapper";
import { Fee, customOption } from "../../../utils/helpers";
import { useWalletContext } from "../../../context/wallet_context";
import CustomSelect from "../../../utils/CustomSelect";


const BuyTv = () => {

    const [formField, setformField] = useState({ smartCardNo: '', amount: '' });

    const [selectedBiller, setselectedBiller] = useState('');
    const [selectedTvBiller, setselectedTvBiller] = useState('');

    const {
        tv: { allTvBiller, allTvProvider, allTvProviderLoad, getTvPack, tvLoader, getTVProvider },
    } = useBillsContext();
    const {
        getCharges: { charges },
    } = useWalletContext();

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    useEffect(() => {
        if (selectedBiller?.type) {
            getTvPack(selectedBiller?.type, setselectedTvBiller);
        }
    }, [selectedBiller?.type]);

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        if (!selectedBiller?.value) {
            toast.warn('Select a Service Provider!');
            return;
        }
        if (!selectedTvBiller?.value) {
            toast.warn('Select a Package!');
            return;
        }
        if (!formField?.smartCardNo) {
            toast.warn('Input Smart Card Number!');
            return;
        }
        setShow(true);
    };
    // Modal

    const data = { formField, selectedBiller, selectedTvBiller };

    useEffect(() => {
        getTVProvider();
    }, []);
    return (
        <BillWrapper
            title='Cable Tv'
            load={(tvLoader || allTvProviderLoad)}
        >
            <Form>
                <div className="col-lg-6 input-col">
                    <Label htmlFor="operator">
                        SERVICE PROVIDER:
                    </Label>
                    <CustomSelect
                        variant="-bill"
                        id="biller"
                        options={allTvProvider}
                        isSearchable={true}
                        onChange={(e) => setselectedBiller(e)}
                        value={selectedBiller}
                        placeholder='Select Service Provider'
                        required
                        components={{ Option: customOption }}

                    />
                </div>

                {selectedBiller?.type &&
                    <div className="col-lg-6 input-col">
                        <Label htmlFor="operator">
                            {selectedBiller?.label} PACKAGE:
                        </Label>
                        <CustomSelect
                            variant="-bill"
                            id="tvPack"
                            options={allTvBiller}
                            isSearchable={true}
                            onChange={(e) => setselectedTvBiller(e)}
                            value={selectedTvBiller}
                            placeholder='Select a Package'
                            required
                        />
                    </div>
                }

                {selectedTvBiller?.amount &&
                    <div className="col-lg-6 input-col">
                        <div className="d-flex justify-content-between">
                            <Label htmlFor="amount">
                                AMOUNT({naira}) ?
                            </Label>
                            {' '}
                            <Fee fee={charges?.cable_bill.amount} />
                        </div>
                        <Input
                            type="number"
                            name="amount"
                            placeholder="Amount"
                            onChange={handleChange}
                            value={selectedTvBiller?.amount}
                            required
                            disabled
                            className="input-style"
                        />
                    </div>
                }

                <div className="col-lg-6 input-col">
                    <Label htmlFor="number">
                        Smart Card Number
                    </Label>
                    <Input
                        type="number"
                        name="smartCardNo"
                        placeholder="1234567890"
                        onChange={handleChange}
                        value={formField.smartCardNo}
                        required
                        className="input-style"

                    />
                </div>


                <div className="my-4 col-lg-6">
                    <Button
                        pry
                        className="btn m-0 w-100"
                        onClick={(e) => handleShow(e)}
                    >
                        Proceed
                    </Button>
                </div>


            </Form>

            <ConfirmTv
                show={show}
                onHide={handleClose}
                {...data}
            />


        </BillWrapper>

    );
};

export default BuyTv;