import styled from "styled-components";


export const StyledHomeNav = styled.nav`

  transition: 0.5s ease !important;
  overflow: hidden !important;

  img {
    width: 80px;
  }

  button.navbar-toggler {
  background-color: none !important;
}

.navbar-toggler-icon {
  background: none !important;
}

  .nav-item a {
  color: var(--grey-bold-color) !important;
  font-size: 1rem;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  border-bottom: transparent solid 2px !important;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

  .nav-item a:hover {
  border-bottom: var(--pry-color) solid 2px !important;
  transition: 0.1s ease !important;
}

  .btn {
  text-transform: capitalize !important;
  border: var(--pry-color) solid 1px !important;
  margin: 0 .2rem !important;
}

`;




export const StyledTopNav = styled.nav`
  background-color: var(--light-color);
  transition: 0.5s ease;
  padding: 1rem 1rem;
  z-index: 1000;

  .page-title {
    position: absolute;
    padding-left: 230px !important;
  }

   .bell {
  left: 18px !important;
  top: 5px !important;
  font-size: 7px;
  }

  svg {
    font-size: 20px;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-size: contain;
    
  }

  
@media screen and (max-width: 580px) {
  border-radius: 0 0 1rem 1rem;

  .page-title {
    display:none;
  }
}
`;

export const StyledSideNav = styled.aside`

  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--sec-color);
  overflow-x: hidden;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
  z-index: 1003 !important;


  /* Scrollbar  */

::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border: none;
  background-color: var(--sec-color);
}
::-webkit-scrollbar-thumb {
  border-radius:10px;
  background: var(--pry-color);
}
/* Scrollbar  */
 
  .navbar-brand {
    width: 100% !important;
    padding: 1rem !important;
    color: var(--pry-color);
    margin-bottom: 1rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 0 1rem;
    
    a {
     width: 100% !important;
     background-color: var(--light-color);
     color: var(--pry-color);
     font-size: 1rem;
     padding: 0.5rem 1rem;
     border-radius: 10px;
     border: 2px solid transparent;
     
     :hover {
       border: 2px solid var(--pry-color);
      }

      svg {
        font-size: 1.2rem;
        margin-right:10px;
        margin-bottom:3px;
      }
    }

    a.active {
     background-color: var(--pry-color);
     color: var(--light-color);
    }

    
   }


@media screen and (max-width: 580px) {
  width: 0;
}
`;

export const Hamburger = styled.span`
svg {
    color: var(--pry-color);
    font-size: 27px;
    cursor:pointer;
}
`;
export const CloseBtn = styled.span`
svg {
  position: absolute;
  right: 15px;
  top: 6px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
}

@media screen and (max-width: 580px) {
  svg {
  display: block;
  }
}
`;


/* Bottom Nav Bar */
export const StyledBottomNav = styled.div`

display: none !important;

 nav {
  z-index: 1000 !important;
}

 nav .list-unstyled a {
  color: var(--nav-color);
}
 nav .list-unstyled a:hover {
  color: var(--pry-color);
}

 .small-text {
  font-size: 12px !important;
}

 .active {
  color: var(--pry-color) !important;
  transition: 0.1s ease !important;
}

.bottom-button{
 
  .circle{
       background-color: var(--pry-color);
       color: var(--light-color);
       width:45px !important;
       height:45px !important;
       border-radius: 50%;
       display:flex;
       align-items:center;
       justify-content:center;
     }
  
  h4{
    border-radius:50%;
    font-size:1.5rem;
    margin:0;
    color: var(--light-color);
  }

  :hover {
    
  }
}

@media screen and (max-width: 580px) {
  display: block !important;
}
`;

export const BottomLinks = styled.div`
  position: fixed;
  width: 75%;
  left: 50%;
  transform: translate(-50%, -65%);
  backdrop-filter: blur(2px);
  background-color: var(--bottom-link-color);
  text-align:center;
  border-radius: 1rem; 
  padding: 1rem 2rem;

  animation: fadein  0.5s;
  @keyframes fadein {
    0%{
      opacity:0;
    }
    100%{
      opacity:1;
    }
  } 
    
  .nav-item {
    list-style:none;
    align-items: center;
    margin-bottom: 1rem;
    
    a {
      .circle{
       background-color: var(--light-color);
       color: var(--light-color);
       width:50px !important;
       height:50px !important;
       border-radius: 50%;
       display:flex;
       align-items:center;
       justify-content:center;
     }

      svg {
        color: var(--pry-color);
        font-size: 1.5rem;
        margin-bottom:3px;
      }
    }

    a.active {
     background-color: var(--pry-color);
     color: var(--light-color);
    }

  }

`
/* Bottom Nav Bar */