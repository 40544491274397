
import BalanaceCard from '../../components/dashboard/BalanaceCard';
import { DashboardPage } from '../../components/dashboard/Dashboard.styled';
import AppWrapper from '../../layout/AppWrapper';
import DashboardHeader from '../../components/dashboard/DashboardHeader';
import EscrowSegment from '../../components/dashboard/EscrowSegment';
import { useEffect } from 'react';
import { useDisputeContext } from '../../context/dispute_context';
import { useSendFundContext } from '../../context/send_fund_context';
import { useEscrowContext } from '../../context/escrow_context';
import TransactionHistory from '../../components/transactions/TransactionHistory';
import { useUserContext } from '../../context/user_context';


const Dashboard = () => {

  const {
    getAllEscrow: { getEscrow }
  } = useEscrowContext();
  const {
    getUserPhoto
  } = useUserContext();
  const {
    getAllDispute: { getDispute }
  } = useDisputeContext();
  const {
    payRequest: { getRequestList },
    beneficiary: { getBen },
    banks: { getBank },
  } = useSendFundContext();


  function getFn() {
    getEscrow();
    getDispute();
    getRequestList();
    getBank();
    getBen();
    getUserPhoto();
  }

  useEffect(() => {
    getFn();
  }, []);


  return (
    <AppWrapper
      pagename={"Dashboard"}
    >
      <DashboardPage>
        <div className="row">
          <div className="col-md-6 mb-3">
            <BalanaceCard />
          </div>
          <div className="col-md-6">
            <DashboardHeader />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-8 mb-3">
            <TransactionHistory
              reduce={5}
            />
          </div>
          <div className="col-md-4">
            <EscrowSegment />
          </div>
        </div>

      </DashboardPage>

    </AppWrapper>
  );
};

export default Dashboard;

