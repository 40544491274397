import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../../styles";
import { useUserContext } from "../../../context/user_context";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import AppLoader from "../../../utils/AppLoader";
import { useEscrowContext } from "../../../context/escrow_context";
import { toast } from 'react-toastify';
import Api from "../../../api/axios";
import SuccessfulEscrow from "../SuccessfulEscrow";
import styled from "styled-components";


const ConfirmNewEscrow = (props) => {
  const [sending, setsending] = useState(false);
  const [escrowToken, setescrowToken] = useState('');
  const { usertoken } = useUserContext();

  const { formField, recipient } = props;

  const { role, r_mail, r_phone, r_name, title, quantity, price_unit, delivery_cost, delivery_date, description, amount } = formField;

  const {
    confirmNewEscrow: { showConfirmNewEscrow, handleCloseConfirmNewEscrow },
  } = useEscrowContext();


  // Submit Function
  function submitFn(e) {
    e.preventDefault();
    if (
      formField.role && formField.title && formField.quantity && formField.price_unit && formField.delivery_cost && formField.description && formField.delivery_date
    ) {
      setsending(true);
      const s_data = {
        usertoken: usertoken,
        role: formField.role,
        s_usertoken: usertoken,
        b_usertoken: formField?.usertoken,
        b_mail: formField.r_mail,
        b_phone: formField.r_phone,
        b_name: formField.r_name,
        title: formField.title,
        quantity: formField.quantity,
        price_unit: formField.price_unit,
        delivery_cost: formField.delivery_cost,
        description: formField.description,
        delivery_date: formField.delivery_date,
        amount: formField.amount
      };
      const b_data = {
        usertoken: usertoken,
        role: formField.role,
        b_usertoken: usertoken,
        s_usertoken: formField?.usertoken,
        s_mail: formField.r_mail,
        s_phone: formField.r_phone,
        s_name: formField.r_name,
        title: formField.title,
        quantity: formField.quantity,
        price_unit: formField.price_unit,
        delivery_cost: formField.delivery_cost,
        description: formField.description,
        delivery_date: formField.delivery_date,
        amount: formField.amount
      };
      const data = formField.role === 'buyer' ? b_data : s_data;

      Api.post(`/createEscrow`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setsending(false);
          } else {
            setsending(false);
            handleShow(res.data.data.token);
            setTimeout(() => {
              toast.success(res.message);
            }, 10);
          }
        })
        .catch((error) => {
          setsending(false);
        });
    } else {
      setsending(false);
      toast.warn("Empty Fields!");
    }
  }
  // Submit Function

  // Success Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (token) => {
    setescrowToken(token);
    handleCloseConfirmNewEscrow();
    setShow(true);
  };
  // Success Modal

  return (
    <>
      <Modal
        show={showConfirmNewEscrow}
        onHide={handleCloseConfirmNewEscrow}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            CONFIRM ESCROW
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyledConfirmEscrow>
            <div className="mb-5">
              <div className="container py-3 my-2 light-bold-bg br-md">
                <small>
                  Role
                </small>
                <p>
                  {role}
                </p>
                <small>
                  Title
                </small>
                <p>
                  {title}
                </p>
                <small>
                  {recipient}'s Email
                </small>
                <p>
                  {r_mail}
                </p>
                <small>
                  {recipient}'s Name
                </small>
                <p>
                  {r_name}
                </p>
                <small>
                  {recipient}'s Phone
                </small>
                <p>
                  {r_phone}
                </p>
              </div>
              <div className="container py-3 my-2 light-bold-bg br-md">

                <small>
                  Description
                </small>
                <p>
                  {description}
                </p>
                <small>
                  No of Goods
                </small>
                <p>
                  {th(quantity)}
                </p>
                <small>
                  Unit Price
                </small>
                <p>
                  {naira}{th(price_unit)}
                </p>
                <small>
                  Amount
                </small>
                <p>
                  {naira}{th(amount)}
                </p>
                <small>
                  Delivery fee
                </small>
                <p>
                  {naira}{th(delivery_cost)}
                </p>
                <small>
                  Delivery Date
                </small>
                <p>
                  {delivery_date}
                </p>
              </div>

            </div>
            <div className="">
              <Button
                pry
                type="submit"
                disabled={sending}
                className="btn w-100"
                onClick={(e) => submitFn(e)}
              >
                {sending ? 'loading...' : "Submit"}
              </Button>
            </div>

          </StyledConfirmEscrow>
        </Modal.Body>
      </Modal>

      <SuccessfulEscrow
        show={show}
        onHide={handleClose}
        id={escrowToken}
      />
      <AppLoader
        load={sending}
      />
    </>
  );
};

export default ConfirmNewEscrow;

const StyledConfirmEscrow = styled.div`
  small {
    color: var(--grey-bold-color);
  }
  p {
    padding:0;
    margin:0 0 5px 0;
    line-height:1;
  }
`


