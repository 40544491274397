import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_EXCLAMATION } from "../../../utils/icons";
import { Button } from "../../../styles";
import SuccessTransfer from "../SuccessTransfer";
import { StyleConfirmFunds } from "../Wallet.styled";
import { useSendFundContext } from "../../../context/send_fund_context";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import AppLoader from "../../../utils/AppLoader";


const ConfirmRequestUsername = (props) => {
  const [issending, setissending] = useState(false);
  const { usertoken } = useUserContext();

  const { formField } = props;

  const {
    confirmUsername: { showConfirmUsername, handleCloseConfirmUsername },
    success: { handleShowSuccess },

  } = useSendFundContext();

  const openSuccess = () => handleShowSuccess();

  // Send Money to Username
  function submitFn() {
    if (
      formField.u_name, formField.amount
    ) {
      setissending(true);
      const data = {

        usertoken,
        amount: formField.amount,
        username: formField.u_name
      };
      Api.post(`/requestFromUsername`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
          } else {
            toast.success(res.message);
            setissending(false);
            openSuccess();
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Input Correct Details!");
    }
  }
  // Send Money to Username


  return (
    <>
      <Modal
        show={showConfirmUsername}
        onHide={handleCloseConfirmUsername}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            CONFIRM REQUEST
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyleConfirmFunds>
            <div className="mb-5 pb-5">
              <h1 className="yellow-text display-2">
                {ICON_EXCLAMATION}
              </h1>

              <h5 className="">
                Are you sure?
              </h5>
              <h5 className="fw-light">
                You are about to request
                <span className="fw-bold"> {naira}{th(formField.amount)}</span> from
                <span className="fw-bold"> {formField.r_name} ({formField.u_name}) </span>
              </h5>
            </div>
            <Button
              pry
              disabled={issending}
              className="btn w-100"
              onClick={() => submitFn()}
            >
              {issending ? 'loading...' : "Proceed"}
            </Button>
          </StyleConfirmFunds>
        </Modal.Body>
      </Modal>

      <SuccessTransfer
        title={'Request'}
        data={formField}
      />
      <AppLoader
        load={issending}
      />

    </>
  );
};

export default ConfirmRequestUsername;


