import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../context/user_context';
import EmptyData from '../EmptyData';
import { Modal } from "react-bootstrap";



const DisputeChat = (props) => {

    const { chat } = props;
    const { usertoken } = useUserContext();
    const [image, setImage] = useState('');
    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (file) => { setShow(true); setImage(file); };
    // Modal



    return (
        <StyledChat>
            <EmptyData
                data={chat}
                title='chat'
            />
            {chat?.map(({ timestamp, chat, file, filetype, realTime, usertoken: respondToken, userData }) => {
                let token = 'respond';
                let tokenName = 'respond-name';
                { respondToken === usertoken ? token = 'user' : respondToken === '1' ? token = 'admin' : token = 'respond'; }
                { respondToken === usertoken ? tokenName = 'user-name' : respondToken === '1' ? tokenName = 'admin-name' : tokenName = 'respond-name'; }
                return (
                    <>
                        <div
                            className={`msg col-md-8 col-10 ${ token }`}
                            key={timestamp}
                        >

                            <small className="fw-bold text-uppercase">
                                <span className={tokenName}>
                                    {userData?.data.fullname}
                                </span>
                            </small>
                            <p className="text-break mt-2"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(chat)
                                }}
                            />

                            {(file || file !== '0') &&
                                <>
                                    {filetype?.includes('image/') &&
                                        <img src={file} alt="image"
                                            onClick={() => handleShow(file)}
                                        />
                                    }
                                </>
                            }

                            <span className="time d-flex justify-content-end">
                                {realTime}
                            </span>

                        </div>

                    </>
                );
            }
            ).reverse()
            }
            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body >
                    <img src={image} alt="image" width="100%" />
                </Modal.Body>

            </Modal>

        </StyledChat>
    );
};

export default DisputeChat;

const StyledChat = styled.div`

    .msg{
        border-radius: 12px;
        padding: 10px ;
        margin: 10px;
        color: var(--light-color);
    }

    p{
        line-height: 1.3;
    }
    small {
        background: var(--light-color);
        border-radius:6px;
        padding:3px 6px;
        font-size: 12px;

        .admin-name{
            color:#1C1C1C;
        }
        .respond-name{
            color:var(--pry-color);
        }
        .user-name{
            color:#008D39;
        }
    }

    .admin {
        background-color:#1C1C1C;
    }
    .respond{
        background-color:var(--pry-color);
    }
    .user {
        background-color: #008D39;
        margin-left:auto;
    }

    img {
     width: 150px;
    height: 150px;
    background-size: contain;
    border-radius: .6rem;
    }

    audio {
  background-color: var(--light-color) !important;
  border-radius: 10px;
}

  .modal .modal-content {
            background-color: var(--light-color)  !important;
    }

    .time{
        font-size:10px;
        color: var(--grey-color);
        
    }
`;;