import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import Api from '../api/axios';
import { useUserContext } from './user_context';


export const ProfileContext = React.createContext();


export const ProfileProvider = ({ children }) => {

    const { usertoken, getUserData, getUserPhoto } = useUserContext();


    // Username Edit Fn
    function editUsername(e, form, setloading, showUsernameEdit) {
        e.preventDefault();
        if (form) {
            setloading(true);
            const data = { usertoken, username: form };
            Api.post(`/updateUserProfile-username`, data)
                .then((res) => {
                    if (res.success === false) { setloading(false); toast.warn(res.message); } else {
                        setloading(false);
                        toast.success(res.message);
                        getUserData(); showUsernameEdit();
                    }
                }).catch(() => { setloading(false); });
        } else { toast.warn('Username is empty'); setloading(false); }
    }
    // Username Edit Fn

    // Phone Edit Fn
    function editPhone(e, form, setloading, showPhoneEdit) {
        e.preventDefault();
        if (form) {
            setloading(true);
            const data = { usertoken, phone: form };
            Api.post(`/updateUserProfile-phone`, data)
                .then((res) => {
                    if (res.success === false) { setloading(false); toast.warn(res.message); } else {
                        setloading(false);
                        toast.success(res.message);
                        getUserData(); showPhoneEdit();
                    }
                }).catch(() => { setloading(false); });
        } else { toast.warn('Phone is empty'); setloading(false); }
    }
    // Phone Edit Fn


    // Address Edit Fn
    function editAddress(e, form, setloading, showAddressEdit) {
        e.preventDefault();
        if (form) {
            setloading(true);
            const data = { usertoken, address: form };
            Api.post(`/updateUserProfile-address`, data)
                .then((res) => {
                    if (res.success === false) { setloading(false); toast.warn(res.message); } else {
                        setloading(false);
                        toast.success(res.message);
                        getUserData(); showAddressEdit();
                    }
                }).catch(() => { setloading(false); });
        } else { toast.warn('Address is empty'); setloading(false); }
    }
    // Address Edit Fn


    // Avatar Edit Fn
    function editAvatar(e, form, setloading) {
        e.preventDefault();
        if (form) {
            setloading(true);
            const data = { usertoken, photo: form };
            Api.post(`/updateUserProfile-photo`, data)
                .then((res) => {
                    if (res.success === false) { setloading(false); toast.warn(res.message); } else {
                        setloading(false);
                        toast.success(res.message);
                        getUserPhoto();
                    }
                }).catch(() => { setloading(false); });
        } else { toast.warn('Avatar is empty'); setloading(false); }
    }
    // Avatar Edit Fn



    return (
        <ProfileContext.Provider value={{
            editData: { editUsername, editPhone, editAddress, editAvatar }

        }}>
            {children}
        </ProfileContext.Provider>
    );
};

// make sure use
export const useProfileContext = () => {
    return useContext(ProfileContext);
};
