import { Link } from 'react-router-dom';
import { StyledBill } from '../../components/bills/Bill.styled';
import AppWrapper from '../../layout/AppWrapper';
import WalletHeader from '../../components/wallet/WalletHeader';
import { StyledLinks } from '../../components/wallet/WalletLinks';
import { bill } from '../../utils/data';
import BillTransactions from '../../components/transactions/BillTransactions';

const Bills = () => {

  return (
    <AppWrapper
      pagename={"Bills"}
    >
      <WalletHeader />

      <StyledBill>

        <div className="row mt-4">
          {bill?.map(({ title, route, img, bgColor }, i) =>
            <div className="col-md-4 mb-3" key={i}>
              <Link to={route}>
                <StyledLinks className="br-md h-100"
                  style={{
                    // background: `url(${ img }) no-repeat right/contain`,
                    backgroundColor: `${ bgColor }`
                  }}
                >
                  {title}
                </StyledLinks>
              </Link>
            </div>
          )}
        </div>

      </StyledBill>

      <div className="row">
        <div className="col-md-8 mb-3">
          <BillTransactions />
        </div>
      </div>


    </AppWrapper>
  );
};

export default Bills;