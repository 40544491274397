import styled from "styled-components";
import HomeNavbar from "../../components/homepage/HomeNavbar";
import Footer from "../../components/homepage/Footer";


const Terms = () => {

  return (
    <StyledHome>
      <>
        <div className="container">
          <HomeNavbar />

          <div className="my-4">
            <h4 className="text-center">
              Terms and Conditions
            </h4>
            <hr />
            <small className="">
              Please read these terms and conditions carefully before using Our Service.
            </small>
            <div className="py-2">

              <p className="mt-4">
                RENITECH is a fully registered and regulated internet technologies service provider focused on software and internet solutions.  We do not offer investment services or run financial technologies services but provide an escrow system to ensure that service providers delivered what was ordered and clients/ customers receive what was ordered to specific terms and conditions of their order.
                <br />
                <br />
                These term and condition of use (these 'terms and Conditions') represents an Agreement between you and Renitech duly incorporated under the laws of the Federal Republic of Nigeria. These Terms and Conditions govern your use of our platform. Reni (the App); the related online documentation, such as our help guides and user manuals (User Guides); and the services, including the Renitech Account, and content provided to you through the App (Services)
              </p>
            </div>

            <div className="py-2">

              <h5 className=""> Definitions</h5>
              <p>
                For the purposes of these Terms and Conditions:
              </p>
              <ol>
                <li>
                  <b>Application  </b>
                  means the software program provided by the Company downloaded by You on any electronic device, named Reni, where you can enjoy all our services as soon as you provide all required information and documents.
                </li>
                <li>
                  <b> Application Store </b>
                  means the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store) in which the Application has been downloaded.
                </li>
                <li>
                  <b> Escrow: </b>

                </li>
                <li>
                  <b>Product  </b>
                  means product (s) you order from a vendir using our online platform
                </li>
                <li>
                  <b> Statement of account: </b>
                  It is a document generated by our platform that reflects all transactions that took place between you and a particular customer for a given period.
                </li>
                <li>
                  <b> Company  </b>
                  (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Reni Technologies Limited if No.3, Shead House, off U.I/Ojoo Road, Orogun, Ibadan
                </li>
                <li>
                  <b>Device   </b>
                  means any device that can access the Service such as a computer, a cellphone or a digital tablet.
                </li>
                <li>
                  <b> Service  </b>
                  refers to the Application.
                </li>
                <li>
                  <b>Terms and Conditions  </b>
                  (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the Free Terms and Conditions Generator.
                </li>
                <li>
                  <b> Third-party Social Media Service </b>
                  means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.
                </li>
                <li>
                  <b> You </b>
                  mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </li>
              </ol>
            </div>

            <div className="py-2">
              <h5 className=""> Description of Service</h5>

              <p>
                Renitech provides you with a an escrow systems app where sellers can be buyers and offers and acceptance of services are made and transactions seamlessly completed. We offer services like ..., To be eligible to use our services you must;
                <ol>
                  <li>
                    be a legal Citizen of Nigeria or a legal Resident of Nigeria and reside in Nigeria.

                  </li>
                  <li>
                    must be at least 18 years of age or 21 where you reside in a state where the majority age is 21

                  </li>
                  <li>
                    Have a bank Account with a licensed Nigerian Bank

                  </li>
                  <li>
                    Provide all information required to open an account with us, including but not limited to; your full name, email address, bank account details, mobile number and such other information that may be required from time to time (user information).
                  </li>
                </ol>

                You agree that the information you provide to us on account registration through our website or mobile applications will be true, accurate, current, and complete and you agree not to misrepresent your identity or your User Information. You agree to promptly notify us of changes to your User Information by updating your Renitech Account on the Application platform and notifying us immediately of any changes in your information by mailing us at hello@reni.tech

              </p>

              <h6 className="fw-bold">
                OPENING AND OPERATING YOUR RENITECH ACCOUNT
              </h6>
              <p>
                To successfully open an account with us, you must register for a Renitech Account via the App, available on apple and play store. Provide your email address, phone number; and such other KYC (Know your Customer) information and documentation that we may require from time to time. By using the Services and providing User Information to us, you automatically authorize us to obtain, directly or indirectly through our third-party service providers and without any time limit or the requirement to pay any fees, information about you and your Bank Account from the licensed financial institution holding your Bank Account and other third-party websites and databases as necessary to provide the Services to you. <br />
                You then proceed to either create an account where you have the right either list your product as a vendor or purchase from vendors as a purchaser.
              </p>
            </div>

            <div className="py-2">
              <h5 className=""> Indemnity </h5>

              <ol>
                <li>
                  1.Renitech is authorized to honor all instructions, mandates, applications, consents, confirmation, commitments, and communications received in respect of the service signed up for, subject to regulatory approvals, issued or purported to have been provided by electronic mail via the application platform  and where Renitech has honored any such written instructions, mandates, applications, consents, confirmation, commitments, and communications in such circumstances prior to the date hereof its actions in that regard are hereby ratified, confirmed and approved.
                </li>
                <li>
                  2.I hereby indemnify Renitech in full for any loss, actions, proceedings, claims, damages, costs and expenses that may be suffered or incurred by reason of its honouring such electronic mail instructions, mandates, applications, consents, confirmation, commitments, and communications which emanates from my usage of the application platform, irrespective of whether same are in fact erroneous, fraudulent or issued otherwise than as aforesaid provided that Renitech has taken all reasonable and professional care required in dealing with such electronic instruction.
                </li>
                <li>
                  3.This indemnity shall remain in force until such time as any and all potential or actual liability against Renitech shall be extinguished.
                </li>
                <li>
                  4.This indemnity shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.
                </li>
              </ol>
            </div>

            <div className="py-2">
              <h5 className=""> Suspension and Termination of Service </h5>
              <p>
                You may discontinue use of our service at any point in time if you feel threats or lack of interest in our services
                <br /> <br />
                We may discontinue your use of our service if you our app guidelines or our anti-money laundering, counter terrorism and other policies and guidelines.
              </p>

              <b>
                The following might lead to you being banned.
              </b>
              <ul>
                <li>
                  Advocating violence
                </li>
                <li>
                  Posting discriminatory, abusive, threatening, pornographic, or offensive content
                </li>
                <li>
                  Using the platform or site for illegal activities
                </li>
                <li>
                  Exposing another user’s personal information, including username(s) and password(s)
                </li>
              </ul>
              <p>
                If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal actions, including without limitation pursuing civil, criminal, and injunctive redress.
              </p>
            </div>

            <div className="py-2">
              <h5 className=""> Acknowledgment </h5>
              <p>
                These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                <br /> <br />
                Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
                <br /> <br />
                By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.

                <br /> <br />
                Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Third Party Integration </h5>
              <p>
                Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
                <br /> <br />
                The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
                <br /> <br />
                By using the Services and providing User Information to us, you automatically authorize us to obtain, directly or indirectly through our third-party service providers and without any time limit or the requirement to pay any fees, information about you and your Bank Account from the licensed financial institution holding your Bank Account and other third-party websites and databases as necessary to provide the Services to you.
                <br /> <br />
                You understand that in order to operate a Reni Account you might be required to link your Debit card details with your Reni account by saving the details on our app to grant us access through our third party service provider to your financial records, WE DO NOT STORE OR SAVE YOUR BANK LOGIN INFORMATION. For purposes of such access and authorization, you hereby grant Renitech and our third-party service providers a limited power of attorney, and you hereby appoint Renitech and our third-party service providers as your true and lawful attorney-in-fact and agent, with full power of substitution and resubstituting, for you and in your name, place, and stead, in any and all capacities, to access third-party websites, servers, and documents; retrieve information; and use your User Information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with such activities, as fully to all intents and purposes as you might or could do in person. YOU ACKNOWLEDGE AND AGREE THAT WHEN RENITECH OR OUR THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE INFORMATION FROM SUCH THIRD-PARTY WEBSITES, RENITECH AND OUR THIRD-PARTY SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that other third parties shall be entitled to rely on the foregoing authorization, agency, and power of attorney granted by you. You understand and agree that the Services are not endorsed or sponsored by any third-party account providers accessible through the Services. We make no effort to review information obtained from the financial institution holding your Bank Account and other third-party websites and databases for any purpose, including, but not limited to, accuracy, legality, or non-infringement. As between Renitech and our third-party service providers, Renitech owns your confidential User Information.
                <br /> <br />
                We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.
              </p>
            </div>
            <div className="py-2">
              <h5 className="">Termination</h5>
              <p>
                We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
                <br />
                Upon termination, Your right to use the Service will cease immediately.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> "AS IS" and "AS AVAILABLE" Disclaimer </h5>
              <p>
                The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
                <br /> <br />
                Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, time bombs or other harmful components.
                <br /> <br />
                Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Governing Law  </h5>
              <p>
                The laws of Nigeria, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service. Your use of the Application may also be subject to other local, state, national, or international laws.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Disputes Resolution </h5>
              <p>
                If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by contacting the Company.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Privacy Notice  </h5>
              <p>
                We care about data privacy and security. Please review our Privacy Notice to understand our use of your personal information. You acknowledge that you have reviewed and understand our Privacy Notice.
                <br /> <br />
                Please be advised the Services is hosted in Nigeria. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Nigeria, then through your continued use of the Services, you are transferring your data to Nigeria, and you agree to have your data transferred to and processed in Nigeria. Our Data Policy is as prescribed by NITDA
                <a href="https://nitda.gov.ng/privacy-policy/" target="_blank" rel="noopener noreferrer">
                  {' '}  https://nitda.gov.ng/privacy-policy/
                </a>

              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Translation Interpretation </h5>
              <p>
                These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Changes to These Terms and Conditions </h5>
              <p>
                We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material We will make reasonable efforts to provide at least 20 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at Our sole discretion.
                <br /><br />
                By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, in whole or in part, please stop using the app and the Service.
              </p>
            </div>
            <div className="py-2">
              <h5 className=""> Contact Us </h5>
              <p>
                If you have any questions about these Terms and Conditions, You can contact us:
              </p>
              <p>
                By visiting this page on our website:
                <a href="http://renitrust.com" target="_blank" rel="noopener noreferrer">
                  {' '}  www.renitrust.com
                </a>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </StyledHome>

  );
};

export default Terms;

const StyledHome = styled.main`
background-color: var(--light-color);
color: var(--dark-color);
text-align:justify;

`;