import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input, Label, NameInput, UserDetail } from '../../../styles';
import { ICON_EXCLAMATION, ICON_MARK_FILL, ICON_RECEIVE_MONEY } from '../../../utils/icons';
import { useSendFundContext } from "../../../context/send_fund_context";
import { StyledSendFund } from '../Wallet.styled';
import ConfirmRequestUsername from './ConfirmRequestUsername';
import { ClipLoader } from 'react-spinners';


const RequestUsername = () => {

  const [formField, setformField] = useState({
    u_name: '', amount: ''
  });
  const [proceed, setproceed] = useState(false);
  const [selectUser, setselectUser] = useState('');

  const {
    user: { searchUser, message, loadingName, },
    confirmUsername: { handleShowConfirmUsername },
  } = useSendFundContext();


  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field


  useEffect(() => {
    searchUser(formField.u_name, setformField, setselectUser);
  }, [formField.u_name]);


  function checkSendDetails(e) {
    e.preventDefault();
    if (!selectUser) {
      toast.warn('Unable to verify Username!');
      return;
    }
    setproceed(true);
  }

  const data = { formField, selectUser };
  return (
    <AppWrapper
      pagename={"Wallet"}
    >

      <StyledSendFund>

        <SendLayout
          title={'Request Money'}>

          <div className="text-center">
            <h1 className="pry-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_RECEIVE_MONEY}
            </h1>
          </div>

          {/* Send Form */}
          <Form
            className="my-5"
          >
            <div className="justify-content-center text-left">

              <div className="col-lg-12 ">
                <Label htmlFor="u_name"
                  className='fw-bold dark-text name_label'
                >
                  Request money from
                </Label>
                {loadingName &&
                  <ClipLoader size='20' color={'var(--pry-color)'} className='mx-2' />
                }
                <NameInput
                  type="search"
                  name="u_name"
                  id="u_name"
                  placeholder="Reni Username"
                  onChange={handleChange}
                  value={formField.u_name}
                  required
                />
                {formField.u_name &&
                  <>
                    <br />
                    <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                      {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                      {selectUser ? selectUser?.fullname : message}
                    </UserDetail>
                  </>
                }
                {!proceed &&
                  <div className="col-lg-12">
                    <Button
                      pry
                      className="btn w-100"
                      onClick={(e) => checkSendDetails(e)}
                    >
                      Next
                    </Button>
                  </div>
                }

                {proceed &&
                  <>
                    <Label htmlFor="amount">
                      Amount:
                    </Label>

                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder={`Input Amount`}
                      className='mb-1'
                      onChange={handleChange}
                      value={formField.amount}
                      required
                    />

                    <Label htmlFor="details">
                      Narration:
                    </Label>
                    <Input
                      type="text"
                      name="details"
                      id="details"
                      placeholder="Input Narration"
                      onChange={handleChange}
                      value={formField.details}
                      required
                    />
                    <div className="col-lg-12">
                      <Button
                        pry
                        className="btn w-100"
                        onClick={(e) => handleShowConfirmUsername(e, data)}
                      >
                        Proceed
                      </Button>
                    </div>
                  </>
                }
              </div>
            </div>
          </Form>

          {/* Send Form */}


        </SendLayout>
      </StyledSendFund>

      <ConfirmRequestUsername
        {...data}
      />
    </AppWrapper >
  );
};

export default RequestUsername;

