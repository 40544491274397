import { useState } from 'react';
import styled from 'styled-components';
import { ICON_ARROW_RIGHT_CIRCLE } from '../../utils/icons';
import { Button, Input, StyledTab } from '../../styles';
import EmptyData from '../EmptyData';
import { Link, useLocation } from 'react-router-dom';
import { StyledEscrowSegment } from './Escrow.styled';
import { th } from '../../utils/helpers';
import { useEffect } from 'react';

import AppLoader from '../../utils/AppLoader';
import { useEscrowContext } from '../../context/escrow_context';
import { naira } from '../../utils/data';

const EscrowTransaction = () => {

    const {
        getAllEscrow: { EscrowLoading, historyData, getEscrow, selectData, searchTrx }
    } = useEscrowContext();

    const [selectedLink, setselectedLink] = useState('all');

    const links = ['all', 'pending', 'successful', 'closed'];

    useEffect(() => {
        getEscrow();
    }, []);


    return (
        <StyledEscrowTransaction>

            <AppLoader load={EscrowLoading} />

            <h4 className="fw-light">
                Transactions
            </h4>
            <div className="my-3">
                <StyledTab className=''>
                    {links.map((e, i) =>
                        <div className={`links ${ e === selectedLink && 'active' }`}
                            key={i}
                            onClick={() => selectData(e, setselectedLink)}
                        >{e}</div>
                    )}
                </StyledTab>
            </div>
            <StyledEscrowSegment>
                <div className="col-lg-8">
                    <Input
                        type="search"
                        name="search"
                        placeholder="Search Transaction (name, amount, trx ID)"
                        onChange={(e) => searchTrx(e.target.value, setselectedLink)}
                        className="mb-0 m-0"
                    // value={formField.email}
                    />
                    <EscrowList data={historyData} />
                </div>
            </StyledEscrowSegment>
        </StyledEscrowTransaction>
    );
};

export default EscrowTransaction;

export const EscrowList = ({ data }) => {

    let location = useLocation();
    let path = location.pathname;

    return (
        <div className="escrow-history mt-3 px-2 px-lg-4 br-md py-4">

            <EmptyData
                data={data}
                title='escrow'
            />
            {
                data?.map(({ title, real_time, amount, status, funded, token, refunded, cancelled, resolved, dispute }) => {
                    return (
                        <div className="history br-md" key={token}>
                            <h4 className="">
                                {title}
                            </h4>
                            <h5>
                                {naira}{th(amount)}
                            </h5>
                            <p>
                                {real_time}
                            </p>
                            <p>
                                Escrow ID: {' '}
                                <span className="fw-bold">
                                    {token}
                                </span>
                            </p>
                            <div className="d-flex justify-content-between mt-4">
                                <div className="d-flex gap-1 flex-wrap">
                                    {(cancelled === '0' && refunded === '0') &&
                                        <p className={`${ status === '1' ? 'badge-success' : 'badge-warning' } badge mt-3 pt-2`}>
                                            {status === '1' ? 'Successful' : 'Pending'}
                                        </p>
                                    }
                                    {(refunded === '0' && cancelled === '0') &&
                                        <p className={`${ funded === '1' ? 'badge-success' : 'badge-warning' } badge mt-3 pt-2`}>
                                            {funded === '1' ? 'Paid' : 'Unpaid'}
                                        </p>
                                    }
                                    {refunded === '1' &&
                                        <p className='badge badge-dark mt-3 pt-2'>
                                            Refunded
                                        </p>
                                    }
                                    {(cancelled === '1' || resolved === '0' && status === '1') &&
                                        <p className='badge badge-danger mt-3 pt-2'>
                                            Closed
                                        </p>
                                    }
                                    {(dispute === '1') &&
                                        <p className='badge badge-primary mt-3 pt-2'>
                                            In Dispute
                                        </p>
                                    }

                                </div>
                                <Link to={`/user/escrow/${ token }`}>
                                    {ICON_ARROW_RIGHT_CIRCLE}
                                </Link>

                            </div>
                        </div>
                    );
                })
            }

            {path !== '/user/escrow' &&
                <>
                    {data.length > 2 &&
                        <div className="text-center">
                            <Link to='/user/escrow'>
                                <Button
                                    className='btn border border-primary shadow-none pry-text fw-bold'
                                >
                                    View More
                                </Button>
                            </Link>
                        </div>
                    }
                </>
            }

        </div>

    );
};

const StyledEscrowTransaction = styled.div`
margin: 3rem 0;
    

`;