import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ICON_EXCLAMATION, ICON_MARK } from "../../utils/icons";
import { Button } from "../../styles";
import { useSendFundContext } from "../../context/send_fund_context";
import { naira } from "../../utils/data";
import { th } from "../../utils/helpers";


const PendingTransfer = (props) => {

  const { response, data: { u_name, amount } } = props;
  const navigate = useNavigate();

  const {
    pending: { showPending, handleClosePending, setShowPending },
  } = useSendFundContext();

  function proceed() {
    navigate('/user/dashboard');
    setShowPending(false);
  }

  return (
    <>
      <Modal
        show={showPending}
        onHide={handleClosePending}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Body>
          <StyledSuccessModal className="text-center">
            <div className="my-5">
              <span className="success-icon">
                {ICON_EXCLAMATION}
              </span>
              <div className="my-4">
                <h5 className="">
                  Transfer Pending!
                </h5>
                <p className="grey-bold-text col-8 mx-auto">
                  Your Transfer of
                  <span className="fw-bold">
                    {' '} {naira}{th(amount)} {' '}
                  </span>
                  to
                  <span className="fw-bold">
                    {' '} {u_name}{' '}
                  </span>
                  {' '}is {response}
                </p>
              </div>
            </div>

            <Button
              className="btn w-100"
              pry
              onClick={() => proceed()}
            >
              Homepage
            </Button>

          </StyledSuccessModal>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default PendingTransfer;

export const StyledSuccessModal = styled.div`
  .success-icon svg {
    font-size: 70px;
    padding:7px;
    background-color: var(--pry-color);
    color: var(--light-color);
    border-radius: 50%;
    margin-bottom: 2rem;

  }
  svg.qr {
  background-color: var(--light-color) !important;
  border-radius: 0 ;
  }
  button{
    margin: 3px !important;
  }
`;
