import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useWalletContext } from "../../context/wallet_context";
import AppWrapper from "../../layout/AppWrapper";
import SendLayout from "../../layout/SendLayout";
import { Button } from "../../styles";
import { naira } from "../../utils/data";
import { CopyItem, saveImage, shareImage } from "../../utils/helpers";
import { ICON_COPY, ICON_MARK, ICON_MARK_FILL, ICON_SAVE, ICON_SHARE_FILL } from "../../utils/icons";
import { logo_blue_bg } from "../../assets";


const TransactionDetails = () => {

    const params = useParams();
    const navigate = useNavigate();

    const {
        getTrxHist: { trxHist, getTrxHist }
    } = useWalletContext();

    const trxData = trxHist?.find((e) => e.reference_number === params.id) ||
    {
        narration: '', type: 'Credit', amount_th: '0.00', recipient_account_number: '1234567890', reference_number: 'A0000000000', r_userData: { name: 'Jane Doe' }, real_time: '01-01-1970', status: '0', title: 'Unknown Trx'
    };

    const { narration, type, amount_th, account_number, recipient_account_number, reference_number, r_userData: { name: r_name } = {}, s_userData: { name: s_name } = {}, real_time, status, title } = trxData;

    useEffect(() => {
        getTrxHist();
        if (trxData.type !== 'null') {
            return;
        } else {
            toast.error('Trx not found!');
        }
    }, []);
    console.log(trxData);

    // Copy Function 
    const [copied, setcopied] = useState(false);
    function copy() {
        toast.success('Copied to Clipboard');
        setcopied(!copied);
        setTimeout(() => {
            setcopied(false);
        }, 3000);
    }
    // Copy Function
    return (
        <>
            <AppWrapper
                pagename={"Transactions"}
            >
                <div className="col-lg-8 mx-auto">
                    <StyledTransaction>

                        <SendLayout
                            title={''}>
                            <StyledReceipt id="trx" >
                                <div className="d-flex justify-content-between">
                                    <div className="">
                                        <p className="mb-4 fw-bold"> Receipt </p>
                                        <div className="">
                                            <small className="grey-bold-text">
                                                Amount
                                            </small>
                                            <p className={`fw-bold h1 ${ type === 'Credit' ? 'text-success' : 'red-text' }`}>
                                                {naira}
                                                {amount_th}
                                            </p>
                                        </div>
                                        <div className="">
                                            <small className="grey-bold-text m-0">
                                                Fee
                                            </small>
                                            <p className="m-0 lh-1 small">
                                                {naira}0.00
                                            </p>
                                        </div>

                                    </div>
                                    <div className="">
                                        <img src={logo_blue_bg} alt="Reni" width='90px' />
                                    </div>
                                </div>
                                <hr />
                                <small className="grey-bold-text">
                                    Sender Details
                                </small>
                                <div className="text-uppercase">
                                    <p className="m-0 fw-bold lh-1">
                                        {s_name || 'Nil'}
                                    </p>
                                    <small className="m-0 lh-1">
                                        {account_number || 'Nil'}
                                    </small>
                                </div>
                                <small className="grey-bold-text">
                                    Recipient Details
                                </small>
                                <div className="text-uppercase">
                                    <p className="m-0 fw-bold lh-1">
                                        {r_name || 'Nil'}
                                    </p>
                                    <small className="m-0 lh-1">
                                        {recipient_account_number || 'Nil'}
                                    </small>
                                </div>


                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="">
                                        <small className="grey-bold-text m-0">
                                            Transaction ID
                                        </small>
                                        <p className="fw-bold">
                                            {reference_number}
                                        </p>
                                    </div>
                                    <CopyItem value={reference_number}>
                                        <p
                                            className="m-0 pry-text small pointer"
                                            onClick={() => copy()}
                                        >
                                            {ICON_COPY}
                                            {copied ? ' COPIED' : ' COPY'}

                                        </p>
                                    </CopyItem>
                                </div>
                                <div className="">
                                </div>
                                <hr className="m-0" />
                                <div className="">
                                    <small className="grey-bold-text">
                                        Transaction Type
                                    </small>
                                    <p className="m-0">
                                        {title}
                                    </p>
                                </div>
                                <div className="">
                                    <small className="grey-bold-text">
                                        Date
                                    </small>
                                    <p className="m-0">
                                        {real_time}
                                    </p>
                                </div>
                                <div className="">
                                    <small className="grey-bold-text">
                                        Payment Type
                                    </small>
                                    <p className={`fw-bold m-0 ${ type === 'Credit' ? 'text-success' : 'red-text' }`}>
                                        {type}
                                    </p>
                                </div>
                                <div className="">
                                    <small className="grey-bold-text">
                                        Description
                                    </small>
                                    <p className="m-0">
                                        {narration}
                                    </p>
                                </div>
                                <div className="">
                                    <small className="grey-bold-text">
                                        Status
                                    </small>
                                    <p className={`m-0 ${ status === '0' ? 'text-success' : 'red-text' }`}>
                                        <span className='me-1'>
                                            {ICON_MARK_FILL}
                                        </span>
                                        Successful
                                    </p>
                                </div>
                            </StyledReceipt>
                        </SendLayout>
                        <div className="text-center">

                            <Button pry className="btn"
                                onClick={() => saveImage("trx")}
                            >
                                Download {ICON_SAVE}
                            </Button>
                            {/* <Button pry className="btn"
                                onClick={() =>
                                    shareImage("qrImg", "ReniTrust Payment QR Code")
                                }
                            >
                                Share {ICON_SHARE_FILL}
                            </Button> */}
                        </div>
                    </StyledTransaction>
                </div>
            </AppWrapper>
        </>
    );
};

export default TransactionDetails;

const StyledReceipt = styled.div`
padding: 2rem 1rem;
/* border: 1px red solid; */
/* background-image: linear-gradient(to bottom,var(--receipt-bg) , var(--receipt-bg)), url(${ logo_blue_bg }) ;
background-size:contain;
background-position:bottom;
background-repeat: no-repeat; */


`;
const StyledTransaction = styled.div`

hr {
    border: var(--grey-bold-color) 0 1px 0 0 solid;
}
small {
    font-size:10px;
}
p{
    line-height: 1.1em;
}
    
`;