import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useUserContext } from './user_context';
import Api from '../api/axios';
import { useNavigate } from 'react-router-dom';
import { useSendFundContext } from './send_fund_context';

export const QrContext = React.createContext();

export const QrProvider = ({ children }) => {

    const [qrResult, setQrResult] = useState('');

    let navigate = useNavigate();
    const {
        claimQrFund: { handleShowClaimQrFund },
    } = useSendFundContext();

    const { usertoken } = useUserContext();

    // Function to read QR Code
    function readQr(scanResult, setissending) {
        if (scanResult?.qrCodeData) {
            setissending(true);
            const data = {

                usertoken,
                qrCodeData: scanResult?.qrCodeData,
            };
            Api.post(`/readQRCode`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setissending(false);
                    } else {
                        toast.success(res.message);
                        setissending(false);
                        setQrResult(res.data);

                    }
                })
                .catch((error) => {
                    toast.warn(error.message === "Request failed with status code 400" ? "Unknown Request- Qr Code is not valid!" : error.message);
                    setissending(false);
                });
        } else {
            setissending(false);
        }
    }
    // Function to read QR Code

    // Data from QR Code
    const { qrCodeRawData, requestData, userData: qrUser } = qrResult;

    const instruction = qrCodeRawData?.instruction;

    let token = qrCodeRawData?.reqid;
    let sendid = qrCodeRawData?.sendid;
    let fullname = requestData?.data?.userData?.data?.fullname;
    let amount = requestData?.data?.amount;
    let decline = requestData?.data?.decline;
    let status = requestData?.data?.status;

    let username = qrUser?.data?.username;
    let fname = qrUser?.data?.fullname;
    let photo = qrUser?.data?.photo;
    let email = qrUser?.data?.email;
    // Data from QR Code


    // QR for User Profile
    function userProfile() {
        const data = { fname, username, photo, email };
        navigate(`/user/wallet/send/${ username }`,
            { state: data }
        );
    }
    // QR for User Profile

    // QR for Fund Request
    function payRequest() {
        const data = { fullname, amount, token, decline, status };
        navigate('/user/wallet/request/pay',
            { state: data }
        );
    }
    // QR for Fund Request

    // QR for Claiming Fund Request
    const claimData = { fullname, amount, sendid };
    function claimFunds() {
        handleShowClaimQrFund();
    }
    // QR for Claiming Fund Request

    useEffect(() => {
        // QR for User Profile
        if (instruction) {
            if (instruction === 'getUser') {
                userProfile();
            }
        }
        // QR for Fund Request
        if (instruction) {
            if (instruction === 'reqCash_user') {
                payRequest();
            }
        }
        // QR for Claiming Fund Request
        if (instruction) {
            if (instruction === 'sendCash_user') {
                claimFunds();
            }
        }
        // QR for Claiming Anonymous Fund Request
        if (instruction) {
            if (instruction === 'sendCash') {
                claimFunds();
            }
        }
    }, [instruction]);

    return (
        <QrContext.Provider value={{
            readQr,
            claimData,
        }}>
            {children}
        </QrContext.Provider>
    );
};

// make sure use
export const useQrContext = () => {
    return useContext(QrContext);
};
