import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_EXCLAMATION } from "../../../utils/icons";
import { Button } from "../../../styles";
import SuccessTransfer from "../SuccessTransfer";
import { StyleConfirmFunds } from "../Wallet.styled";
import { useSendFundContext } from "../../../context/send_fund_context";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import AppLoader from "../../../utils/AppLoader";


const ConfirmDeclineRequest = (props) => {
  const [issending, setissending] = useState(false);
  const { usertoken } = useUserContext();

  const { u_name, amount, token: rf_token } = props;

  const {
    confirmDeclineRequest: { showConfirmDeclineRequest, handleCloseConfirmDeclineRequest },
    success: { showSuccess, handleCloseSuccess, handleShowSuccess },
  } = useSendFundContext();

  const openSuccess = () => handleShowSuccess();
  let navigate = useNavigate();

  // Send Money to PayRequest
  function submitFn() {
    if (rf_token) {
      setissending(true);
      const data = {

        usertoken,
        rf_token
      };
      Api.post(`/declineFundRequest`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
          } else {
            toast.success(res.message);
            setissending(false);
            setTimeout(() => {
              navigate('/user/dashboard');
            }, 500);
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Not completed, Try Again!");
    }
  }
  // Send Money to PayRequest

  return (
    <>
      <Modal
        show={showConfirmDeclineRequest}
        onHide={handleCloseConfirmDeclineRequest}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title red-text m-0">
            DECLINE MONEY REQUEST
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyleConfirmFunds>
            <div className="mb-5 pb-5">
              <h1 className="yellow-text display-2">
                {ICON_EXCLAMATION}
              </h1>

              <h5 className="">
                Are you sure?
              </h5>
              <h5 className="fw-light">
                You are about to decline
                <span className="fw-bold"> {naira}{th(amount)}</span> to
                <span className="fw-bold"> {u_name}</span> via money request
              </h5>
            </div>
            <Button

              disabled={issending}
              className="btn w-100 btn-danger"
              onClick={() => submitFn()}
            >
              {issending ? 'loading...' : "Proceed"}
            </Button>

          </StyleConfirmFunds>

        </Modal.Body>
      </Modal>

      <AppLoader
        load={issending} />
    </>
  );
};

export default ConfirmDeclineRequest;


