import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ICON_PLUS } from '../../utils/icons';
import BalanaceCard from '../../components/dashboard/BalanaceCard';
import EscrowTransaction from '../../components/escrow/EscrowTransaction';
import AppWrapper from '../../layout/AppWrapper';
import { Button } from '../../styles';

const Escrow = () => {
  return (
    <AppWrapper
      pagename={"Escrow"}
    >
      <StyledEscrow>
        <div className="row">
          <div className="col-md-6 mb-3">
            <BalanaceCard />
          </div>
          <div className="col-md-6 mb-3">
            <Link to='new'>
              <Button className='btn w-100 br-md' dark>
                {ICON_PLUS}
                <h5 className="">
                  Initiate Escrow
                </h5>
              </Button>
            </Link>
          </div>
          <EscrowTransaction />
        </div>
      </StyledEscrow>
    </AppWrapper>
  );
};

export default Escrow;

export const StyledEscrow = styled.div`
  
  button {
    min-height:234px;
    font-size: 18px;

    
    svg {
      font-size: 60px;
      margin-bottom: 1rem;
    }
    
    @media screen and (max-width: 780px) {
      min-height:77px;
      font-size: 12px;
      display:flex;
      justify-content:center;
      align-items: center;
      
      svg {
        font-size: 20px;
        margin-top: .5rem;
        margin-right: .5rem;
      }
    }
  }
`;