
import styled from 'styled-components';
import AppWrapper from '../../layout/AppWrapper';
import { Button } from '../../styles';
import { ICON_LINK } from '../../utils/icons';
import PayLinkHistory from '../../components/paylink/PayLinkHistory';
import { Link } from 'react-router-dom';

const PayLink = () => {
      return (
            <AppWrapper
                  pagename={"Payment Link"}
            >
                  <StyledPayLink>
                        <div className="col-md-10 col-lg-8 light-bg border py-4 px-3 br-md">
                              <div className="w-100 ">
                                    <Link to='new'>
                                          <Button pry className='btn w-100'>
                                                {ICON_LINK} Create new payment link
                                          </Button>
                                    </Link>
                              </div>

                              <PayLinkHistory />
                        </div>
                  </StyledPayLink>



            </AppWrapper>
      );
};

export default PayLink;

const StyledPayLink = styled.section`
      

`;