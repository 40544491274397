import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFetch } from '../../api/useFetch';
import AppWrapper from '../../layout/AppWrapper';
import AppLoader from '../../utils/AppLoader';
import { ICON_PEOPLE_ARROW } from '../../utils/icons';
import BackButton from '../BackButton';
import DisputeChat from './DisputeChat';
import DisputeSendChat from './DisputeSendChat';


const DisputeDetails = () => {

    const location = useLocation();
    const params = useParams();

    const { id: disputeToken } = params;

    const { state: { escrowtoken } = { escrowtoken: '' } } = location;
    const datas = { disputeToken, escrowtoken };
    const token = { disputeToken, escrowToken: escrowtoken };

    const { loading: loadingDetails, data: { data: details } = {}, fetchData: getDisputeDetails } = useFetch('getDisputeDetails', { ...token });

    const { loading: loadingChat, data: { data: chat } = {}, fetchData: getDisputeChat } = useFetch('getDisputeChat', { disputeToken });

    const getData = { getDisputeChat, getDisputeDetails, chat };

    return (
        <AppWrapper
            pagename={"Dispute"}
        >
            <AppLoader
                load={(loadingChat || loadingDetails)}
            />
            <BackButton />
            <div className="my-3">
                <h5>
                    Dispute {details?.disputeToken || 'ABCDEGF'}

                </h5>
                <div className="container">
                    <div className="br-md light-bold-bg row py-3 justify-content-between">
                        <div className="col-md-4">
                            <small>
                                Date:
                            </small>
                            <p className='m-0 fw-bold'>
                                {details?.realTime || '01-01-1970'}
                            </p>
                        </div>
                        <div className="col-md-4">
                            <small>
                                Raised by:
                            </small>
                            <p className='m-0 fw-bold'>
                                {details?.userData?.data.fullname || 'Jane Doe'}

                            </p>
                        </div>
                        <small className="grey-bold-text ">
                            This conversation is being recorded for future use, analysis and administrative decision making
                        </small>
                        <Link to={`/user/escrow/${ escrowtoken }`} className="fit-content">
                            <p className="pry-text m-0 pointer">
                                Open Escrow Transaction {ICON_PEOPLE_ARROW}
                            </p>
                        </Link>
                    </div>
                </div>

                <hr />

                <DisputeChat
                    {...datas}
                    {...getData}
                />
                <br />
                <DisputeSendChat
                    {...datas}
                    {...getData}
                />
            </div>

        </AppWrapper>
    );
};

export default DisputeDetails;