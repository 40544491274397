import React, { useState, useRef } from 'react';
import { Input } from '../styles';


const Test = () => {


      const [otp, setOtp] = useState(['', '', '', '']); // Initialize with empty strings for 4 OTP fields
      const otpFields = useRef([]); // Ref to store references to the OTP input fields

      const handleChange = (e, index) => {
            const value = e.target.value;
            if (value.length <= 1) {
                  const otpCopy = [...otp];
                  otpCopy[index] = value;
                  setOtp(otpCopy);

                  // Move focus to the next input field
                  if (value !== '') {
                        const nextIndex = Math.min(index + 1, otpFields.current.length - 1);
                        otpFields.current[nextIndex].focus();
                  }
            }
      };

      const handleKeyDown = (e, index) => {
            // Move focus to the previous input field on backspace/delete key press
            if (e.key === 'Backspace' || e.key === 'Delete') {
                  if (otp[index] === '') {
                        const prevIndex = Math.max(index - 1, 0);
                        otpFields.current[prevIndex].focus();
                  }
            }
      };


      return (
            <>
                  <div className="row">
                        <div className="col-3">
                              {otp.map((digit, index) => (
                                    <Input
                                          key={index}
                                          type="text"
                                          value={digit}
                                          maxLength={1}
                                          onChange={(e) => handleChange(e, index)}
                                          onKeyDown={(e) => handleKeyDown(e, index)}
                                          ref={(el) => (otpFields.current[index] = el)}
                                    />
                              ))}
                        </div>
                  </div>

            </>
      );
};

export default Test;



// import { useEffect } from 'react';
// import { useState } from 'react';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import styled from 'styled-components';
// import Api from '../../api/axios';
// import { Form, Input, Label, Button } from '../../styles';
// import { EMAIL_REGEX } from '../../utils/data';
// import AuthWrapper from '../../layout/AuthWrapper';

// const EmailRegister = () => {
//       const [formField, setformField] = useState({});
//       const [authField, setauthField] = useState({});
//       const [emailValid, setemailValid] = useState(false);
//       const navigate = useNavigate();
//       const [sending, setsending] = useState(false);
//       const [verifying, setverifying] = useState(false);
//       const [authCode, setauthCode] = useState('');

//       let params = useParams();
//       const { id } = params;

//       // Form Fields
//       function handleChange(e) {
//             setformField((inputs) => ({
//                   ...inputs,
//                   [e.target.name]: e.target.value,
//             }));
//       }
//       // Form Fields
//       // Auth Fields
//       function handleAuth(e) {
//             setauthField((inputs) => ({
//                   ...inputs,
//                   [e.target.name]: e.target.value,
//             }));
//       }
//       useEffect(() => {
//             EMAIL_REGEX.test(formField.email) ? setemailValid(true) : setemailValid(false);
//       }, [formField.email]);

//       useEffect(() => {
//             setauthCode(`${ authField.code1 }${ authField.code2 }${ authField.code3 }${ authField.code4 }${ authField.code5 }`);
//       }, [authField.code1, authField.code2, authField.code3, authField.code4, authField.code5]);
//       // Auth Fields

//       // Submit Function
//       function submitFn(e) {
//             e.preventDefault();
//             if (!formField.email && !emailValid) {
//                   toast.warn('enter valid email!');
//                   return;
//             }
//             if (!authField.code1 || !authField.code2 || !authField.code3 || !authField.code4 || !authField.code5) {
//                   toast.warn('Input verification code!');
//                   return;
//             }
//             setverifying(true);
//             const data = {

//                   authCode: authCode.toLocaleUpperCase(),
//                   mail: formField.email
//             };
//             Api.post(`/activateEmail`, data)
//                   .then((res) => {
//                         if (res.success === false) {
//                               toast.warn(res.message);
//                               setverifying(false);
//                         } else {
//                               setverifying(false);
//                               navigate(
//                                     `/register-complete`,
//                                     { state: { email: formField.email, ref: id } }
//                               );
//                               setTimeout(() => {
//                                     toast.success(res.message);
//                               }, 10);
//                         }
//                   })
//                   .catch((error) => {
//                         setverifying(false);
//                   });
//       }

//       // Submit Function
//       // Submit Function
//       function sendCode(e) {
//             if (!formField.email && !emailValid) {
//                   toast.warn('enter valid email!');
//                   return;
//             }
//             e.preventDefault();
//             setsending(true);
//             const data = {

//                   mail: formField.email,
//             };
//             Api.post(`/registerEmail`, data)
//                   .then((res) => {
//                         if (res.success === false) {
//                               toast.warn(res.message);
//                               setsending(false);
//                         } else {
//                               setsending(false);
//                               setTimeout(() => {
//                                     toast.success(res.message);
//                               }, 10);
//                         }
//                   })
//                   .catch((error) => {
//                         setsending(false);
//                   });
//       }

//       // Submit Function

//       return (

//             <AuthWrapper
//                   title='Create Account'
//                   subTitle='Build your business with Escrow Transactions'
//             >
//                   <Form>

//                         <div className="row justify-content-center text-start">
//                               <div className="col-md-12 mb-5">
//                                     <Label htmlFor="email" className="">
//                                           Email:
//                                     </Label>
//                                     <Input
//                                           type="email"
//                                           id='email'
//                                           name="email"
//                                           placeholder="Email Address"
//                                           onChange={handleChange}
//                                           required
//                                     />
//                                     <Button
//                                           pry
//                                           type="submit"
//                                           disabled={sending}
//                                           className="btn w-100"
//                                           onClick={(e) => sendCode(e)}
//                                     >
//                                           {sending ? 'loading...' : "Send"}
//                                     </Button>
//                               </div>

//                               <div className="col-md-12">
//                                     <Label className="">
//                                           Verification Code:
//                                     </Label>

//                                     <VerificationInputGroup>
//                                           <Input
//                                                 type="text"
//                                                 name="code1"
//                                                 onChange={handleAuth}
//                                                 required
//                                                 maxLength="1"
//                                           />
//                                           <Input
//                                                 type="text"
//                                                 name="code2"
//                                                 onChange={handleAuth}
//                                                 required
//                                                 maxLength="1"
//                                           />
//                                           <Input
//                                                 type="text"
//                                                 name="code3"
//                                                 onChange={handleAuth}
//                                                 required
//                                                 maxLength="1"
//                                           />
//                                           <Input
//                                                 type="text"
//                                                 name="code4"
//                                                 onChange={handleAuth}
//                                                 required
//                                                 maxLength="1"
//                                           />
//                                           <Input
//                                                 type="text"
//                                                 name="code5"
//                                                 onChange={handleAuth}
//                                                 required
//                                                 maxLength="1"
//                                           />

//                                           <Button
//                                                 pry
//                                                 type="submit"
//                                                 disabled={verifying}
//                                                 className="btn"
//                                                 onClick={(e) => submitFn(e)}
//                                           >
//                                                 {verifying ? 'loading...' : "Verify"}
//                                           </Button>
//                                     </VerificationInputGroup>

//                               </div>

//                               <div className="col-md-12 text-center mt-4">

//                                     <div className="text-center">
//                                           Have an Account?
//                                           <Link to="/login" className="pry-text fw-bold"> Login </Link>
//                                     </div>
//                               </div>
//                         </div>
//                   </Form>
//             </AuthWrapper>
//       );
// };

// export default EmailRegister;

// export const VerificationInputGroup = styled.div`
// display:flex;
// justify-content: space-between;
// gap: 8px;
// align-items: center;

// input {
//   padding: 12px 5px;
//   text-align:center;
//   text-transform: uppercase;
//   font-weight: 800;
//   font-size:20px;
// }

// button {
//   height: 50px !important;
//   margin: 8px 0 !important;
//   margin-bottom: 1.5rem !important;
//   }
// `;