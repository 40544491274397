import styled from "styled-components";
import { ICON_BELL_FILL } from "../../utils/icons";
import EmptyData from "../../components/EmptyData";
import { Modal } from "react-bootstrap";
import { useUserContext } from "../../context/user_context";
import { useState } from "react";
import DOMPurify from "dompurify";
import Api from "../../api/axios";
import { useFetch } from "../../api/useFetch";
import { ClipLoader } from 'react-spinners';


const AllNotifications = () => {
    const [doData, setdoData] = useState({});
    const [showNotification, setShowNotification] = useState(false);
    const handleCloseNotification = () => setShowNotification(false);
    const handleShowNotification = (props) => { setShowNotification(true); setdoData(props); markRead(props); };

    const { loading, data: allNotification, fetchData: getNotification } = useFetch('getNotifications');


    const { usertoken } = useUserContext();

    // mark read
    function markRead(e) {
        if (e.status === '0') {
            const data = {
                usertoken,
                nid: e.id
            };
            Api.post(`/readNotification`, data)
                .then((res) => {
                    getNotification();
                }).catch((err) => {
                });
        }
    }
    // mark read


    return (

        <StyledNotification>
            <h5 className="pry-text my-4 text-center d-flex justify-content-center">
                All Notifications
                {loading &&
                    <ClipLoader size='20' color={'var(--dark-color)'} className='mx-2' />
                }
            </h5>
            <div className="all-notify mt-3 px-2 px-lg-4 br-md py-5 mx-auto col-lg-8">
                <EmptyData
                    data={allNotification?.data}
                    title='notifications'
                />

                {allNotification?.data?.map((props) => {
                    const { real_time, id, status, subject } = props;
                    return (<div className="row mb-4" key={id}>
                        <div className="col-2 col-md-1">
                            <span className={`${ status === '0' ? 'pry' : 'grey-bg' } light-text`}>
                                {ICON_BELL_FILL}
                            </span>
                        </div>
                        <div className="col-7 col-md-9">
                            <p className={`${ status === '0' ? 'fw-bold' : 'grey-bold-text' } m-0 mb-1 lh-1`}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(subject)
                                }}
                            />
                            <small className={status === '0' ? 'date' : 'grey-bold-text date'}>
                                {real_time}
                            </small>
                        </div>
                        <div className="col-3 col-md-2 text-end pointer"
                            onClick={() => handleShowNotification(props)}
                        >
                            <small className="pry-text ">
                                DETAILS
                            </small>
                        </div>
                    </div>);
                }).reverse()}
            </div>
            <NotificationDetail
                show={showNotification}
                onHide={handleCloseNotification}
                data={doData}
            />
        </StyledNotification >

    );
};

export default AllNotifications;


const NotificationDetail = (props) => {
    const { data: { body, real_time, id, status, subject } } = props;

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Header closeButton>
                <p className="title pry-text m-0">
                    NOTIFICATION DETAILS
                </p>
            </Modal.Header>
            <Modal.Body>
                <h6 className="fw-bold">
                    {subject}
                </h6>
                <small className="grey-bold-text">
                    {real_time}
                </small>
                <hr />
                <p className=""
                    dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(body)
                    }}
                />
            </Modal.Body>
        </Modal>
    );
};


const StyledNotification = styled.section`
    .all-notify {
        box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);
        
        span {
            border-radius: 50%;
           padding:.4rem ;
        }
        span svg {
            font-size:16px ;
            /* margin: 9px; */
        }
        small.date {
            font-size:12px;
        }
    }


`;