import { useEffect, useState } from "react";
import { useBillsContext } from "../../../context/bill_context";
import { Form, Label, Input, Button, UserDetail } from "../../../styles";
import Select from 'react-select';
import { toast } from "react-toastify";
import { ICON_EXCLAMATION, ICON_MARK_FILL } from "../../../utils/icons";
import ConfirmElectricity from "./ConfirmElectricity";
import { Fee, customOption } from "../../../utils/helpers";
import { naira } from "../../../utils/data";
import BillWrapper from "../../../layout/BillWrapper";
import { useWalletContext } from "../../../context/wallet_context";
import CustomSelect from "../../../utils/CustomSelect";


const BuyElectricity = () => {

    const [formField, setformField] = useState({ number: '', name: '', disco: '', type: '', address: '', amount: '' });
    const [selectedBiller, setselectedBiller] = useState('');
    const [selectedMeter, setselectedMeter] = useState('');
    const [proceed, setproceed] = useState(false);


    const {
        electricity: { getElectricityBill, allElectricityBiller, getMeterName, meterLoading, allElectricityBillerLoad, message }
    } = useBillsContext();
    const {
        getCharges: { charges },
    } = useWalletContext();


    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    function checkSendDetails(e) {
        e.preventDefault();
        if (!selectedBiller) {
            toast.warn('Select a Biller!');
            return;
        }
        if (!formField?.number?.length >= 10) {
            toast.warn('Input Correct Meter/Card Number!');
            return;
        }
        if (selectedBiller && formField?.number) {
            getMeterName(setselectedMeter, formField, setformField, selectedBiller);
        }
    }

    useEffect(() => {
        if (selectedMeter) {
            setproceed(true);
        }
    }, [selectedMeter]);

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        if (formField?.amount) {
            setShow(true);
        } else {
            toast.warn('Input Amount!');
        }
    };
    // Modal

    const data = { formField, selectedBiller };


    useEffect(() => {
        getElectricityBill();
    }, []);

    return (
        <BillWrapper
            title='Electricity'
            load={(meterLoading || allElectricityBillerLoad)}
        >
            <Form>
                <div className="col-lg-6 input-col">
                    <Label htmlFor="operator">
                        SERVICE PROVIDER:
                    </Label>
                    <CustomSelect
                        variant="-bill"
                        id="biller"
                        options={allElectricityBiller}
                        isSearchable={true}
                        onChange={(e) => setselectedBiller(e)}
                        value={selectedBiller}
                        placeholder='Select Service Provider'
                        required
                        isDisabled={selectedMeter ? true : false}
                        components={{ Option: customOption }}
                    />
                </div>

                <div className="col-lg-6 input-col">
                    <Label htmlFor="number">
                        METER/CARD NUMEBR:
                    </Label>
                    <Input
                        type="number"
                        name="number"
                        placeholder="Meter/Card Number"
                        onChange={handleChange}
                        required
                        className="input-style"
                        disabled={selectedMeter ? true : false}

                    />
                    {(formField.number && selectedBiller) &&
                        <>
                            {meterLoading ? <p>Loading...</p> :
                                <>
                                    <br />
                                    {message &&
                                        <UserDetail className={selectedMeter ? 'text-success' : 'grey-bold-text -'}>
                                            {selectedMeter ? ICON_MARK_FILL : ICON_EXCLAMATION}
                                            {selectedMeter ? selectedMeter?.customerName : (message || 'loading...')}
                                        </UserDetail>
                                    }
                                </>
                            }
                        </>
                    }

                </div>
                {proceed &&
                    <>
                        <div className="col-lg-6 input-col">
                            <div className="d-flex justify-content-between">
                                <Label htmlFor="amount">
                                    AMOUNT({naira}) ?
                                </Label>
                                {' '}
                                <Fee fee={charges?.electricity_bill.amount} />
                            </div>
                            <Input
                                type="number"
                                name="amount"
                                placeholder="Amount"
                                onChange={handleChange}
                                required
                                className="input-style"
                            />
                        </div>
                    </>
                }
                <div className="my-4 col-lg-6">
                    {!selectedMeter ?
                        <Button
                            pry
                            className="btn m-0 w-100"
                            onClick={(e) => checkSendDetails(e)}
                        >
                            Next
                        </Button>
                        :
                        <Button
                            pry
                            className="btn m-0 w-100"
                            onClick={(e) => handleShow(e)}
                        >
                            Proceed
                        </Button>
                    }
                </div>
            </Form>
            <ConfirmElectricity
                show={show}
                onHide={handleClose}
                {...data}
            />
        </BillWrapper>
    );
};

export default BuyElectricity;