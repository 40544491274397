import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { white_ribbon, yellow_ribbon } from '../../assets';
import { Button } from '../../styles';

const RegisterDone = () => {

    let locationData = useLocation();
    let navigate = useNavigate();

    useEffect(() => {
        if (!locationData.state) {
            navigate('/noAccess');
        }
    }, []);

    return (
        <Wrapper>
            <img src={yellow_ribbon} alt="a yellow ribbon" className='ribbon1' />
            <h1 className="">
                You’re all set!
            </h1>
            <h3 className="fw-light">
                {/* Akindepraise5@gmail.com */}
                {locationData?.state}
            </h3>
            <p className="col-md-5 text-center">
                Your account has been created! You can sign in to your account now
            </p>
            <Link to="/login">
                <Button light className='btn fw-bold' >
                    Sign In
                </Button>
            </Link>
            <img src={white_ribbon} alt="a white ribbon" className='ribbon2' />
        </Wrapper>
    );
};

export default RegisterDone;

export const Wrapper = styled.main`
padding: 5rem 0;
min-height:100vh;
background: var(--pry-color);
display:flex;
flex-direction: column;
gap: 15px;
justify-content:center;
align-items: center;
color: var(--light-color);

.ribbon1{
    position:absolute;
    left:20%;
}
.ribbon2{
    position:absolute;
    right:20%;
}

@media screen and (max-width: 780px) {
    .ribbon1{
    position:static;
    left:0;
    margin-bottom:2rem;
}
.ribbon2{
    position:static;
    right:0;
    margin-top:2rem;
}
}

button {
    font-size:15px !important;
    margin-top:3rem;
    padding: auto 2rem !important;
}

`;