import BottomNav from "./BottomNav";
import SideNav from "./SideNav";
import TopNav from "./TopNav";



const Navbar = ({ pagename }) => {

  return (
    <>
      <TopNav
        pagename={pagename}
      />
      <SideNav />
      <BottomNav />
    </>
  );
};

export default Navbar;
