
import { useState } from 'react';
import AccountSettings from '../../components/Profile/AccountSettings';
import NextOfKinSettings from '../../components/Profile/NextOfKinSettings';
import { StyledProfile } from '../../components/Profile/Profile.styled';
import AppWrapper from '../../layout/AppWrapper';
import { StyledTab } from '../../styles';

const Profile = () => {
  const [selectedLink, setselectedLink] = useState('account');


  // function selectData(e) {
  //   if (e === 'next of kin') {
  //     setselectedLink('next of kin');
  //   } else if (e === 'security') {
  //     setselectedLink('security');
  //   } else if (e === 'kyc') {
  //     setselectedLink('kyc');
  //   } else {
  //     setselectedLink('account');
  //   }
  // }
  function selectData(e) {
    if (e === 'next of kin') {
      setselectedLink('next of kin');
    } else {
      setselectedLink('account');
    }
  }

  // const links = ['account', 'next of kin', 'security', 'kyc'];
  const links = ['account', 'next of kin'];

  return (
    <AppWrapper
      pagename={"Profile"}
    >

      <StyledProfile>
        <StyledTab className=''>
          {links.map((e, i) =>
            <div className={`links ${ e === selectedLink && 'active' }`}
              key={i}
              onClick={() => selectData(e)}
            >{e}</div>
          )}
        </StyledTab>

        <div className="my-4">
          {/* {selectedLink === 'next of kin' ?
            <NextOfKinSettings /> :
            selectedLink === 'security' ?
              <SecuritySettings /> :
              selectedLink === 'kyc' ?
                <KycSettings /> :
                <AccountSettings />
          } */}
          {selectedLink === 'next of kin' ?
            <NextOfKinSettings /> :
            <AccountSettings />
          }
        </div>

      </StyledProfile>
    </AppWrapper>
  );
};

export default Profile;