import { useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../../styles";
import { useUserContext } from "../../context/user_context";
import { naira } from "../../utils/data";
import { th } from "../../utils/helpers";
import AppLoader from "../../utils/AppLoader";
import { usePayLinkContext } from "../../context/paylink_context";
import { toast } from 'react-toastify';
import Api from "../../api/axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";


const ConfirmNewPayLink = (props) => {
  const [sending, setsending] = useState(false);
  const { usertoken } = useUserContext();
  const navigate = useNavigate();

  const { formField } = props;

  const { title, amount, description, redirect_link, photo } = formField;

  const {
    confirmNewPayLink: { showConfirmNewPayLink, handleCloseConfirmNewPayLink },
  } = usePayLinkContext();


  // Submit Function
  function submitFn(e) {
    e.preventDefault();
    if (
      title && amount && description && photo
    ) {
      setsending(true);
      const data = {
        usertoken,
        name: title,
        amount,
        desc: description,
        redirect_url: redirect_link,
        image: photo
      };


      Api.post(`/paymentLink.create`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setsending(false);
          } else {
            setsending(false);
            handleCloseConfirmNewPayLink();
            navigate(`/user/paylink/${res.data.token}`)
            setTimeout(() => {
              toast.success(res.message);
            }, 10);
          }
        })
        .catch((error) => {
          setsending(false);
        });
    } else {
      setsending(false);
      toast.warn("Empty Fields!");
    }
  }
  // Submit Function

  return (
    <>
      <Modal
        show={showConfirmNewPayLink}
        onHide={handleCloseConfirmNewPayLink}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            CONFIRM PAYMENT LINK
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyledConfirmPayLink>
            <div className="mb-5">
              <div className="container py-3 my-2 light-bold-bg br-md">

                <small>
                  Title
                </small>
                <p>
                  {title}
                </p>

                <small>
                  Description
                </small>
                <p>
                  {description}
                </p>

                <small>
                  Amount
                </small>
                <p>
                  {naira}{th(amount)}
                </p>
                <small>
                  Redirect Link
                </small>
                <p>
                  {redirect_link}
                </p>
              </div>
              <div className="container py-3 my-2 light-bold-bg br-md">
                <small>
                  Cover Photo
                </small>
                <div className="">
                  <img src={photo} alt="Cover" width='50%' className="br-md" />
                </div>

              </div>

            </div>
            <div className="">
              <Button
                pry
                type="submit"
                disabled={sending}
                className="btn w-100"
                onClick={(e) => submitFn(e)}
              >
                {sending ? 'loading...' : "Submit"}
              </Button>
            </div>

          </StyledConfirmPayLink>
        </Modal.Body>
      </Modal>


      <AppLoader
        load={sending}
      />
    </>
  );
};

export default ConfirmNewPayLink;

const StyledConfirmPayLink = styled.div`
  small {
    color: var(--grey-bold-color);
  }
  p {
    padding:0;
    margin:0 0 5px 0;
    line-height:1;
  }
`


