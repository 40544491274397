import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSendFundContext } from "../../context/send_fund_context";
import { naira } from "../../utils/data";
import { th } from "../../utils/helpers";
import { ICON_PAY_MONEY } from "../../utils/icons";
import EmptyData from "../EmptyData";


const AllRequest = () => {

    const {
        payRequest: { requestList },
    } = useSendFundContext();

    const navigate = useNavigate();

    function payRequest(e) {
        const { token } = e;

        navigate(`/user/wallet/request/pay/`,
            { state: {token} }
        );
    }

    return (

        <StyledNotification>
            <h5 className="pry-text my-4 text-center">
                All Requests
            </h5>
            <div className="all-notify mt-3 px-2 px-lg-4 br-md py-5 mx-auto col-lg-8">
                <EmptyData
                    data={requestList?.data}
                    title='request'
                />
                {requestList?.data?.map((item) => {
                    const { userData: { data: { fullname } }, real_time, amount, decline, status, token } = item;
                    return (
                        <div className="row mb-4 shadow-sm mx-1 align-items-center" key={token}>
                            <div className="col-2 col-md-1">
                                <h4 className="shadow-sm text-success text-auto fit-content py-1 px-2 br-xlg">
                                    {ICON_PAY_MONEY}
                                </h4>
                            </div>
                            <div className="col-7 col-md-9">
                                <p className="m-0 mb-1 lh-1">
                                    {fullname}
                                </p>
                                <h5 className="m-0 mb-1 lh-1">
                                    {naira}{th(amount)}
                                </h5>
                                <small className="date grey-bold-text">
                                    {real_time}
                                </small>
                                <span className="grey-bold-text">

                                    ●
                                </span>
                                {status === '1' &&
                                    <small className="date badge badge-success">
                                        Confirmed
                                    </small>
                                }
                                {decline === '1' &&
                                    <small className="date badge badge-danger">
                                        Declined
                                    </small>
                                }
                                {(decline === '1') || (status === '1') ?
                                    <></> :
                                    <small className="date badge badge-warning">
                                        Pending
                                    </small>
                                }
                            </div>
                            <div className="col-3 col-md-2 text-end pointer"
                                onClick={() => payRequest(item)}>
                                <small className="pry-text">
                                    DETAILS
                                </small>
                            </div>
                        </div>);
                }
                )}
            </div>
        </StyledNotification>

    );
};

export default AllRequest;

const StyledNotification = styled.section`
    .all-notify {
        box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);
        
        span {
            border-radius: 50%;
           padding:.4rem ;
        }
        span svg {
            font-size:16px ;
        }
        small.date {
            font-size:12px;
        }
    }


`;