import { useEffect, useState } from "react";
import styled from "styled-components";
import { useSendFundContext } from "../../../context/send_fund_context";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { ICON_QR, ICON_EXCLAMATION, ICON_MARK_FILL } from "../../../utils/icons";
import { Button, Form, Input, Label, NameInput, UserDetail } from '../../../styles';
import { toast } from 'react-toastify';
import { naira } from "../../../utils/data";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import SuccessQr from "./SuccessQr";
import AppLoader from "../../../utils/AppLoader";
import { ClipLoader } from "react-spinners";


const RequestQr = () => {


  const [formField, setformField] = useState({
    u_name: '', amount: '', r_name: ''
  });
  const [proceed, setproceed] = useState(false);
  const [selectUser, setselectUser] = useState('');
  const [genQr, setgenQr] = useState(false);
  const [issending, setissending] = useState(false);
  const [qrData, setqrData] = useState(JSON.stringify({ qrCodeData: '' }));
  const {
    user: { searchUser, message, loadingName, },
    successRequestQr: { handleShowSuccessRequestQr },
  } = useSendFundContext();

  // Open Success If successful
  const openSuccess = (data) => {
    setqrData(
      JSON.stringify(data)
    );
    handleShowSuccessRequestQr();
  };
  // Open Success If successful

  // Context
  const { usertoken } = useUserContext();
  // Context


  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field

  // Search User Fn
  useEffect(() => {
    searchUser(formField.u_name, setformField, setselectUser);
  }, [formField.u_name]);
  // Search User Fn

  // Verify Details
  function checkSendDetails(e) {
    e.preventDefault();
    if (!selectUser) {
      toast.warn('Unable to verify Username!');
      return;
    }
    setproceed(true);
  }
  // Verify Details

  // Submit Function
  function submitFn() {
    if (
      formField.u_name && formField.amount
    ) {
      setissending(true);
      const data = {

        usertoken,
        amount: formField.amount,
        username: formField.u_name
      };
      Api.post(`/requestFundQRCode`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
          } else {
            toast.success(res.message);
            setissending(false);
            openSuccess(res.data);
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Input Correct Details!");
    }
  }
  // Submit Function


  // Confirm function before submitting
  function confirm(e) {
    e.preventDefault();
    if (
      formField.u_name && formField.amount
    ) {
      if (window.confirm(`Are you sure? You are about to request ${ naira }${ formField?.amount } form ${ selectUser?.fullname } (${ formField?.u_name }) Confirm?`)) {
        submitFn();
      }
    } else {
      toast.warn("Input Correct Details!");
    }
  }
  // Confirm function before submitting

  // Data for success modal
  const data = { formField, qrData };
  // Data for success modal
  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledSendToQr>
        <SendLayout
          title={'Generate QR Code'}>
          <div className="text-center mb-5">
            <h1 className="yellow-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_QR}
            </h1>
            <p>
              Generate custom QR codes instantly for to request fund from a ReniTrust User.
            </p>
            {!genQr &&
              <Button pry
                className="btn"
                onClick={(e) => setgenQr(true)}
              >
                Generate QR Code {ICON_QR}
              </Button>
            }
          </div>
          <div className="my-5">


            {/* Send Form */}
            {genQr &&
              <Form
                className="my-5"
              >
                <div className="justify-content-center text-left">

                  <div className="col-lg-12 ">
                    <Label htmlFor="u_name"
                      className='fw-bold dark-text name_label'
                    >
                      Request money from
                    </Label>
                    {loadingName &&
                      <ClipLoader size='20' color={'var(--pry-color)'} className='mx-2' />
                    }
                    <NameInput
                      type="search"
                      name="u_name"
                      id="u_name"
                      placeholder="Reni Username"
                      onChange={handleChange}
                      value={formField.u_name}
                      required
                    />
                    {formField.u_name &&
                      <>
                        <br />
                        <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                          {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                          {selectUser ? selectUser?.fullname : message}
                        </UserDetail>
                      </>
                    }
                    {!proceed &&
                      <div className="col-lg-12">
                        <Button
                          pry
                          className="btn w-100"
                          onClick={(e) => checkSendDetails(e)}
                        >
                          Next
                        </Button>
                      </div>
                    }

                    {proceed &&
                      <>
                        <Label htmlFor="amount">
                          Amount:
                        </Label>

                        <Input
                          type="number"
                          name="amount"
                          id="amount"
                          placeholder={`Input Amount`}
                          className='mb-1'
                          onChange={handleChange}
                          value={formField.amount}
                          required
                        />

                        <Label htmlFor="details">
                          Narration:
                        </Label>
                        <Input
                          type="text"
                          name="details"
                          id="details"
                          placeholder="Input Narration"
                          onChange={handleChange}
                          value={formField.details}
                          required
                        />
                        <div className="col-lg-12">
                          <Button
                            pry
                            disabled={issending}
                            className="btn w-100 m-0"
                            onClick={(e) => confirm(e)}
                          >
                            {issending ? 'loading...' : "Proceed"}
                          </Button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </Form>
            }

            {/* Send Form */}

          </div>
        </SendLayout>
      </StyledSendToQr>

      <SuccessQr
        {...data}
        name={'Request'}

      />

      <AppLoader
        load={issending}
      />
    </AppWrapper >
  );
};

export default RequestQr;

export const StyledSendToQr = styled.div`

`;
