import { useState } from "react";
import { toast } from "react-toastify";
import { useUserContext } from "../../../context/user_context";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button } from "../../../styles";
import { CopyItem, shareItem } from "../../../utils/helpers";
import { ICON_AT, ICON_COPY, ICON_SHARE_FILL } from "../../../utils/icons";


const ShareUsername = () => {

  const { userData } = useUserContext();
  const { username } = userData;
  // Copy Function 
  const [copied, setcopied] = useState(false);
  function copy() {
    toast.success('Copied to Clipboard');
    setcopied(!copied);
    setTimeout(() => {
      setcopied(false);
    }, 5000);
  }
  // Copy Function

  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <SendLayout
        title={'Share Reni Username'}
      >
        <div className="text-center mb-5">
          <h1 className="pry-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
            {ICON_AT}
          </h1>

          <h5>
            Hi, <span className="grey-bold-text">@</span>{username || 'janedoe'}
          </h5>
          <p>
            Receive money from your friends on ReniTrust with your username.
          </p>
        </div>

        <div className="light-bold-bg br-sm py-3 px-3">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="m-0 fw-bold">
              <span className="grey-bold-text">@</span>{username || 'janedoe'}
            </h6>
            <CopyItem value={username}>
              <p
                className="m-0 pry-text small pointer"
                onClick={() => copy()}
              >
                {ICON_COPY}
                {copied ? ' COPIED' : ' COPY'}

              </p>
            </CopyItem>

          </div>
        </div>
        <div className="mt-5">

          <Button pry
            className="btn w-100"
            onClick={() => shareItem({
              title: 'Share Reni Username',
              text: `Hi, @${ username } is my username on ReniTrust. /n Open ReniTrust to get yours too.`,
              url: `https://renitrust.com`
            })}
          >
            {ICON_SHARE_FILL} SHARE
          </Button>
        </div>
      </SendLayout>
    </AppWrapper >
  );
};

export default ShareUsername;
