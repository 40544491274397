import { useState } from 'react';
import styled from 'styled-components';
import { ICON_ARROW_RIGHT_CIRCLE } from '../../utils/icons';
import { Input, StyledTab } from '../../styles';
import EmptyData from '../EmptyData';
import { useNavigate } from 'react-router-dom';
import { th } from '../../utils/helpers';
import { useEffect } from 'react';
import AppLoader from '../../utils/AppLoader';
import { useDisputeContext } from '../../context/dispute_context';
import { StyledEscrowSegment } from '../escrow/Escrow.styled';
import { naira } from '../../utils/data';

const DisputeHistory = () => {

    const {
        getAllDispute: { DisputeLoading, historyData, getDispute, selectData, searchTrx }
    }
        = useDisputeContext();

    const [selectedLink, setselectedLink] = useState('all');

    const links = ['all', 'pending', 'successful', 'closed'];

    useEffect(() => {
        getDispute();
    }, []);

    return (
        <StyledDisputeTransaction>

            <AppLoader load={DisputeLoading} />

            <h4 className="fw-light">
                All Dispute
            </h4>
            <div className="my-3">
                <StyledTab className=''>
                    {links.map((e, i) =>
                        <div className={`links ${ e === selectedLink && 'active' }`}
                            key={i}
                            onClick={() => selectData(e, setselectedLink)}
                        >{e}</div>
                    )}
                </StyledTab>
            </div>
            <StyledEscrowSegment>
                <div className="col-lg-8">
                    <Input
                        type="search"
                        name="search"
                        placeholder="Search Transaction (name, amount)"
                        onChange={(e) => searchTrx(e.target.value, setselectedLink)}
                        className="mb-0 m-0"
                    />
                    <DisputeList data={historyData} />
                </div>
            </StyledEscrowSegment>
        </StyledDisputeTransaction>
    );
};

export default DisputeHistory;

export const DisputeList = ({ data }) => {

    let navigate = useNavigate();

    function disputePage(disputeToken, escrowtoken) {
        navigate(`/user/dispute/${ disputeToken }`,
            { state: { disputeToken, escrowtoken } }
        );
    }

    return (
        <div className="escrow-history mt-3 px-2 px-lg-4 br-md py-4">

            <EmptyData
                data={data}
                title='dispute'
            />
            {
                data?.map(({ title, realTime, amount, escrowtoken, disputeToken, refunded, resolved }) => {
                    return (
                        <div className="history br-md" key={disputeToken}>
                            <h5 className="">
                                Dispute {disputeToken}
                            </h5>
                            {/* <h5>
                                {naira}{th(amount)}
                            </h5> */}
                            <p>
                                {realTime}
                            </p>
                            <p>
                                Escrow ID: {' '}
                                <span className="fw-bold">
                                    {escrowtoken}
                                </span>
                            </p>
                            <div className="d-flex justify-content-between mt-4">
                                <div className="d-flex gap-1">
                                    <p className={`${ resolved === '1' ? 'badge-success' : 'badge-warning' } badge mt-3 pt-2`}>
                                        {resolved === '1' ? 'Resolved' : 'Unresolved'}
                                    </p>
                                    {refunded === '1' &&
                                        <p className='badge badge-dark mt-3 pt-2'>
                                            Refunded
                                        </p>
                                    }
                                </div>
                                <span
                                    onClick={() => disputePage(disputeToken, escrowtoken)}>
                                    {ICON_ARROW_RIGHT_CIRCLE}
                                </span>

                            </div>
                        </div>
                    );
                })
            }

        </div>

    );
};

const StyledDisputeTransaction = styled.div`
margin: 3rem 0;
    

`;