import React, { useEffect, useState } from 'react';
import { SyncLoader } from 'react-spinners';
import styled from 'styled-components';
import { useWalletContext } from '../context/wallet_context';
import { useEscrowContext } from '../context/escrow_context';
import { useSendFundContext } from '../context/send_fund_context';

const AppLoader = (props) => {

  const {
    getAcctNo: { acctNoLoading },
    getTrxHist: { trxHistLoading }
  } = useWalletContext();

  const {
    getAllEscrow: { allEscrowLoading },
  } = useEscrowContext();
  const {
    banks: { allBanksLoading },
    user: { allUserLoading },
    beneficiary: { allBenLoading },
  } = useSendFundContext();


  const [loader, setloader] = useState(false);

  function Loader() {
    if ((acctNoLoading || allBanksLoading || allUserLoading || allEscrowLoading || allBenLoading || trxHistLoading)
    ) {
      setloader(true);
    } else {
      setloader(false);
    }
  }

  useEffect(() => {
    Loader();
  }, [acctNoLoading, allBanksLoading, allUserLoading, allEscrowLoading, allBenLoading, trxHistLoading]);

  return (
    <>
      {props.load &&
        <StyledLoader>
          <main>
            <SyncLoader color={"var(--pry-color)"} margin="6" size="14" speedMultiplier="1.2" />
          </main>
        </StyledLoader>
      }
      {loader &&
        <StyledLoader>
          <main>
            <SyncLoader color={"var(--pry-color)"} margin="6" size="14" speedMultiplier="1.2" />
          </main>
        </StyledLoader>
      }

    </>
  );
};

export default AppLoader;







const StyledLoader = styled.div`
   width: 100%;
  height: 100vh;
  background: var(--loader-bg);
  display: grid;
  place-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000 !important;
`;