import { StyledAccount } from "../Profile/Profile.styled";
import UpdatePassword from "./UpdatePassword";
import UpdateTrxPin from "./UpdateTrxPin";

const SecuritySettings = () => {

    return (
        <StyledAccount>

            <div className="mt-5">
            </div>
            <UpdatePassword />
            <UpdateTrxPin />
        </StyledAccount>
    );
};

export default SecuritySettings;