import { Button } from '../../styles';
import { Link } from 'react-router-dom';
import { ICON_PLUS } from '../../utils/icons';
import { EscrowList } from '../escrow/EscrowTransaction';
import { StyledEscrowSegment } from '../escrow/Escrow.styled';
import { useEscrowContext } from '../../context/escrow_context';


const EscrowSegment = () => {

    const {
        getAllEscrow: { allEscrow },
    } = useEscrowContext();

    const Escrow = allEscrow?.slice(0, 3);

    return (
        <StyledEscrowSegment>
            <Link to='/user/escrow/new'>
                <Button className='btn w-100 br-md initiate' dark >
                    {ICON_PLUS} Initiate Escrow
                </Button>
            </Link>
            <EscrowList data={Escrow} />

        </StyledEscrowSegment>
    );
};

export default EscrowSegment;

