import axios from 'axios';
import { base64ToObject, objectToBase64 } from '../encrypt';

const FetchApi = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'Authorization': `Bearer ${ objectToBase64(process.env.REACT_APP_APPTOKEN) }`
    }
});

FetchApi.interceptors.request.use(
    (request) => {
        let encPayload = objectToBase64(request.data);
        let newRequest = {
            ...request, data: encPayload
        };
        return newRequest;
    },
    (error) => {
        return Promise.reject(error);
    }
);

FetchApi.interceptors.response.use(
    (response) => {
        return base64ToObject(response.data);
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default FetchApi;
