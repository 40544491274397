import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { toast } from 'react-toastify';
import { Button, Form, Input, Label, NameInput, UserDetail } from '../../../styles';
import { ICON_EXCLAMATION, ICON_MARK_FILL } from '../../../utils/icons';
import AppLoader from "../../../utils/AppLoader";
import Api from '../../../api/axios';
import { useSendFundContext } from "../../../context/send_fund_context";
import { useUserContext } from '../../../context/user_context';
import { StyledSendFund } from '../Wallet.styled';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../utils/CustomSelect';




const AddBeneficiary = ({ settoggleAdd }) => {

  const [issending, setissending] = useState(false);
  const [formField, setformField] = useState({
    acct_no: '', name: ''
  });
  const [loadingName, setloadingName] = useState(false);
  const [selectUser, setselectUser] = useState('');
  const [selectedBank, setselectedBank] = useState('');
  const [disableSelectUser, setdisableSelectUser] = useState(false);

  const { usertoken } = useUserContext();

  const navigate = useNavigate();

  const {
    beneficiary: { getBen },
    banks: { allBanks },
  } = useSendFundContext();

  // Form Fields
  function handleChange(e) {
    const input = e.target.value;
    let name = e.target.name;
    let shortNo = '';
    if (name === 'acct_no') {
      shortNo = input.slice(0, 10);
    }
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
      'acct_no': shortNo
    }));
  };
  // Form Field

  // Get Account NAme
  function getAcctName() {
    if (
      selectedBank && formField?.acct_no?.length === 10
    ) {
      setloadingName(true);
      const data = {

        usertoken,
        recipientAccount: formField.acct_no,
        bankCode: selectedBank.value
      };
      Api.post(`/bankNameEnquiry`, data)
        .then((res) => {
          if (res.success === false) {
            setloadingName(false);
          } else {
            setloadingName(false);
            setselectUser(res.data);
            setformField(prevState => ({ ...prevState, name: res.data.name, SessionID: res.data.data.data.SessionID }));
          }
        })
        .catch((error) => {
          setloadingName(false);
        });
    } else {
      setloadingName(false);
    }
  }

  useEffect(() => {
    getAcctName();
  }, [selectedBank, formField.acct_no.length === 10]);
  // Get Account NAme


  function checkSendDetails() {
    if (!selectedBank) {
      toast.warn('Select Bank!');
      return false;
    }
    if (formField?.acct_no?.length !== 10) {
      toast.warn('Input Correct Account Number!');
      return false;
    }
    if (!selectUser) {
      toast.warn('Unable to verify Bank Account!');
      return false;
    }
    setdisableSelectUser(true);
    return true;
  }

  // Send Money to Bank
  function submitFn(e) {
    e.preventDefault();
    if (!checkSendDetails(e)) {
      return;
    }
    setissending(true);
    const data = {

      usertoken,
      name: formField.name,
      accountNumber: formField.acct_no,
      bankcode: selectedBank.value,
      bankname: selectedBank.label
    };
    Api.post(`/addBeneficiary-bank`, data)
      .then((res) => {
        if (res.success === false) {
          setissending(false);
          toast.warn(res.message);
        } else {
          setissending(false);
          toast.success(res.message);
          getBen();
          settoggleAdd(false);
        }
      })
      .catch((error) => {
        setissending(false);
      });
  }
  // Send Money to Bank

  return (

    <StyledSendFund>
      <>
        <AppLoader load={loadingName} />

        {/* Send Form */}
        <Form
          className="my-3"
        >
          <h5 className='text-center'>
            Add Beneficiary
          </h5>

          <div className="justify-content-center text-left">
            <div className="col-lg-12">
              <Label htmlFor="bank">
                Bank:
              </Label>
              <CustomSelect
                id="bank"
                options={allBanks}
                isSearchable={true}
                onChange={(e) => setselectedBank(e)}
                value={selectedBank}
                placeholder='Select Banks'
                required
                isDisabled={disableSelectUser}
              />
            </div>

            <div className="col-lg-12 ">
              <Label htmlFor="acct_no">
                Account Number:
              </Label>
              <NameInput
                type="number"
                name="acct_no"
                id="acct_no"
                placeholder="12345127890"
                onChange={handleChange}
                value={formField.acct_no}
                required
                disabled={disableSelectUser}
              />
              {formField.acct_no?.length === 10 &&
                <>
                  <br />
                  <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                    {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                    {selectUser ? selectUser?.name : 'No User Found'}
                  </UserDetail>
                </>
              }
              <div className="d-flex">
                <Button
                  pry
                  disabled={issending}
                  className="btn w-50"
                  onClick={(e) => submitFn(e)}
                >
                  {issending ? 'loading...' : "Add"}
                </Button>
                <Button
                  className="btn w-50 "
                  disabled={issending}
                  onClick={(e) => { e.preventDefault(); navigate(-1); }}
                >
                  Cancel
                </Button>
              </div>
            </div>

          </div>
        </Form>

        {/* Send Form */}

      </>
    </StyledSendFund>

  );
};

export default AddBeneficiary;
