import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_MARK, ICON_SAVE, ICON_SHARE_FILL } from "../../../utils/icons";
import { Button } from "../../../styles";
import { StyledSuccessModal } from "../SuccessTransfer";
import { useSendFundContext } from "../../../context/send_fund_context";
import { QRCodeSVG } from 'qrcode.react';
import { naira } from "../../../utils/data";
import { saveImage, shareImage, th } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logo_blue } from "../../../assets";


const SuccessQr = (props) => {

  const { formField, qrData, name } = props;

  let navigate = useNavigate();

  const {
    successRequestQr: { showSuccessRequestQr, handleCloseSuccessRequestQr, setShowSuccessRequestQr },
  } = useSendFundContext();

  function proceed() {
    navigate('/user/dashboard');
    setShowSuccessRequestQr(false);
  }

  return (
    <>
      <Modal
        show={showSuccessRequestQr}
        onHide={handleCloseSuccessRequestQr}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header>
        </Modal.Header>
        <Modal.Body>
          <StyledSuccessModal>
            <div className="pb-5 text-center ">
              <span className="success-icon">
                {ICON_MARK}
              </span>

              <h5 className="">
                {name} Successful
              </h5>
              <p className="fw-light">
                Your {name} of
                <span className="fw-bold"> {naira}{th(formField.amount)}</span>
                {name === 'Request' ? ' from' : name === 'Transfer' ? ' to' : ''}
                <span className="fw-bold"> {formField.r_name} ({formField.u_name}) </span>
                is successful. Share this QR Code with the recipient to confirm the payment.
              </p>

              <StyledQrImg
                className="fit-content"
                id="qrImg">
                <p className="light-text">
                  Scan for seamless Payment
                </p>
                <div className="my-3 light-bg p-4 br-md fit-content mx-auto">
                  <QRCodeSVG
                    value={qrData}
                    bgColor="var(--light-color)"
                    fgColor="var(--pry-color)"
                    level="L"
                    className="qr"
                  />
                </div>
                <div className="fit-content">
                  <img src={logo_blue} alt="Reni Logo" width='20%'
                    className="p-0 m-0"
                  />
                </div>
              </StyledQrImg>
            </div>

            <div className="d-flex">
              <Button
                pry
                className="btn w-50"
                onClick={() =>
                  shareImage("qrImg", "ReniTrust Payment QR Code")
                }
              >
                Share {ICON_SHARE_FILL}
              </Button>
              <Button
                pry
                className="btn w-50"
                onClick={() => saveImage("qrImg")}
              >
                Download {ICON_SAVE}
              </Button>
            </div>
            <Button
              className="btn w-100 border border-primary"
              onClick={() => proceed()}
            >
              Homepage
            </Button>
          </StyledSuccessModal>
        </Modal.Body>
      </Modal>


    </>
  );
};

export default SuccessQr;


export const StyledQrImg = styled.div`
  background-color: var(--pry-color) !important;
  text-align: center !important;
  padding: 1rem;

`

