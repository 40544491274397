import { useParams } from "react-router-dom";
import styled from "styled-components";
import React from 'react';
import AppWrapper from "../../layout/AppWrapper";
import EscrowDetailsBal from "./EscrowDetails/EscrowDetailsBal";
import EscrowDetailHistory from "./EscrowDetails/EscrowDetailHistory";
import EscrowDetailsAction from "./EscrowDetails/EscrowDetailsAction";
import { useEffect } from "react";
import AppLoader from "../../utils/AppLoader";
import BackButton from "../BackButton";
import { useEscrowContext } from "../../context/escrow_context";

const EscrowDetails = () => {

  const { id: escrowId } = useParams();

  const {
    escrow: { escrowDataLoading, getEscrowData },
  } = useEscrowContext();

  useEffect(() => {
    getEscrowData(escrowId);
  }, []);

  return (
    <AppWrapper
      pagename={'Escrow Details'}>
      <BackButton />
      <AppLoader
        load={escrowDataLoading}
      />
      <EscrowDetailsBal />

      <EscrowDetailHistory
      />

      <EscrowDetailsAction
      />
    </AppWrapper>
  );
};

export default EscrowDetails;

