import styled from "styled-components";
import { useWalletContext } from "../../context/wallet_context";
import { naira } from "../../utils/data";
import { ICON_PLANE } from "../../utils/icons";
import BalanaceCard from "../dashboard/BalanaceCard";
import { EscrowBalance } from "../dashboard/DashboardHeader";


const WalletHeader = () => {

    const {
        getAcctBal: { acctBal }
    } = useWalletContext();

    return (
        <StyledWalletHeader>
            <div className="row">
                <div className="col-md-6 mb-3">
                    <BalanaceCard />
                </div>
                <div className="col-md-6">
                    <div className="col-sm-12">
                        <WalletEcsrowBalance>
                            <div className="col-2 sec me-2 d-flex justify-content-center align-items-center br-sm">
                                <span className="paper-plane green-bg ">
                                    {ICON_PLANE}
                                </span>
                            </div>
                            <div className="col mt-2">
                                <p className='text-success'>
                                    Available Balance
                                </p>
                                <h4>
                                    {naira}{acctBal?.WithdrawableBalance_th || '0.00'}
                                </h4>
                            </div>
                        </WalletEcsrowBalance>
                    </div>
                    <div className="col-sm-12">
                        <WalletEcsrowBalance>
                            <div className="col-2 sec me-2 d-flex justify-content-center align-items-center br-sm">
                                <span className="paper-plane red-bg ">
                                    {ICON_PLANE}
                                </span>
                            </div>
                            <div className="col mt-2">
                                <p className='red-text'>
                                    Ledger Balance
                                </p>
                                <h4>
                                    {naira}{acctBal?.LedgerBalance_th || '0.00'}
                                </h4>
                            </div>
                        </WalletEcsrowBalance>
                    </div>
                </div>
            </div>
        </StyledWalletHeader>
    );
};

export default WalletHeader;

export const StyledWalletHeader = styled.div`

`;
export const WalletEcsrowBalance = styled(EscrowBalance)`
  p{
    font-size: 15px;
    margin:0;
  }
`;