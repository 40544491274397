import React, { useState } from 'react';
import styled from 'styled-components';
import { ICON_SUPPORT, ICON_GMAIL, ICON_TELEGRAM, ICON_WHATSAPP, ICON_X } from '../utils/icons';

const ContactSupport = () => {

    const [links, setlinks] = useState(false);
    const [showIcon, setshowIcon] = useState(true);

    function showLinks() {
        setlinks(true);
        setshowIcon(false);
    }
    function hideLinks() {
        setlinks(false);
        setshowIcon(true);
    }

    const support = [
        {
            url: "http://t.me/TheFireSwitchTech",
            icon: ICON_TELEGRAM,
            color: "",
            bg_color: "",
            name: "Telegram"
        },
        {
            url: "http://wa.me/2348037837313",
            icon: ICON_WHATSAPP,
            color: "light-text",
            bg_color: "green-bold",
            name: "Whatsapp"
        },
        {
            url: "mailto:hello@reni.tech",
            icon: ICON_GMAIL,
            color: "light-text",
            bg_color: "red-bold",
            name: "email"
        },
    ];

    return (
        <StyledSupport>
            {showIcon &&
                <div className="" title='Support'>
                    <button
                        onClick={(e) => showLinks()}
                    >
                        {ICON_SUPPORT}
                    </button>
                </div>
            }


            {links &&
                <>
                    <div className="support-link"
                        onClick={() => hideLinks()}
                    >
                        <div className="">
                            <div className=" py-3 px-2 me-3 mb-2 first"
                            >
                                {support.map(({ url, color, icon, name, bg_color }, i) =>
                                    <div className="cs-icon  pb-3"
                                        key={i}
                                        title={name}
                                    >
                                        <a href={url} target="_blank" className='h3'>
                                            {icon}
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className=""
                                title='Close'
                            >

                                <button
                                    onClick={(e) => hideLinks()}
                                    className='mb-3'
                                >
                                    {ICON_X}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </StyledSupport>
    );
};

export default ContactSupport;

const StyledSupport = styled.div`
    
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1004 !important;

 svg {
  color: var(--pry-color);
}
.first {
    z-index: 1005 !important;
}

.support-link {
  background-color: #deebf9a1;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
  position: fixed;
  right: 0;
  bottom: 0;
}

button {
    background-color: var(--pry-color);
    color: var(--sec-color);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.3rem;
    box-shadow: 3px 3px 4px #00000088;
    
    :focus {
     outline: none;
    }
    
    svg {
     color: var(--sec-color);
    }
}


@media screen and (max-width: 580px) {
    bottom: 6rem;

}


`;