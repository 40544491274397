import styled from "styled-components";
import { useEscrowContext } from "../../../context/escrow_context";
import { naira } from "../../../utils/data";
import { ICON_PEOPLE_ARROW } from "../../../utils/icons";


const EscrowDetailHistory = () => {

    const {
        escrow: { escrowData },
    } = useEscrowContext();

    const { amount_th, b_amount_th, b_charges_th, b_mail, b_phone, b_userData: { data: { fullname: b_name } = {} } = {}, delivery_cost_th, description, dispute, funded, price_unit_th, quantity, real_delivery_date, real_time, refunded, resolved, role, s_amount_th, s_charges_th, s_mail, s_phone, s_usertoken, s_userData: { data: { fullname: s_name } = {} } = {}, status, title, cancelled, token, usertoken } = escrowData;

    let role_name = "Escrow";
    if (role === 'buyer') {
        role_name = "Buyer";
    } else {
        role_name = "Seller";
    }

    return (
        <StyledEscrowHsitory>
            <div className="">
                <h4 className="mt-3 pry-text">
                    {ICON_PEOPLE_ARROW}  Invoice Review
                </h4>

                <div className="row">

                    <div className="col-md-6">
                        <div className="">
                            <small>
                                Status
                            </small>
                            <br />
                            <div className="d-flex gap-1">
                                {(cancelled === '0' && refunded === '0') &&
                                    <p className={`${ status === '1' ? 'badge-success' : 'badge-warning ' } badge `}>
                                        {status === '1' ? 'Successful' : 'Pending'}
                                    </p>
                                }
                                {(refunded === '0' && cancelled === '0') &&
                                    <p className={`${ funded === '1' ? 'badge-success' : 'badge-warning ' } badge `}>
                                        {funded === '1' ? 'Paid' : 'Unpaid'}
                                    </p>
                                }
                                {refunded === '1' &&
                                    <p className='badge badge-dark'>
                                        Refunded
                                    </p>
                                }
                                {(cancelled === '1' || resolved === '0' && status === '1') &&
                                    <p className='badge badge-danger'>
                                        Closed
                                    </p>
                                }
                                {(dispute === '1') &&
                                    <p className='badge badge-primary'>
                                        In Dispute
                                    </p>
                                }

                            </div>
                        </div>
                        <div className="">
                            <small>
                                Contract Title
                            </small>
                            <p>
                                {title || 'Title'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                This contract was initiated by:
                            </small>
                            <p>
                                {(s_usertoken === usertoken ? s_name : b_name) || 'Jane Doe'}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <small>
                                Date Initiated
                            </small>
                            <p>
                                {real_time || '01-Jan-1970'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Escrow ID
                            </small>
                            <p>
                                {token || 'abcdefg'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                {role_name}'s {role === 'buyer' ? 'Payable' : 'Receivable'}  Amount
                            </small>
                            <p className={role === 'buyer' ? 'red-text' : 'text-success'}>
                                {naira}
                                {role === 'buyer' ? b_amount_th : s_amount_th || '0.00'}
                            </p>
                        </div>
                    </div>
                </div>

                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <div className="">
                            <small>
                                Seller's Name
                            </small>
                            <p>
                                {s_name || 'Jane Doe'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Seller's Email
                            </small>
                            <p>
                                {s_mail || 'escrow@reni.com'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Seller's Phone Number
                            </small>
                            <p>
                                {s_phone || '0812345678'}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="">
                            <small>
                                Buyer's Name
                            </small>
                            <p>
                                {b_name || 'Jane Doe'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Buyer's Email
                            </small>
                            <p>
                                {b_mail || 'escrow@reni.com'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Buyer's Phone Number
                            </small>
                            <p>
                                {b_phone || '0812345678'}
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-6">
                        <div className="">
                            <small>
                                Description of Goods
                            </small>
                            <p>
                                {description || "Escrow Details"}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Your role
                            </small>
                            <p>
                                {role_name}{' '}
                                <span className="fw-light small fst-italic">
                                    (i.e You are the {role_name} in this escrow transaction)
                                </span>
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Number of Goods
                            </small>
                            <p>
                                {quantity || "0"}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Unit Price
                            </small>
                            <p>
                                {naira}{price_unit_th || '0.00'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Cost of Delivery
                            </small>
                            <p>
                                {naira}{delivery_cost_th || '0.00'}
                            </p>
                        </div>

                    </div>
                    <div className="col-md-6">

                        <div className="">
                            <small>
                                Amount
                            </small>
                            <p>
                                {naira}{amount_th || '0.00'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                {role_name}'s {role === 'buyer' ? 'Payable' : 'Receivable'}  Amount
                            </small>
                            <p>
                                {naira}
                                {role === 'buyer' ? b_amount_th : s_amount_th || '0.00'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                {role_name}'s Escrow Charges
                            </small>
                            <p>
                                {naira}
                                {role === 'buyer' ? b_charges_th : s_charges_th || '0.00'}
                            </p>
                        </div>
                        <div className="">
                            <small>
                                Expected Delivery Date
                            </small>
                            <p>
                                {real_delivery_date || '01-Jan-1970'}
                            </p>
                        </div>
                    </div>
                </div>
                <hr />
            </div>

        </StyledEscrowHsitory>
    );
};

export default EscrowDetailHistory;


const StyledEscrowHsitory = styled.div`

small {
    color: var(--grey-bold-color);
    line-height: 1 ;
}
p {
    line-height: 1;
    margin:0 0 5px 0;
    font-weight:600;
      word-wrap: break-word;
    }
    
          .badge {
            font-size:10px;
          }
`;