import styled from "styled-components";
import AppWrapper from "../../layout/AppWrapper";
import { ICON_ARROW_LEFT, ICON_COPY } from "../../utils/icons";
import { placeholder1 } from "../../assets";
import { Button } from "../../styles";
import { usePayLinkContext } from "../../context/paylink_context";
import { useNavigate, useParams } from "react-router-dom";
import AppLoader from "../../utils/AppLoader";
import { useEffect } from "react";
import { naira } from "../../utils/data";
import { CopyItem, th } from "../../utils/helpers";
import EditPayLink from "./EditPayLink";
import { useState } from "react";
import { toast } from "react-toastify";



const PayLinkDetails = () => {

      // Modal
      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);;
      // Modal

      const navigate = useNavigate();
      const { id: payLinkId } = useParams();
      const {
            payLink: { payLinkData, payLinkDataLoading, getPayLinkData },
      } = usePayLinkContext();

      const { name, description, amount, c_url, image, redirect_url, realTime } = payLinkData;

      useEffect(() => {
            getPayLinkData(payLinkId);
      }, []);

      const details = [
            { title: 'Payment URL', content: `pay.reni.tech/l/${ payLinkId }`, type: 'link' },
            { title: 'Payment link name', content: name },
            { title: 'Amount', content: `${ naira }${ th(amount) }` },
            { title: 'Description', content: description },
            { title: 'Date', content: realTime },
            { title: 'Redirect', content: redirect_url },
      ];
      // Copy Function 
      const [copied, setcopied] = useState(false);
      function copy() {
            toast.success('Copied to Clipboard');
            setcopied(!copied);
            setTimeout(() => {
                  setcopied(false);
            }, 3000);
      }
      // Copy Function

      return (

            <AppWrapper
                  pagename={"Payment Link"}
            >
                  <AppLoader
                        load={payLinkDataLoading}
                  />

                  <StyledPayLinkDetails>

                        <div className="col-md-10 col-lg-8 light-bg border py-4 px-3 br-md">
                              <section className="d-flex justify-content-between">
                                    <div className="col">
                                          <a onClick={() => navigate(-1)}>
                                                {ICON_ARROW_LEFT}
                                          </a>
                                    </div>
                                    <div className="col-8 text-center">
                                          <img src={image || placeholder1} alt="Placeholder" width='70%'
                                                className="br-md"
                                          />
                                    </div>
                                    <div className="col">
                                          <div className="badge badge-success text-uppercase">
                                                active
                                          </div>
                                    </div>
                              </section>
                              <section className="my-5">
                                    {details.map(({ title, content, type }, i) =>
                                          <div key={i}>
                                                <div className="row justify-content-between">
                                                      <div className="col-4 fw-bold">
                                                            {title}:
                                                      </div>
                                                      <div className="col text-end">
                                                            {content}
                                                            {type === 'link' &&
                                                                  <CopyItem value={`https://${ content }`}>
                                                                        <p
                                                                              className="m-0 pry-text small pointer"
                                                                              onClick={() => copy()}
                                                                        >
                                                                              {ICON_COPY}
                                                                              {copied ? ' COPIED' : ' COPY'}

                                                                        </p>
                                                                  </CopyItem>
                                                            }
                                                      </div>
                                                </div>
                                                <hr />
                                          </div>
                                    )}
                              </section>
                              <section>
                                    <div className="my-1">
                                          <Button pry className="btn w-100"
                                                onClick={() => handleShow()}
                                          >
                                                Edit payment link
                                          </Button>
                                    </div>
                                    <div className="my-1">
                                          <Button className="btn w-100 red-bg light-text ">
                                                Delete payment link
                                          </Button>
                                    </div>
                              </section>
                        </div>

                  </StyledPayLinkDetails>

                  <EditPayLink
                        onShow={show}
                        onHide={handleClose}
                        {...payLinkData}
                  />

            </AppWrapper>
      );
};

export default PayLinkDetails;


const StyledPayLinkDetails = styled.div`
      

`;