import styled from 'styled-components';
import { StyledBalanaceCard } from '../../dashboard/BalanaceCard';
import { naira } from '../../../utils/data';
import { ICON_PEOPLE_ARROW } from '../../../utils/icons';
import { escrow_bg, logo_white, seller_bg } from '../../../assets';
import { useEscrowContext } from '../../../context/escrow_context';



export const EscrowBuyerCard = () => {
    const {
        escrow: { escrowData },
    } = useEscrowContext();

    const { amount_th, b_userData: { data: { fullname: b_name } = {} } = {}, funded, refunded, resolved, cancelled } = escrowData;

    return (
        <>
            <StyledBuyerCard>
                <div className="row justify-content-around">
                    <div className="col">
                        <p>
                            Buyer's Name
                        </p>
                        <h6 className='text-uppercase'>
                            {b_name || 'Jane Doe'}
                        </h6>
                    </div>
                    <div className="col m-0 p-0"
                        title="RENI ESCROW">
                        <div className="text-end">
                            <img src={logo_white} alt="reni logo" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around">
                    <div className="col">
                        {(refunded !== '1') &&
                            <>
                                <p>
                                    Amount Payable by Buyer
                                </p>
                                <h4>
                                    {naira}
                                    {cancelled === '1' ?
                                        '0.00' :
                                        (funded === '0') ?
                                            amount_th : '0.00'
                                    }

                                </h4>
                            </>
                        }
                        {(funded === '1' && resolved === '0' && refunded === '1') &&
                            <>
                                <p>
                                    Amount Refunded to the Buyer
                                </p>
                                <h4>
                                    {naira}
                                    {(funded === '1' && resolved === '0' && refunded === '1') ?
                                        amount_th : '0.00'
                                    }
                                </h4>

                            </>
                        }
                    </div>
                    <div className="col text-end"
                    >
                        {ICON_PEOPLE_ARROW}
                    </div>
                </div>
            </StyledBuyerCard>
        </>
    );
};


const StyledBuyerCard = styled(StyledBalanaceCard)`

`;

export const EscrowEscrowCard = () => {
    const {
        escrow: { escrowData },
    } = useEscrowContext();

    const { amount_th, funded, refunded, resolved, token } = escrowData;

    return (
        <>
            <StyledEscrowCard>
                <div className="row justify-content-around">
                    <div className="col">
                        <p>
                            {token || 'abcdefg'} Escrow
                        </p>
                        <h6 className='text-uppercase'>
                            RENI ESCROW
                        </h6>
                    </div>
                    <div className="col m-0 p-0"
                        title="RENI ESCROW">
                        <div className="text-end">
                            <img src={logo_white} alt="reni logo" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around">
                    <div className="col">
                        <p>
                            Amount in Escrow
                        </p>
                        <h4>
                            {naira}
                            {(funded === '1' && resolved === '0' && refunded === '0') ?
                                amount_th : '0.00'
                            }
                        </h4>
                    </div>
                    <div className="col text-end"
                    >
                        {ICON_PEOPLE_ARROW}
                    </div>
                </div>
            </StyledEscrowCard>
        </>
    );
};


const StyledEscrowCard = styled(StyledBalanaceCard)`
  background: url(${ escrow_bg }) no-repeat ;
  background-size: cover;


`;

export const EscrowSellerCard = () => {
    const {
        escrow: { escrowData },
    } = useEscrowContext();

    const { amount_th, s_userData: { data: { fullname: s_name } = {} } = {}, funded, resolved } = escrowData;

    return (
        <>
            <StyledSellerCard>
                <div className="row justify-content-around">
                    <div className="col">
                        <p>
                            Seller's Name
                        </p>
                        <h6 className='text-uppercase'>
                            {s_name || 'Jane Doe'}
                        </h6>
                    </div>
                    <div className="col m-0 p-0"
                        title="RENI ESCROW">
                        <div className="text-end">
                            <img src={logo_white} alt="reni logo" />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-around">
                    <div className="col">
                        <p>
                            Amount Received by the Seller
                        </p>
                        <h4>
                            {naira}
                            {(funded === '1' && resolved === '1') ?
                                amount_th : '0.00'
                            }
                        </h4>
                    </div>
                    <div className="col text-end"
                    >
                        {ICON_PEOPLE_ARROW}
                    </div>
                </div>
            </StyledSellerCard>
        </>
    );
};


const StyledSellerCard = styled(StyledBalanaceCard)`
  background: url(${ seller_bg }) no-repeat ;
  background-size: cover;

`;