import { bank, send, username, tier1, tier2, tier3 } from "../assets";
import { ICON_AT, ICON_BANK, ICON_CARD, ICON_HASH, ICON_PEOPLE, ICON_QR, ICON_DASHBOARD, ICON_GIFT_FILL, ICON_MONEY_BILL, ICON_PEOPLE_ARROW, ICON_PEOPLE_FILL, ICON_WALLET, ICON_RECEIVE_MONEY, ICON_CHAT, ICON_USER, ICON_FLASH, ICON_LINK, ICON_SETTING, } from "../utils/icons";

// Homepage

export const features = [
    {
        title: 'Escrow',
        content: 'Escrow is a great way to protect yourself when makingonline payments. It is also a great way to build trust with sellers. If you are looking for a secure way to make payments online, escrow is the best option.',
        icon: ICON_PEOPLE_ARROW,
        link: '/user/dashboard',
        to: 'To Dashboard',
        color: 'primary'
    },
    {
        title: 'Wallet',
        content: 'Your wallet is a secure and convenient way to store your money. With your wallet, you can make payments online and in stores, send and receive money from friends and family, track your spending set up alerts to stay on top of your finances',
        icon: ICON_WALLET,
        link: '/user/wallet',
        to: 'To Wallet',
        color: 'blue'
    },
    {
        title: 'Pay Bills',
        content: 'Pay your bills on time and avoid late fees with our easy-to-use bills payment feature. With just a few taps, you can pay your utility bills, credit card bills, and more.',
        icon: ICON_MONEY_BILL,
        link: '/user/bills',
        to: 'Pay Bills',
        color: 'green'
    },
    {
        title: 'Resolve disputes',
        content: 'The resolve dispute feature typically allows users to submit a request for help, provide information about the issue, and track the status of their dispute.',
        icon: ICON_CHAT,
        link: '/user/dispute',
        to: 'To Dispute',
        color: 'pink'
    },
];

export const moreAbout = [
    {
        title: 'Fund your wallet with ease',
        content: 'If you are looking for a convenient, secure, and flexible way to make payments, consider funding your wallet.'
    },
    {
        title: 'Save your money',
        content: 'Start saving money today with our new app. It\'s the easiest way to save money and reach your financial goals.'
    },
    {
        title: 'Pay in seconds',
        content: 'Pay bills, send money to friends and family, or make purchases online or in-app.Also keep track of your spendings'
    },
];

export const about = [
    {
        title: 'Excellent Security',
        content: 'Escrow accounts provide a safe and secure way to store funds during a transaction. The funds are held by a third-party, and the seller cannot access them until the buyer has completed the transaction. This helps to protect both buyers and sellers from fraud and scams.'
    },
    {
        title: 'Assets Custody',
        content: 'Buyers and sellers do not have to meet in person or exchange bank information. The transaction can be completed entirely online, which is convenient for both parties.'
    },
    {
        title: 'Regulatory Compliance',
        content: 'Renitrust is PCI compliant, which means that your payments are protected by the highest security standards.'
    },
    {
        title: 'Team of Professionals',
        content: 'Renitrust is backed by a team of experienced professionals with a proven track record in the financial technology industry'
    },
];

// Homepage

// navLinks

export const navLink = [
    { title: "Dashboard", to: "/user/dashboard", icon: ICON_DASHBOARD },
    { title: "Escrow", to: "/user/escrow", icon: ICON_PEOPLE_ARROW },
    { title: "Wallet", to: "/user/wallet", icon: ICON_WALLET },
    { title: "Pay Link", to: "/user/paylink", icon: ICON_LINK },
    { title: "Bills", to: "/user/bills", icon: ICON_MONEY_BILL },
    { title: "Dispute", to: "/user/dispute", icon: ICON_PEOPLE_FILL },
    { title: "Referral", to: "/user/referral", icon: ICON_GIFT_FILL },
    { title: "Settings", to: "/user/settings", icon: ICON_SETTING },
];

export let bottomNavLink = [
    { title: "Home", to: "/user/dashboard", icon: ICON_DASHBOARD },
    { title: "Wallet", to: "/user/wallet", icon: ICON_WALLET },
    // { title: "", to: "", icon: '' },
    // { title: "", to: "", icon: '' },
    { title: "quick", to: "", icon: '' },
    { title: "Pay Bills", to: "/user/bills", icon: ICON_MONEY_BILL },
    { title: "Profile", to: "/user/profile", icon: ICON_USER },
];

export let bottomNavQuickLinks = [
    { title: "Add Money", to: "/user/wallet/fund", icon: ICON_CARD },
    { title: "Send Money", to: "/user/wallet/send", icon: ICON_BANK },
    { title: "Request Money", to: "/user/wallet/request", icon: ICON_RECEIVE_MONEY },
    { title: "Buy Airtime", to: "/user/bills/airtime", icon: ICON_MONEY_BILL },
    { title: "Buy Data", to: "/user/bills/data", icon: ICON_FLASH },
    { title: "New Escrow", to: "/user/escrow/new", icon: ICON_PEOPLE_ARROW },
    { title: "New Pay Link", to: "/user/paylink/new", icon: ICON_LINK },
    { title: "Scan QR", to: "/user/scan", icon: ICON_QR },
];

// navLinks


export const walletLinks = [
    {
        date: '2022-07-18 08:39PM',
        id: '1',
        title: 'Add Money',
        bgColor: '#cc00ff11',
        img: username,
        link: 'fund',
    },
    {
        date: '2022-07-18 08:39PM',
        id: '3',
        title: 'Send Money ',
        bgColor: '#3bd00011',
        img: send,
        link: 'send',
    },
    {
        date: '2022-07-18 08:39PM',
        id: '2',
        title: 'Request Money',
        bgColor: '#0397ae11',
        img: bank,
        link: 'request',
    }
];

export const bill = [
    {
        title: 'Airtime ',
        route: 'airtime',
        bgColor: '#cc00ff11',
        img: username,
    },
    {
        title: 'Internet Data',
        route: 'data',
        bgColor: '#ff000011',
        img: username,
    },
    {
        title: ' Cable TV',
        route: 'tv',
        bgColor: '#3bd00011',
        img: send,
    },
    {
        title: ' Electricity',
        route: 'electricity',
        bgColor: '#0397ae11',
        img: bank,
    },
];

export const recents = [
    {
        id: '1',
        name: 'Akinyemi Bank',
        account: '123456789',
    },
    {
        id: '3',
        name: 'Ayodele Bank',
        account: '123456789',
    },
    {
        id: '2',
        name: 'Praise Bank',
        account: '123456789',
    },
    {
        id: '6',
        name: 'Praise Bank',
        account: '123456789',
    }
];

export const addMoneyLinks = [
    {
        link: 'username',
        name: 'Share Reni Username',
        color: 'pry-text',
        icon: ICON_AT
    },
    {
        link: 'bank',
        name: 'Bank Transfer',
        color: 'text-success',
        icon: ICON_BANK
    },
    {
        link: 'qr',
        name: 'Scan my QR Code',
        color: 'yellow-text',
        icon: ICON_QR
    },
    {
        link: 'card',
        name: 'Fund with Card',
        color: 'red-text',
        icon: ICON_CARD
    },
    {
        link: 'ussd',
        name: 'Fund with USSD',
        color: 'dark-text',
        icon: ICON_HASH
    },
];

export const sendMoneyLinks = [
    {
        link: 'username',
        name: 'Send to Username',
        color: 'pry-text',
        icon: ICON_AT
    },
    {
        link: 'bank',
        name: 'Send to Bank',
        color: 'text-success',
        icon: ICON_BANK
    },
    {
        link: 'beneficiary',
        name: 'Send to Beneficiaries',
        color: 'red-text',
        icon: ICON_PEOPLE
    },
    {
        link: 'qr',
        name: 'Send using QR Code',
        color: 'yellow-text',
        icon: ICON_QR
    },
];

export const requestMoneyLinks = [
    {
        link: 'username',
        name: 'Request from ReniTrust User',
        color: 'pry-text',
        icon: ICON_RECEIVE_MONEY
    },
    {
        link: '/user/paylink',
        name: 'Payment Link',
        color: 'text-success',
        icon: ICON_BANK
    },
    // {
    //     link: 'beneficiary',
    //     name: 'Send to Beneficiaries',
    //     color: 'red-text',
    //     icon: ICON_PEOPLE
    // },
    {
        link: 'qr',
        name: 'Generate QR Code',
        color: 'yellow-text',
        icon: ICON_QR
    },
];

export const nextOfKin = [
    { title: 'father' },
    { title: 'mother' },
    { title: 'brother' },
    { title: 'sister' },
    { title: 'husband' },
    { title: 'wife' },
    { title: 'son' },
    { title: 'daughter' },
    { title: 'friend' },
    { title: 'others' },
];

export const idType = [
    { value: 'IntPass ', option: 'International Passport' },
    { value: 'NIN ', option: 'National Indentity Number' },
    { value: 'DL ', option: 'Driver\'s License ' },
    { value: 'VC ', option: 'Voter\'s Card ' },
];

export const limit = [
    {
        logo: tier1, name: 'Tier 1', min: '50,000', max: '300,000', tier: true,
    },
    {
        logo: tier2, name: 'Tier 2', min: '200,000', max: '500,000', tier: false,
    },
    {
        logo: tier3, name: 'Tier 3', min: '5,000,000', max: 'Unlimited', tier: false,
    },

];


export const naira = '₦';

export const PASSWORD_REGEX = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{};':"\\|,.<>/?`~\[\]])[a-zA-Z\d!@#$%^&*()\-=_+{};':"\\|,.<>/?`~\[\]]{7,}$/;

export const EMAIL_REGEX = /^(?![_.-])((?![_.-][_.-])[a-zA-Z\d_.-]){0,63}[a-zA-Z\d]@((?!-)((?!--)[a-zA-Z\d-]){0,63}[a-zA-Z\d]\.){1,2}([a-zA-Z]{2,14}\.)?[a-zA-Z]{2,14}$/;

export const CARD_REGEX = /^\d{16}$/;

export const CVV_REGEX = /^\d{3,4}$/;

export const MONTH_REGEX = /^(0[1-9]|1[0-2])$/;

export const YEAR_REGEX = /^([0-9]{2})$/;

export const URL_REGEX = /\b(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*\b/;

export const PIN_REGEX = /^\d{4}$/;
// 62417411089