import React from 'react';
import BackButton from '../components/BackButton';

const SendLayout = ({ children, title }) => {
    return (
        <>
            <BackButton />
            <h4 className="text-center mb-1">
                {title}
            </h4>
            <div className="row">
                <div className="col-md-8 col-lg-6 mb-3 mx-auto shadow py-4">
                    {children}
                </div>
            </div>
        </>
    );
};

export default SendLayout;