import styled from "styled-components";


export const StyledReferral = styled.div`
text-align:center;

img {
    margin-top: 3rem;
}

button {
  height: 50px !important;
  margin: 8px 0 !important;
  margin-bottom: 1.5rem !important;
  }

label {
font-weight: 600;
font-size: 16.5667px;
color: #000000;

}
`;