import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { BottomLinks, StyledBottomNav } from "./Nav.styled";
import { ICON_FLASH, ICON_MOON, ICON_SUN, ICON_X, } from "../../utils/icons";
import { bottomNavLink, bottomNavQuickLinks } from "../../utils/data";
import { useThemeContext } from "../../context/theme_context";
import { ThemeChange } from "../../utils/ThemeToggle";


const BottomNav = () => {

    const [showLinks, setShowLinks] = useState(false);

    const {
        darkMode: { darkTheme }
    } = useThemeContext();

    return (
        <>
            <StyledBottomNav className="">
                <nav
                    className="navbar light-bg navbar-expand-sm navbar-light fixed-bottom">
                    <div className="container justify-content-between" id="logo">
                        {bottomNavLink.map(({ title, to, icon }, i) => {
                            return (

                                <li className="list-unstyled text-center py-1" key={i}>
                                    {title === 'quick' ?
                                        <div className="bottom-button ">
                                            <h4
                                                onClick={() => setShowLinks(!showLinks)} className="pointer m-0 p-0 "
                                            >
                                                <div className="circle mx-auto">
                                                    {showLinks ?
                                                        ICON_X :
                                                        ICON_FLASH
                                                    }
                                                </div>
                                            </h4>
                                            <div className="small-text pry-text"> Quick Links</div>
                                        </div>
                                        :
                                        <NavLink tag={Link} activeClassName="active" to={to}>
                                            <h4 className="">
                                                {icon}
                                            </h4>
                                            <div className="small-text"> {title}</div>
                                        </NavLink>
                                    }
                                </li>
                            );
                        })}

                    </div>
             
                    <BottomLinks className={`${ showLinks ? 'd-block' : 'd-none' } `}>
                        <div className="row justify-content-center">
                            {bottomNavQuickLinks?.map(({ title, to, icon }, i) => {
                                return (
                                    <div className="nav-item col-4 col-sm-4 " key={i}>
                                        <NavLink to={to}>
                                            <div className="circle mx-auto">
                                                {icon}
                                            </div>
                                        </NavLink>
                                        <div className="small-text light-text"> {title}</div>
                                    </div>
                                );
                            })}
                            <div className="nav-item col-4 col-sm-4 ">
                                <ThemeChange >
                                    <a>
                                        <div className="circle mx-auto">
                                            {darkTheme === "dark" ? ICON_SUN : ICON_MOON}
                                        </div>
                                    </a>
                                    <div className="small-text light-text">
                                        {darkTheme === "dark" ? 'Light Mode' : 'Dark Mode'}
                                    </div>
                                </ThemeChange>
                            </div>
                        </div>
                    </BottomLinks>
                </nav>
            </StyledBottomNav >
        </>
    );
};

export default BottomNav;
