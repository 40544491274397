import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'jquery';
import "react-toastify/dist/ReactToastify.css";
import './index.css';
import './styles/app.css';
import App from './app/app';
import { ToastContainer, Flip } from 'react-toastify';
import { SendFundProvider } from './context/send_fund_context';
import { UserProvider } from './context/user_context';
import { WalletProvider } from './context/wallet_context';
import { EscrowProvider } from './context/escrow_context';
import AppLoader from './utils/AppLoader';
import ScrollToTop from './utils/ScrollToTop';
import { DisputeProvider } from './context/dispute_context';
import { ProfileProvider } from './context/profile_context';
import { BillsProvider } from './context/bill_context';
import { ThemeProvider } from './context/theme_context';
import { QrProvider } from './context/qr_context';
import { PayLinkProvider } from './context/paylink_context';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <UserProvider>
          <WalletProvider>
            <SendFundProvider>
              <PayLinkProvider>
                <BillsProvider>
                  <EscrowProvider>
                    <DisputeProvider>
                      <ProfileProvider>
                        <QrProvider>
                          <ScrollToTop />
                          <App />
                          <ToastContainer
                            hideProgressBar={true}
                            autoClose={1500}
                            theme="colored"
                            newestOnTop
                            transition={Flip}
                          />
                          <AppLoader />
                        </QrProvider>
                      </ProfileProvider>
                    </DisputeProvider>
                  </EscrowProvider>
                </BillsProvider>
              </PayLinkProvider>
            </SendFundProvider>
          </WalletProvider>
        </UserProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);