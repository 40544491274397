import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useWalletContext } from "../../../context/wallet_context";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Label } from "../../../styles";
import { CopyItem, shareItem } from "../../../utils/helpers";
import { ICON_BANK, ICON_COPY, ICON_SHARE_FILL } from "../../../utils/icons";
import { useUserContext } from "../../../context/user_context";
import { Alert } from "react-bootstrap";


const BankTransfer = () => {

  const {
    getAcctNo: { acctNo },
  } = useWalletContext();

  const {
    userData,
  } = useUserContext();

  // Copy Function 
  const [copied, setcopied] = useState(false);
  function copy() {
    toast.success('Copied to Clipboard');
    setcopied(!copied);
    setTimeout(() => {
      setcopied(false);
    }, 5000);
  }
  // Copy Function

  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledBankTransfer>
        <SendLayout
          title={'Bank Transfer'}>
          <div className="text-center mb-5">
            <h1 className="text-success display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_BANK}
            </h1>
            <p>
              Use the details below to send money to your ReniTrust account from any bank's app or through internet banking.
            </p>
          </div>

          {!acctNo?.nuban &&
            <div className="my-2">
              <Alert variant="warning" dismissible
                className='py-2'
              >
                Sorry, you do not have an account yet, add your bvn to get an account
              </Alert>
            </div>
          }

          <div className="my-2">
            <Label>
              Bank Name
            </Label>
            <div className="light-bold-bg br-sm py-3 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0 fw-bold">
                  {acctNo?.bankname || 'ReniTrust'}
                </h6>
              </div>
            </div>
          </div>

          <div className="my-2">
            <Label>
              Account Number
            </Label>
            <div className="light-bold-bg br-sm py-3 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0 fw-bold">
                  {acctNo?.nuban || '********'}
                </h6>
                <CopyItem value={acctNo?.nuban}>
                  <p
                    className="m-0 pry-text small pointer"
                    onClick={() => copy()}
                  >
                    {ICON_COPY}
                    {copied ? ' COPIED' : ' COPY'}

                  </p>
                </CopyItem>
              </div>
            </div>
          </div>

          <div className="my-2">
            <Label>
              Account Name
            </Label>
            <div className="light-bold-bg br-sm py-3 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="m-0 fw-bold">
                  {acctNo?.rawdata?.data?.customer_details?.name || userData?.fullname}
                </h6>

              </div>
            </div>
          </div>


          <div className="mt-5">
            <Button pry
              className="btn w-100"
              onClick={() => shareItem({
                title: 'Share Reni Account Details',
                text: `Bank Account: ${ acctNo?.bankname } 
                Account Number: ${ acctNo?.nuban } 
                Bank Name: ${ acctNo?.rawdata?.data.customer_details?.name }`,
                url: `https://renitrust.com`
              })}
            >
              {ICON_SHARE_FILL} SHARE
            </Button>
          </div>
        </SendLayout>
      </StyledBankTransfer>
    </AppWrapper >
  );
};

export default BankTransfer;

export const StyledBankTransfer = styled.div`
label{
  font-size: 15px;
}
`;
