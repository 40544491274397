import { useState } from "react";
import { toast } from "react-toastify";
import Api from "../../api/axios";
import { useUserContext } from "../../context/user_context";
import { Button, Form, Input, Label, Select } from "../../styles";
import { ICON_PEN, ICON_X } from "../../utils/icons";
import { StyledAccount } from "../Profile/Profile.styled";
import { convertPhoto } from "../../utils/helpers";
import { placeholder } from "../../assets";
import styled from "styled-components";
import { idType } from "../../utils/data";


const UpdateId = () => {

    const { usertoken, getUserData } = useUserContext();

    const [formField, setformField] = useState({ type: '', number: '', photo: '' });
    const [sending, setsending] = useState(false);

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields

    const [idEdit, setidEdit] = useState(true);
    const editFields = () => setidEdit(!idEdit);


    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (
            formField?.type &&
            formField?.number &&
            formField?.photo
        ) {
            setsending(true);
            const data = {
                usertoken,
                type: formField.type,
                number: formField.number,
                photo: formField.photo,

            };
            Api.post(`/user.kyc.UpdateID`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        toast.success(res.message);
                        setsending(false);
                        getUserData();
                        editFields();
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }
    }

    // Submit Function


    return (
        <StyledAccount>
            <Styledid>

                <div className="mt-5">

                    <div className="col-md-7 col-lg-7 ">
                        <div className="d-flex">
                            <h5 className="grey-bold-text">
                                ID Information
                            </h5>
                            <span className="pry-text ms-2"
                                onClick={() => editFields()}
                            >
                                {ICON_PEN}
                            </span>
                        </div>
                        <p className="small grey-bold-text">
                            Upload a clear photo of your ID document. The ID type should align with what you choose below.
                        </p>
                    </div>
                    <Form onSubmit={(e) => submitFn(e)}>

                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    ID Type:
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 ">
                                <Select
                                    name="type"
                                    className='mb-0'
                                    onChange={handleChange}
                                    value={formField?.type}
                                    required
                                    disabled={idEdit}
                                >
                                    <option value="">--Select ID Type--</option>
                                    {
                                        idType?.map(({ value, option }, i) =>
                                            <option value={value} key={i}>{option} </option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>

                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    ID Number:
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 ">
                                <Input
                                    type='text'
                                    name="number"
                                    className='mb-0'
                                    placeholder="ID Number"
                                    onChange={handleChange}
                                    value={formField?.number}
                                    required
                                    disabled={idEdit}
                                />
                            </div>
                        </div>

                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    Proof of ID (front):
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7">
                                <div class="upload">
                                    <div className="w-100" >
                                        {formField?.photo ?
                                            <div className="cover-container">
                                                <img src={formField?.photo}
                                                    className="cover"
                                                    alt="cover " />
                                            </div>
                                            :
                                            <input
                                                accept="image/*"
                                                type="file"
                                                name='photo'
                                                onChange={(e) => convertPhoto(e, setformField)}
                                                className="w-100 h-100"
                                                disabled={idEdit}
                                            />
                                        }
                                        {formField?.photo &&
                                            <div className="cancel"
                                                onClick={() => setformField(prev => ({ ...prev, photo: '' }))}
                                            >
                                                {ICON_X}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {!idEdit &&
                            <div className="row justify-content-start mt-4">
                                <div className="col-md-5 col-lg-2">

                                </div>
                                <div className="col-md-7 col-lg-7 ">
                                    <Button
                                        pry
                                        type="submit"
                                        disabled={sending}
                                        className="btn m-0 w-100"  >
                                        {sending ? 'loading...' : "Submit"}
                                    </Button>
                                </div>
                            </div>
                        }
                    </Form>

                </div>
            </Styledid>
        </StyledAccount>
    );
};

export default UpdateId;

const Styledid = styled.div`
    

     .upload {
        position: relative;

       input[type="file"] {
          position: relative ;
          /* top: 0;
          left: 0; */
          opacity: 1 !important;
          cursor: pointer;
        }
        
        img {
            width: 100%;
                border-radius: 0 ;

        }
    
        .cancel {
          position:absolute;
          top:0;
          right: 0;
    
          svg {
                font-size: 25px;
                background-color: #ffffff90;
                border-radius: 50%;
                margin:.3rem;
          }
    
        }
  
        .cover-container{
              position: relative;
              width:100%;
              height:300px;
              img.cover {
                    border-radius: 1rem;
                    width: 100%;
                    height:100%;
                    margin:auto;
                    object-fit:cover;
              }
        }
      }

`;