import styled from "styled-components";
import { useEscrowContext } from "../../../context/escrow_context";
import { Button } from "../../../styles";
import { ICON_MARK_STYLE, ICON_X_STYLE } from "../../../utils/icons";



const EscrowDetailsAction = (props) => {

    const {
        escrow: { escrowData },
        escrowAction: { fundEscrow, cancelEscrow, reverseEscrow, resolveEscrow, requestRefundEscrow, openDisputeEscrow },
    } = useEscrowContext();

    const { funded, refunded, resolved, status, cancelled, dispute, request_refund, role, token } = escrowData;

    // Action Button
    let CLOSE_ESCROW = <Button className="btn btn-danger col"
        onClick={() => cancelEscrow(token)}
    > Close Ecsrow </Button>;

    let REFUND_PAYMENT = <Button className="btn btn-dark col"
        onClick={() => reverseEscrow(token)}
    > Refund Payment </Button>;

    let FUND_ESCROW = <Button pry className="btn col"
        onClick={() => fundEscrow(token)}
    > Fund Escrow </Button>;

    let CONFIRM_ESCROW = <Button className="btn btn-success col"
        onClick={() => resolveEscrow(token)}
    > Confirm Escrow </Button>;

    let REQUEST_REFUND = <Button className="btn btn-dark col"
        onClick={() => requestRefundEscrow(token)}
    > Request Refund </Button>;

    let RAISE_DISPUTE = <Button className="btn col btn-warning"
        onClick={() => openDisputeEscrow(token)}
    > Raise Dispute </Button>;
    // Action Button

    // Action Notifications
    let NOT_CREATED_BUYER = <div className="alert alert-primary col-12">
        Escrow has been successfuly created, you can proceed to fund the escrow transaction.
    </div>;
    let NOT_FUNDED_BUYER = <div className="alert alert-primary col-12">
        You have funded the escrow transaction, awaiting a delivery from the seller.   </div>;

    let NOT_R_REFUND_BUYER = <div className="alert alert-primary col-12">
        You have requested for refund, kindly wait for the seller to refund the payment.    </div>;



    let NOT_CREATED_SELLER = <div className="alert alert-primary col-12">
        Escrow has been successfully created, awaiting the buyer's payment.    </div>;

    let NOT_FUNDED_SELLER = <div className="alert alert-primary col-12">
        The buyer has funded the escrow transaction. You can proceed with the delivery.   </div>;

    let NOT_R_REFUND_SELLER = <div className="alert alert-primary col-12">
        A refund has been requested by the buyer, kindly refund the payment. If you have delivered the goods, notify the buyer for confirmation. </div>;
    // Action Notifications


    return (
        <StyledAction>
            <div className="mt-4 justify-content-center row gap-2 col-12 mx-auto">
                {/* Seller Actions */}

                {(role === 'seller' && status === '0') &&
                    <>
                        {funded === '0' ?
                            <>
                                {NOT_CREATED_SELLER}

                                {CLOSE_ESCROW}
                            </> :
                            <>
                                {dispute === '0' &&
                                    <>
                                        {(funded === '1' && request_refund === '0') &&
                                            NOT_FUNDED_SELLER
                                        }
                                        {request_refund === '1' &&
                                            NOT_R_REFUND_SELLER
                                        }
                                        {REFUND_PAYMENT}
                                        {RAISE_DISPUTE}
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {/* Seller Actions */}

                {/* Buyer Actions */}
                {(role === 'buyer' && status === '0') &&
                    <>
                        {funded === '0' ?
                            <>
                                {NOT_CREATED_BUYER}

                                {FUND_ESCROW}

                                {CLOSE_ESCROW}
                            </> :
                            <>
                                {dispute === '0' &&
                                    <>
                                        {(funded === '1' && request_refund === '0') &&
                                            NOT_FUNDED_BUYER
                                        }
                                        {request_refund === '1' &&
                                            NOT_R_REFUND_BUYER
                                        }

                                        {CONFIRM_ESCROW}

                                        {request_refund === '0' &&
                                            REQUEST_REFUND
                                        }
                                        {request_refund === '1' &&
                                            RAISE_DISPUTE
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {/* Buyer Actions */}



                {/* Escrow Completed */}
                {(status === '1' && resolved === '1') && <>
                    <div className="text-center text-success fit-content shadow px-3 py-2 br-md mx-auto">
                        <h3>
                            {ICON_MARK_STYLE}
                        </h3>
                        <h5 className="fw-light m-0">
                            Escrow Confirmed
                        </h5>
                        <small>
                            Delivery has been confirmed by the buyer and this escrow transaction is successful
                        </small>
                    </div>
                </>}
                {/* Escrow Completed */}

                {/* Escrow In Dispute */}
                {(status === '0' && dispute === '1') && <>
                    <div className="text-center pry-text fit-content shadow px-3 py-2 br-md mx-auto">
                        <h3>
                            {ICON_MARK_STYLE}
                        </h3>
                        <h5 className="fw-light m-0">
                            Escrow in Dispute
                        </h5>
                        <small>
                            This escrow transaction is on hold pending a dispute resolution between the buyer and the seller
                        </small>
                    </div>
                </>}
                {/* Escrow In Dispute */}

                {/* Escrow Refunded */}
                {(status === '1' && refunded === '1') && <>
                    <div className="text-center dark-text fit-content shadow px-3 py-2 br-md mx-auto">
                        <h3>
                            {ICON_MARK_STYLE}
                        </h3>
                        <h5 className="fw-light m-0">
                            Escrow Refunded
                        </h5>
                        <small>
                            The Seller has refunded the payment to the buyer; this escrow transaction has been closed.
                        </small>
                    </div>
                </>}
                {/* Escrow Refunded */}

                {/* Escrow Cancelled */}
                {(status === '1' && cancelled === '1') && <>
                    <div className="text-center red-text fit-content shadow px-3 py-2 br-md mx-auto">
                        <h3>
                            {ICON_X_STYLE}
                        </h3>
                        <h5 className="fw-light m-0">
                            Escrow Closed
                        </h5>
                        <small>
                            This escrow transaction has been closed
                        </small>
                    </div>
                </>}
                {/* Escrow Cancelled */}


            </div>
        </StyledAction>
    );
};

export default EscrowDetailsAction;

const StyledAction = styled.div`

button {
    margin: 0 0.2rem 0.8rem 0.2rem !important;
    margin-left:auto !important;
    margin-right:auto !important
}

.alert {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.2 ;
    font-size: 14px;
}
`;