import React from 'react';
import { useThemeContext } from '../context/theme_context';
import styled from 'styled-components';
import { ICON_MOON, ICON_SUN } from './icons';

export const ThemeChange = ({ children }) => {

    const {
        darkMode: { switchTheme }
    } = useThemeContext();

    return (
        <span
            onClick={() => switchTheme()}
        >
            {children}
        </span>
    );
};

export const ThemeToggle = () => {

    const {
        darkMode: { darkTheme, switchTheme }
    } = useThemeContext();

    return (
        <StyledThemeToggle >
            <input type="checkbox"
                onChange={() => switchTheme()}
                checked={
                    darkTheme === 'dark' ? true : false
                }
            />
            <span className="slider round d-flex align-items-center ps-1 pe-2">
                {darkTheme === "dark" ? ICON_SUN : ICON_MOON}
            </span>
        </StyledThemeToggle>
    );
};

const StyledThemeToggle = styled.label`

  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  
   input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:  var(--sec-color);
  transition: background-color 0.4s;
    border-radius: 20px;
  }
  .slider.round {
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  /* height: 20px;
  width: 20px; */
  left: 2px;
  bottom: 2px;
  background-color:  var(--sec-color);
  transition: transform 0.4s, color 0.3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color:  var(--sec-color);
}

input:checked + .slider:before {
  transform: translateX(0);
}

.slider svg {
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  color: #333;
  transition: ease 0.3s !important; /* Added transition for the icons */

}

.slider svg {
  color: var(--pry-color); /* Color for dark mode icon */
  transform: translateX(0); /* Slide the dark mode icon to the left */
}

input:checked + .slider svg {
  color: #fff; /* Color for dark mode icon when active */
  transform: translateX(25px); /* Initial position for dark mode icon */
}

`
