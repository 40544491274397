import React, { useContext } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../api/axios';
import FetchApi from '../api/getAxios';
import { naira } from '../utils/data';
import { useUserContext } from './user_context';
import { useWalletContext } from './wallet_context';

export const EscrowContext = React.createContext();


export const EscrowProvider = ({ children }) => {

    const {
        getAcctBal: { getBal },
    } = useWalletContext();

    // Create new Escrow Actions
    const [escrowData, setescrowData] = useState('');
    const [escrowDataLoading, setescrowDataLoading] = useState(false);

    const { usertoken } = useUserContext();

    const [historyData, sethistoryData] = useState([]);
    const [EscrowLoading, setEscrowLoading] = useState(false);
    const [allEscrow, setallEscrow] = useState([]);

    // Get all Escrow Trx
    const getEscrow = async (e) => {
        setEscrowLoading(true);
        const data = {
            usertoken,
        };
        FetchApi.post(`/getUserEscrowTrx`, data)
            .then((res) => {
                if (res.success === false) {
                    setEscrowLoading(false);
                } else {
                    setEscrowLoading(false);
                    setallEscrow(res.data.data);
                    sethistoryData(res.data.data);
                }
            })
            .catch((error) => {
                setEscrowLoading(false);
            });
    };
    // Get all Escrow Trx

    // Sort Escrow Trx
    function selectData(e, setselectedLink) {
        if (e === 'successful') {
            setselectedLink('successful');
            sethistoryData(allEscrow?.filter((e) => e.resolved === "1"));
        } else if (e === 'pending') {
            setselectedLink('pending');
            sethistoryData(allEscrow?.filter((e) => e.status === "0"));
        } else if (e === 'closed') {
            setselectedLink('closed');
            sethistoryData(allEscrow?.filter((e) => (e.cancelled === "1") || (e.refunded === "1")));
        } else {
            setselectedLink('all');
            sethistoryData(allEscrow);
        }
    }

    // Sort Escrow Trx

    // Search Escrow Trx
    function searchTrx(key, setselectedLink) {
        if (key) {
            setselectedLink('all');
            const filteredTrx = allEscrow.filter((trx) => {
                const titleMatch = trx?.title.toLowerCase().includes(key.toLowerCase());
                const amountMatch = trx?.amount.includes(key);
                const tokenMatch = trx?.token.includes(key);
                return titleMatch || amountMatch || tokenMatch;
            });
            sethistoryData(filteredTrx);
        } else {
            setselectedLink('all');
            sethistoryData(allEscrow);
        }
    }
    // Search Escrow Trx

    // getEscrow Data Fn
    function getEscrowData(token) {
        if (token) {
            setescrowDataLoading(true);
            const data = { usertoken, token };
            FetchApi.post(`/getUserEscrowTrx-data`, data)
                .then((res) => {
                    if (res.success === false) { setescrowDataLoading(false); } else {
                        setescrowDataLoading(false);
                        setescrowData(res.data.data);
                    }
                }).catch((error) => { setescrowDataLoading(false); });
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // getEscrow Data Fn

    // Fund Escrow Fn
    function fundEscrow(escrowToken) {
        if (escrowToken) {
            if (window.confirm(`You are about to fund this escrow transaction with ${ naira }${ escrowData?.b_amount_th }. Confirm?`)) {
                setescrowDataLoading(true);
                const data = { usertoken, escrowToken };
                Api.post(`/fundEscrow`, data)
                    .then((res) => {
                        if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                            setescrowDataLoading(false);
                            toast.success(res.message);
                            getEscrowData(escrowToken);
                            getBal();
                        }
                    }).catch(() => { setescrowDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // Fund Escrow Fn

    // cancel Escrow Fn
    function cancelEscrow(escrowToken) {
        if (escrowToken) {
            if (window.confirm(`You are about to cancel this escrow transaction, this is not reversible. Confirm?`)) {
                setescrowDataLoading(true);
                const data = { usertoken, escrowToken };
                Api.post(`/cancelEscrow`, data)
                    .then((res) => {
                        if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                            setescrowDataLoading(false);
                            toast.success(res.message);
                            getEscrowData(escrowToken);
                        }
                    }).catch(() => { setescrowDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // cancel Escrow Fn

    // reverse Escrow Fn
    function reverseEscrow(escrowToken) {
        if (escrowToken) {
            if (window.confirm(`You are about to reverse payment for this escrow transaction to the buyer. Confirm?`)) {
                setescrowDataLoading(true);
                const data = { usertoken, escrowToken };
                Api.post(`/reverseEscrow`, data)
                    .then((res) => {
                        if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                            setescrowDataLoading(false);
                            toast.success(res.message);
                            getEscrowData(escrowToken);
                        }
                    }).catch(() => { setescrowDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // reverse Escrow Fn

    // resolve Escrow Fn
    function resolveEscrow(escrowToken) {
        if (escrowToken) {
            if (window.confirm(`You are about to confirm this escrow transaction, this is not reversible. Confirm?`)) {
                setescrowDataLoading(true);
                const data = { usertoken, escrowToken };
                Api.post(`/resolveEscrow`, data)
                    .then((res) => {
                        if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                            setescrowDataLoading(false);
                            toast.success(res.message);
                            getEscrowData(escrowToken);
                        }
                    }).catch(() => { setescrowDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // resolve Escrow Fn

    // requestRefund Escrow Fn
    function requestRefundEscrow(escrowToken) {
        if (escrowToken) {
            if (window.confirm(`You are about to Request Refund for this escrow transaction from the seller, this is not reversible. Confirm?`)) {
                setescrowDataLoading(true);
                const data = { usertoken, escrowToken };
                Api.post(`/requestRefundEscrow`, data)
                    .then((res) => {
                        if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                            setescrowDataLoading(false);
                            toast.success(res.message);
                            getEscrowData(escrowToken);
                        }
                    }).catch(() => { setescrowDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // requestRefund Escrow Fn

    // disputeEscrow Escrow Fn
    function openDisputeEscrow(escrowToken) {
        if (escrowToken) {
            const reason = window.prompt("What is your reason for raising this dispute?");
            if (reason) {
                if (window.confirm(`You are about to open a dispute for this escrow transaction, Confirm?`)) {
                    setescrowDataLoading(true);
                    const data = { usertoken, escrowToken, reason };
                    Api.post(`/disputeEscrow`, data)
                        .then((res) => {
                            if (res.success === false) { setescrowDataLoading(false); toast.warn(res.message); } else {
                                setescrowDataLoading(false);
                                toast.success(res.message);
                                getEscrowData(escrowToken);
                            }
                        }).catch(() => { setescrowDataLoading(false); });
                }
            } else {
                toast.warn('you need to add a reason!');
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewEscrow(false); }
    }
    // disputeEscrow Escrow Fn
    // Create new Escrow Actions


    // ConfirmNewEscrow
    const [showConfirmNewEscrow, setShowConfirmNewEscrow] = useState(false);
    const handleCloseConfirmNewEscrow = () => setShowConfirmNewEscrow(false);
    const handleShowConfirmNewEscrow = () => setShowConfirmNewEscrow(true);
    // ConfirmNewEscrow



    return (
        <EscrowContext.Provider value={{
            getAllEscrow: { EscrowLoading, allEscrow, historyData, getEscrow, selectData, searchTrx, setallEscrow },
            escrow: { escrowData, escrowDataLoading, getEscrowData },
            escrowAction: { fundEscrow, cancelEscrow, reverseEscrow, resolveEscrow, requestRefundEscrow, openDisputeEscrow },

            confirmNewEscrow: { showConfirmNewEscrow, handleCloseConfirmNewEscrow, handleShowConfirmNewEscrow },

        }}>
            {children}
        </EscrowContext.Provider>
    );
};

// make sure use
export const useEscrowContext = () => {
    return useContext(EscrowContext);
};
