import { useState } from "react";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button } from "../../../styles";
import { saveImage, shareImage } from "../../../utils/helpers";
import { ICON_QR, ICON_SAVE, ICON_SHARE_FILL } from "../../../utils/icons";
import { StyledBankTransfer } from "./BankTransfer";
import { QRCodeSVG } from "qrcode.react";
import { StyledQrImg } from "../RequestQr/SuccessQr";
import { useUserContext } from "../../../context/user_context";
import { logo_blue, logo_blue_bg } from "../../../assets";
import { useThemeContext } from "../../../context/theme_context";


const ShareQr = () => {

  const {
    userData
  } = useUserContext();
  const {
    darkMode: { darkTheme }
  } = useThemeContext();

  const [qrData] = useState(JSON.stringify({ qrCodeData: userData?.qrCodeData || '' }));


  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledBankTransfer>
        <SendLayout
          title={'Bank Transfer'}>
          <div className="text-center mb-5">
            <h1 className="yellow-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_QR}
            </h1>
            <p>
              Share the QR Code with any ReniTrust user and ask them to scan your QR Code with thier ReniTrust App
            </p>
          </div>


          <div className="my-2">
            <StyledQrImg
              className="fit-content"
              id="qrImg">
              <p className="light-text">
                Use ReniTrust Scan to Pay Me
              </p>
              <h6 className="fw-bold light-text text-uppercase">
                {userData?.fullname}
              </h6>
              <div className="my-3 light-bg p-4 br-md fit-content mx-auto">
                <QRCodeSVG
                  value={qrData}
                  bgColor="var(--light-color)"
                  fgColor="var(--pry-color)"
                  level="L"
                  className="qr"
                />
              </div>
              <div className="fit-content">
                <img src={darkTheme === 'dark' ? logo_blue_bg : logo_blue} alt="Reni Logo" width='20%'
                  className="p-0 m-0"
                />
              </div>
            </StyledQrImg>
          </div>


          <div className="mt-5 d-flex gap-2">
            <Button
              pry
              className="btn w-50"
              onClick={() =>
                shareImage("qrImg", "ReniTrust User QR Code")
              }
            >
              Share {ICON_SHARE_FILL}
            </Button>
            <Button
              pry
              className="btn w-50"
              onClick={() => saveImage("qrImg")}
            >
              Download {ICON_SAVE}
            </Button>
          </div>
        </SendLayout>
      </StyledBankTransfer>
    </AppWrapper >
  );
};

export default ShareQr;
