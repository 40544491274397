import styled from "styled-components";
import { bidex, fireswitch, heiler, sheda } from "../../assets";

const Client = () => {

      const client = [
            {
                  img: sheda,
                  width: '10vh'
            },
            {
                  img: heiler,
                  width: '20vh'
            },
            {
                  img: fireswitch,
                  width: '7vh'
            },
            {
                  img: bidex,
                  width: '10vh'
            },
      ];
      return (
            <StyledClient>
                  <div className="col-md-5 mx-auto">
                        <h5 className="mb-5 text-center">
                              Join 50+ successful business owners who
                              trust Renitrust for all their business needs.
                        </h5>
                  </div>
                  <div className="client row justify-content-around">
                        {
                              client?.map(({ img, width }, i) =>
                                    <div className="col-md-3 col-6 text-center mb-3">
                                          <img src={img} alt="Clients"
                                                style={{ width }}
                                          />
                                    </div>
                              )
                        }
                  </div>
            </StyledClient>
      );
};

export default Client;


const StyledClient = styled.section`
      padding: 3rem;
      background-color: var(--sec-color);

/* img {
      width: 10vh;
} */
`;