import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Api from '../api/axios';
import FetchApi from '../api/getAxios';
import { useFetch } from '../api/useFetch';
import { useUserContext } from './user_context';

export const WalletContext = React.createContext();


export const WalletProvider = ({ children }) => {

    const location = useLocation();
    let path = location.pathname;

    const { loading: chargesLoading, data: charges, fetchData: getCharges } = useFetch('systemData');

    // Get User Acct No
    const { loading: acctNoLoading, data: acctNo, fetchData: getAcctNo } = useFetch('getUserBankAccountNumber');

    // Get Acct Bal
    const [acctBal, setacctBal] = useState('');
    const [acctBalLoading, setacctBalLoading] = useState(false);

    const { usertoken } = useUserContext();

    function getBal() {
        if (acctNo?.nuban) {
            setacctBalLoading(true);
            const data = {

                usertoken: usertoken,
                accountNumber: acctNo?.nuban
            };
            FetchApi.post(`/getBankAccountBalance`, data)
                .then((res) => {
                    if (res.success === false) {
                        setacctBalLoading(false);
                    } else {
                        setacctBalLoading(false);
                        setacctBal(res.data);
                    }
                })
                .catch((error) => {
                    setacctBalLoading(false);
                });
        }
    }

    useEffect(() => {
        getAcctNo();
    }, [usertoken]);
    useEffect(() => {
        getBal();
    }, [acctNo?.nuban, path]);


    const [trxHistLoading, settrxHistLoading] = useState(false);
    const [trxHist, settrxHist] = useState([]);
    const [historyData, sethistoryData] = useState(trxHist);

    // Get All Transactions
    const getTrxHist = async (e) => {
        settrxHistLoading(true);
        const data = {
            usertoken: usertoken,
        };
        FetchApi.post(`/fetch.TrxHistory`, data)
            .then((res) => {
                if (res.success === false) {
                    settrxHistLoading(false);
                } else {
                    settrxHistLoading(false);
                    settrxHist(res.data.data);
                    sethistoryData(res.data.data);
                }
            })
            .catch((error) => {
                settrxHistLoading(false);
            });
    };
    // Get All Transactions


    // Sort Trx
    function selectData(e, setselectedLink) {
        if (e === 'Credit') {
            setselectedLink('Credit');
            sethistoryData(trxHist.filter((trx) => trx.type === "Credit"));
        } else if (e === 'Debit') {
            setselectedLink('Debit');
            sethistoryData(trxHist.filter((trx) => trx.type === "Debit"));
        } else {
            setselectedLink('all');
            sethistoryData(trxHist);
        }
    }
    // Sort Trx

    // Search Trx
    function searchTrx(key, setselectedLink) {
        if (key) {
            setselectedLink('all');
            const filteredTrx = trxHist?.filter((trx) => {
                const titleMatch = trx?.title.toLowerCase().includes(key.toLowerCase());
                const nameMatch = trx?.r_userData?.data?.fullname?.toLowerCase().includes(key.toLowerCase());
                const amountMatch = trx?.amount.includes(key);
                const refIdMatch = trx?.reference_number.includes(key);
                return titleMatch || nameMatch || amountMatch || refIdMatch;
            });
            sethistoryData(filteredTrx);
        } else {
            setselectedLink('all');
            sethistoryData(trxHist);
        }
    }
    // Search Trx

    // get Bill Trx History
    const billTrx = trxHist?.filter((e) => e.title === 'bills');
    // get Bill Trx History

    return (
        <WalletContext.Provider value={{
            getCharges: { chargesLoading, charges: charges?.charges },
            getAcctNo: { acctNoLoading, acctNo },
            getAcctBal: { acctBalLoading, acctBal, getBal, setacctBal },
            getTrxHist: { trxHistLoading, trxHist, billTrx, getTrxHist, selectData, sethistoryData, historyData, searchTrx, settrxHist }
        }}>
            {children}
        </WalletContext.Provider>
    );
};



// make sure use
export const useWalletContext = () => {
    return useContext(WalletContext);
};
