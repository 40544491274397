import { useState } from "react";
import { toast } from "react-toastify";
import Api from "../../api/axios";
import { useUserContext } from "../../context/user_context";
import { Button, Form, Input, Label, Select } from "../../styles";
import { ICON_PEN } from "../../utils/icons";
import { StyledAccount } from "./Profile.styled";
import { nextOfKin } from "../../utils/data";

const NextOfKinSettings = () => {

    const { usertoken, userData, getUserData } = useUserContext();

    const name = userData?.nextOfKin?.data?.name;
    const phone = userData?.nextOfKin?.data?.phone;
    const email = userData?.nextOfKin?.data?.mail;
    const relationship = userData?.nextOfKin?.data?.relationship;

    const [formField, setformField] = useState({ name, email, phone, relationship });
    const [sending, setsending] = useState(false);

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields



    const [kinEdit, setkinEdit] = useState(true);
    const editFields = () => setkinEdit(!kinEdit);


    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (
            formField?.name &&
            formField?.email &&
            formField?.phone &&
            formField?.relationship
        ) {
            setsending(true);
            const data = {
                usertoken,
                name: formField?.name,
                mail: formField?.email,
                phone: formField?.phone,
                relationship: formField?.relationship,

            };
            Api.post(`/updateNextOfKin`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        toast.success(res.message);
                        setsending(false);
                        getUserData();
                        editFields();
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }
    }

    // Submit Function


    return (
        <StyledAccount>

            <div className="mt-5">
                <div className="d-flex">
                    <h5 className="grey-bold-text">
                        Next of Kin info
                    </h5>
                    <span className="pry-text ms-2"
                        onClick={() => editFields()}
                    >
                        {ICON_PEN}
                    </span>
                </div>
                <Form onSubmit={(e) => submitFn(e)}>

                    <div className="row justify-content-start">
                        <div className="col-md-5 col-lg-2">
                            <Label>
                                Name
                            </Label>
                        </div>
                        <div className="col-md-7 col-lg-7 ">
                            <Input
                                type='text'
                                name="name"
                                className='mb-0'
                                placeholder="Enter Name"
                                onChange={handleChange}
                                value={formField?.name}
                                required
                                disabled={kinEdit}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-md-5 col-lg-2">
                            <Label>
                                Email Address
                            </Label>
                        </div>
                        <div className="col-md-7 col-lg-7 ">
                            <Input
                                type='email'
                                name="email"
                                className='mb-0'
                                placeholder="Enter Email"
                                onChange={handleChange}
                                value={formField?.email}
                                required
                                disabled={kinEdit}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-start">
                        <div className="col-md-5 col-lg-2">
                            <Label>
                                Phone Number
                            </Label>
                        </div>
                        <div className="col-md-7 col-lg-7 ">
                            <Input
                                type='number'
                                name="phone"
                                className='mb-0'
                                placeholder="Enter Phone Number"
                                onChange={handleChange}
                                value={formField?.phone}
                                required
                                disabled={kinEdit}
                            />
                        </div>
                    </div>
                    <h5 className="grey-bold-text mt-5">
                        Other Details
                    </h5>

                    <div className="row justify-content-start">
                        <div className="col-md-5 col-lg-2">
                            <Label>
                                Relationship
                            </Label>
                        </div>
                        <div className="col-md-7 col-lg-7 ">
                            <Select
                                name="relationship"
                                className='mb-0'
                                onChange={handleChange}
                                value={formField?.relationship}
                                required
                                disabled={kinEdit}
                            >
                                <option value="">--Select Relationship--</option>
                                {
                                    nextOfKin?.map(({ title }, i) =>
                                        <option value={title} key={i}>{title} </option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>

                    {!kinEdit &&
                       <div className="row justify-content-start mb-4">
                                <div className="col-md-5 col-lg-2">

                                </div>
                                <div className="col-md-7 col-lg-7 ">
                                  
                                <Button
                                    pry
                                    type="submit"
                                    disabled={sending}
                                    className="btn m-0 w-100"  >
                                    {sending ? 'loading...' : "Submit"}
                                </Button>
                            </div>
                        </div>
                    }
                </Form>

            </div>
        </StyledAccount>
    );
};

export default NextOfKinSettings;