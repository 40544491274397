import { Routes, Route } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import Home from '../pages/homepage/Home';
import NoPageAccess from '../pages/NoPageAccess';
import EmailRegister from '../pages/auth/EmailRegister';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import RegisterDone from '../pages/auth/RegisterDone';
import Dashboard from '../pages/user/Dashboard';
import Escrow from '../pages/user/Escrow';
import Wallet from '../pages/user/Wallet';
import Dispute from '../pages/user/Dispute';
import Referral from '../pages/user/Referral';
import Bills from '../pages/user/Bills';
import NewEscrow from '../components/escrow/NewEscrow';
import ForgetPassword from '../pages/auth/ForgetPassword';
import Notification from '../pages/user/Notification';
import Profile from '../pages/user/Profile';
import EscrowDetails from '../components/escrow/EscrowDetails';
import SendToBank from '../components/wallet/SendToBank/SendToBank';
import SendToUsername from '../components/wallet/SendToUsername/SendToUsername';
import SendToQr from '../components/wallet/SendToQr/SendToQr';
import SendToBeneficiary from '../components/wallet/SendToBeneficiary/SendToBeneficiary';
import ShareUsername from '../components/wallet/fundAccount/ShareUsername';
import BankTransfer from '../components/wallet/fundAccount/BankTransfer';
import FundCard from '../components/wallet/fundAccount/FundCard';
import USSD from '../components/wallet/fundAccount/USSD';
import RequestUsername from '../components/wallet/RequestUsername/RequestUsername';
import PayRequest from '../components/wallet/RequestUsername/PayRequest';
import SendMoney from '../components/wallet/SendMoney';
import AddMoney from '../components/wallet/AddMoney';
import RequestMoney from '../components/wallet/RequestMoney';
import RequestQr from '../components/wallet/RequestQr/RequestQr';
import ScanQr from '../components/wallet/SendToQr/ScanQr';
import AllTransactions from '../components/transactions/AllTransactions';
import TransactionDetails from '../components/transactions/TransactionDetails';
import DisputeDetails from '../components/dispute/DisputeDetails';
import BuyElectricity from '../components/bills/electricity/BuyElectricity';
import BuyAirtime from '../components/bills/airtime/BuyAirtime';
import BuyTv from '../components/bills/tv/BuyTv';
import BuyData from '../components/bills/data/BuyData';
import QrProfile from '../components/wallet/SendToQr/QrProfile';
import ShareQr from '../components/wallet/fundAccount/ShareQr';
import Test from '../components/Test';
import PayLink from '../pages/user/PayLink';
import NewPayLink from '../components/paylink/NewPayLink';
import PayLinkDetails from '../components/paylink/PayLinkDetails';
import Settings from '../pages/user/Settings';
import Terms from '../pages/homepage/Terms';

const Router = () => {
    return (
        <Routes>

            {/* Landing */}
            <Route path="/" element={<Home />} />
            <Route path="/terms" element={<Terms />} />


            {/* Auth */}
            <Route path="register" element={<EmailRegister />} />
            <Route path="ref/:id" element={<EmailRegister />} />
            <Route path="register-complete" element={<Register />} />
            <Route path="register-success" element={<RegisterDone />} />
            <Route path="login" element={<Login />} />
            <Route path="reset-password" element={<ForgetPassword />} />

            {/* Dashboard */}
            <Route path='user'>
                <Route index element={<Dashboard />} />
                <Route exact path="scan" element={<ScanQr />} />

                <Route path="dashboard" element={<Dashboard />} />
                <Route path="test" element={<Test />} />

                <Route path="escrow">

                    <Route index element={<Escrow />} />
                    <Route exact path="new" element={<NewEscrow />} />
                    <Route exact path=":id" element={<EscrowDetails />} />

                </Route>

                <Route exact path="paylink">
                    <Route index element={<PayLink />} />
                    <Route exact path="new" element={<NewPayLink />} />
                    <Route exact path=":id" element={<PayLinkDetails />} />


                </Route>

                <Route exact path="wallet">
                    <Route index element={<Wallet />} />

                    <Route exact path="transactions">
                        <Route index element={<AllTransactions />} />
                        <Route exact path=":id" element={<TransactionDetails />} />

                    </Route>

                    <Route exact path="fund">
                        <Route index element={<AddMoney />} />
                        <Route exact path="username" element={<ShareUsername />} />
                        <Route exact path="bank" element={<BankTransfer />} />
                        <Route exact path="qr" element={<ShareQr />} />
                        <Route exact path="card" element={<FundCard />} />
                        <Route exact path="ussd" element={<USSD />} />
                    </Route>

                    <Route exact path="send">
                        <Route index element={<SendMoney />} />
                        <Route exact path="username" element={<SendToUsername />} />
                        <Route exact path="bank" element={<SendToBank />} />
                        <Route exact path="qr" element={<SendToQr />} />
                        <Route exact path="beneficiary" element={<SendToBeneficiary />} />
                        <Route path=":username" element={<QrProfile />} />
                    </Route>


                    <Route exact path="request">
                        <Route index element={<RequestMoney />} />
                        <Route exact path="username" element={<RequestUsername />} />
                        <Route exact path="qr" element={<RequestQr />} />
                        <Route exact path="pay" element={<PayRequest />} />

                    </Route>

                </Route>


                <Route exact path="dispute">
                    <Route index element={<Dispute />} />
                    <Route exact path=":id" element={<DisputeDetails />} />
                </Route>

                <Route exact path="bills">
                    <Route index element={<Bills />} />
                    <Route exact path="airtime" element={<BuyAirtime />} />
                    <Route exact path="data" element={<BuyData />} />
                    <Route exact path="tv" element={<BuyTv />} />
                    <Route exact path="electricity" element={<BuyElectricity />} />

                </Route>

                <Route exact path="referral" element={<Referral />} />

                <Route exact path="notification" element={<Notification />} />

                <Route exact path="profile" element={<Profile />} />

                <Route exact path="settings" element={<Settings />} />
            </Route>


            {/* Error */}
            <Route path="noAccess" element={<NoPageAccess />} />
            <Route path="*" element={<ErrorPage />} />

        </Routes>
    );
};

export default Router;