import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSendFundContext } from "../../../context/send_fund_context";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button } from "../../../styles";
import { naira } from "../../../utils/data";
import { ICON_MARK_STYLE, ICON_PAY_MONEY, ICON_X_STYLE } from "../../../utils/icons";
import ConfirmDeclineRequest from "./ConfirmDeclineRequest";
import ConfirmPayRequest from "./ConfirmPayRequest";
import { th } from '../../../utils/helpers';
import AppLoader from "../../../utils/AppLoader";

const PayRequest = () => {

  const location = useLocation();
  const navigate = useNavigate();
  const [locationData, setlocationData] = useState({ state: { token: '' } });

  const {
    payRequest: { allRequestLoading, requestList, getRequestList },
    confirmPayRequest: { handleShowConfirmPayRequest },
    confirmDeclineRequest: { handleShowConfirmDeclineRequest },
  } = useSendFundContext();

  const { state: { token } } = locationData;
  const reqData = requestList?.data?.find((e) => e.token === token) || '';

  useEffect(() => {
    if (!location.state) {
      toast.error('page not valid, try again!');
      navigate('/user/dashboard');
      return;
    } else {
      setlocationData(location);
    }
  }, []);


  const u_name = reqData?.userData?.data?.fullname;
  const amount = reqData?.amount || 0.00;
  const decline = reqData?.decline;
  const status = reqData?.status;

  const data = { u_name, amount, token };


  useEffect(() => {
    getRequestList();
  }, []);




  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <SendLayout
        title={'Pay Money Request'}>
        {!allRequestLoading &&
          <>
            {reqData ?
              <>
                <div className="text-center mb-5">
                  <h1 className="text-success display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
                    {ICON_PAY_MONEY}
                  </h1>
                  <p>
                    A money request has been sent to you from
                  </p>
                  <h6 className="fw-bold">
                    {u_name}
                  </h6>
                  <small className="grey-bold-text">
                    Amount:
                  </small>
                  <h1>
                    {naira}{th(amount)}
                  </h1>
                  <small className="grey-bold-text">
                    Request ID:
                  </small>
                  <h6>
                   {token}
                  </h6>
                </div>
                {(decline === '1') || (status === '1') ?
                  <></> :
                  <div className="my-5 d-flex">
                    <Button
                      pry
                      className="btn w-50"
                      onClick={() => handleShowConfirmPayRequest()}
                    >
                      Accept
                    </Button>
                    <Button
                      className="btn w-50 "
                      onClick={() => handleShowConfirmDeclineRequest()}
                    >
                      Decline
                    </Button>
                  </div>
                }
                {status === '1' &&
                  <div className="my-5 text-center text-success fit-content shadow px-3 py-2 br-md mx-auto">
                    <h3>
                      {ICON_MARK_STYLE}
                    </h3>
                    <h5 className="fw-light ">
                      Request Confirmed
                    </h5>
                  </div>
                }
                {decline === '1' &&
                  <div className="my-5 text-center red-text fit-content shadow px-3 py-2 br-md mx-auto">
                    <h3>
                      {ICON_X_STYLE}
                    </h3>
                    <h5 className="fw-light ">
                      Request Delined
                    </h5>
                  </div>
                }
              </>
              :
              <div className="my-5 text-center">
                <h6 className="fw-bold">
                  Invalid Request
                </h6>
                <Button
                  className="btn w-50"
                >
                  Homepage
                </Button>
              </div>
            }
          </>
        }

        <ConfirmPayRequest
          {...data}
        />
        <ConfirmDeclineRequest
          {...data}
        />
        <AppLoader
          load={allRequestLoading}
        />
      </SendLayout>
    </AppWrapper >
  );
};

export default PayRequest;


