import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input, Label, NameInput, UserDetail } from '../../../styles';
import { ICON_EXCLAMATION, ICON_MARK_FILL } from '../../../utils/icons';
import { useSendFundContext } from "../../../context/send_fund_context";
import { ICON_PEOPLE } from "../../../utils/icons";
import ConfirmSendToBank from '../SendToBank/ConfirmSendToBank';
import { StyledSendFund } from '../Wallet.styled';
import AllBeneficiary from './AllBeneficiary';


const SendToBeneficiary = () => {


  const [formField, setformField] = useState({
    acct_no: '', amount: '', name: '', SessionID: '', narration: ''
  });
  const [proceed, setproceed] = useState(false);
  const [selectUser, setselectUser] = useState('');
  const [selectedBank, setselectedBank] = useState('');


  const {
    confirmBank: { handleShowConfirmBank },
    confirmBen: { handleShowConfirmBen },
  } = useSendFundContext();

  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field

  useEffect(() => {
    if (!selectedBank && !selectUser) {
      handleShowConfirmBen();
    }
  }, [selectedBank]);

  function checkSendDetails(e) {
    e.preventDefault();
    if (!selectedBank) {
      toast.warn('Select Bank!');
      return;
    }
    if (formField?.acct_no?.length !== 10) {
      toast.warn('Input Correct Account Number!');
      return;
    }
    if (!selectUser) {
      toast.warn('Unable to verify Bank Account!');
      return;
    }
    setproceed(true);
  }

  const data = { formField, selectedBank, selectUser, setformField, setselectedBank, setselectUser };

  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledSendFund>
        <SendLayout
          title={'Send to Beneficiary'}>

          <div className="text-center">
            <h1 className="red-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_PEOPLE}
            </h1>
          </div>

          <div className="mb-5">
            {/* Send Form */}
            <Form
              className="mb-5"
            >
              <div className="justify-content-center text-left">
                <div className="col-lg-12">
                  <Label htmlFor="bank">
                    Bank:
                  </Label>
                  {/* <CustomSelect
                    id="bank"
                    options={allBanks}
                    isSearchable={true}
                    onChange={(e) => setselectedBank(e)}
                    value={selectedBank.label}

                    placeholder='Select Banks'
                    required
                    isDisabled
                  /> */}
                  <Input
                    type="text"
                    name="bank"
                    id="bank"
                    placeholder={`Input Bank`}
                    className='mb-1'
                    disabled
                    // onChange={handleChange}
                    value={selectedBank.label}
                    required
                  />
                </div>

                <div className="col-lg-12 ">
                  <Label htmlFor="acct_no">
                    Account Number:
                  </Label>
                  <NameInput
                    type="number"
                    name="acct_no"
                    id="acct_no"
                    placeholder="12345127890"
                    onChange={handleChange}
                    value={formField.acct_no}
                    required
                    disabled
                  />
                  {formField.acct_no?.length === 10 &&
                    <>
                      <br />
                      <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                        {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                        {selectUser ? selectUser?.name : 'No User Found'}
                      </UserDetail>
                    </>
                  }
                  {!proceed &&
                    <div className="col-lg-12">
                      <Button
                        pry
                        className="btn w-100"
                        onClick={(e) => checkSendDetails(e)}
                      >
                        Next
                      </Button>
                    </div>
                  }

                  {proceed &&
                    <>
                      <Label htmlFor="amount">
                        Amount:
                      </Label>

                      <Input
                        type="number"
                        name="amount"
                        id="amount"
                        placeholder={`Input Amount`}
                        className='mb-1'
                        onChange={handleChange}
                        value={formField.amount}
                        required
                      />

                      <Label htmlFor="narration">
                        Narration:
                      </Label>
                      <Input
                        type="text"
                        name="narration"
                        id="narration"
                        placeholder="Input Narration"
                        onChange={handleChange}
                        value={formField.narration}
                        required
                      />
                      <div className="col-lg-12">
                        <Button
                          pry
                          className="btn w-100"
                          onClick={(e) => handleShowConfirmBank(e, data)}
                        >
                          Proceed
                        </Button>
                      </div>
                    </>
                  }
                </div>
              </div>
            </Form>

            {/* Send Form */}
          </div>
        </SendLayout>
      </StyledSendFund>
      <ConfirmSendToBank
        {...data}
      />
      <AllBeneficiary
        {...data}
      />

    </AppWrapper >
  );
};

export default SendToBeneficiary;

