import { useUserContext } from "../../../context/user_context";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input, Label, NameInput, UserDetail } from "../../../styles";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Api from "../../../api/axios";
import AppLoader from "../../../utils/AppLoader";
import { ICON_EXCLAMATION, ICON_MARK_FILL, ICON_QR } from '../../../utils/icons';
import { useSendFundContext } from "../../../context/send_fund_context";
import { naira } from "../../../utils/data";
import SuccessQr from "../RequestQr/SuccessQr";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";

const SendToQr = () => {

  const [genQr, setgenQr] = useState(false);
  const [formField, setformField] = useState({
    u_name: '', amount: ''
  });
  const [proceed, setproceed] = useState(false);
  const [selectUser, setselectUser] = useState('');
  const [issending, setissending] = useState(false);
  const [qrData, setqrData] = useState(JSON.stringify({ qrCodeData: '' }));
  let navigate = useNavigate();
  const [anon, setAnon] = useState(false);

  // Context
  const {
    user: { searchUser, message, loadingName, },
    successRequestQr: { handleShowSuccessRequestQr },
  } = useSendFundContext();
  const { usertoken } = useUserContext();
  // Context

  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field

  // Search User
  useEffect(() => {
    if (anon) {
      return;
    } else {
      searchUser(formField.u_name, setformField, setselectUser);
    }
  }, [formField.u_name]);
  // Search User

  // Verify Detail before Proceed
  function checkSendDetails(e) {
    e.preventDefault();
    if (!selectUser) {
      toast.warn('Unable to verify Username!');
      return;
    }
    setproceed(true);
  }

  // Verify Detail before Proceed
  // Send to Anonymous

  function checkAnon(e) {
    if (anon) {
      setAnon(false);
      setselectUser('');
      setformField((inputs) => ({
        ...inputs,
        u_name: '',
      }));
    } else {
      setAnon(true);
      setselectUser({
        fname: 'anonymous',
        fullname: 'anonymous',
        username: '',
        usertoken: '',
      });
      setformField((inputs) => ({
        ...inputs,
        u_name: 'anonymous',
      }));
    }
  }

  // Send to Anonymous

  // Open Success If successful
  const openSuccess = (data) => {
    setqrData(
      JSON.stringify(data)
    );
    handleShowSuccessRequestQr();
  };
  // Open Success If successful

  // Submit Function
  function submitFn() {
    if (
      formField.u_name && formField.amount
    ) {
      setissending(true);
      const dataWithUsername = {
        usertoken,
        amount: formField.amount,
        username: formField.u_name
      };
      const dataWithoutUsername = {
        usertoken,
        amount: formField.amount,
      };
      const data = anon ? dataWithoutUsername : dataWithUsername;
      const url = anon ? 'qrCode.sendToAll' : 'sendToQRCode';

      Api.post(`/${ url }`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
          } else {
            toast.success(res.message);
            setissending(false);
            openSuccess(res.data);
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Input Correct Details!");
    }
  }
  // Submit Function

  // Confirm before Submitting
  function confirm(e) {
    e.preventDefault();
    if (
      formField.u_name && formField.amount
    ) {
      if (window.confirm(`Are you sure? You are about to send ${ naira }${ formField?.amount } to ${ selectUser?.fullname }. Confirm?`)) {
        submitFn();
      }
    } else {
      toast.warn("Input Correct Details!");
    }
  }
  // Confirm before Submitting

  // Data for success modal
  const data = { formField, qrData };
  // Data for success modal


  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <AppLoader
        load={issending}
      />
      <SendLayout
        title={genQr && 'Send using QR Code'}>

        <StyledSendToQr>

          {!genQr &&
            <>
              <div className="text-center">
                <h4 className="mb-4">
                  Generate QR Code
                </h4>
                <p>
                  Generate custom QR codes instantly for seamless transactions.
                </p>
                <Button pry
                  className="btn"
                  onClick={() => setgenQr(true)}
                >
                  Generate QR Code
                </Button>
              </div>
              <div className="text-center mt-5 pt-3">
                <h4 className="mb-4">
                  Scan QR Code
                </h4>
                <p>
                  Effortlessly upload your own QR codes for seamless transactions.
                </p>
                <Button
                  className="btn border border-primary pry-text"
                  onClick={() => navigate('/user/scan')}
                >
                  Scan QR Code
                </Button>
              </div>
            </>
          }

          {genQr &&
            <>
              {/* Send Form */}
              <div className="text-center">
                <h1 className="yellow-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
                  {ICON_QR}
                </h1>
                <p>
                  Generate custom QR codes instantly for to sending fund to a ReniTrust User.
                </p>
              </div>
              <Form
                className="my-5"
              >
                <div className="justify-content-center text-left">

                  <div className="col-lg-12 ">
                    <Label htmlFor="u_name"
                      className='fw-bold dark-text name_label'
                    >
                      Send money to
                    </Label>
                    {loadingName &&
                      <ClipLoader size='20' color={'var(--pry-color)'} className='mx-2' />
                    }
                    <NameInput
                      type="search"
                      name="u_name"
                      id="u_name"
                      placeholder="Reni Username"
                      onChange={handleChange}
                      value={formField.u_name}
                      required
                      disabled={anon}
                    />

                    <div className="">
                      <label className="custom-checkbox-label mb-4">
                        <input
                          type="checkbox"
                          value={anon}
                          checked={anon}
                          onChange={() => checkAnon()}
                          className="custom-checkbox"
                        />
                        send to anyone anonymously
                      </label>
                    </div>

                    {!anon &&
                      <>
                        {formField.u_name &&
                          <>
                            <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                              {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}{' '}
                              {selectUser ? selectUser?.fullname : message}
                            </UserDetail>
                          </>
                        }
                      </>
                    }
                    {!proceed &&
                      <div className="col-lg-12">
                        <Button
                          pry
                          className="btn w-100"
                          onClick={(e) => checkSendDetails(e)}
                        >
                          Next
                        </Button>
                      </div>
                    }

                    {proceed &&
                      <>
                        <Label htmlFor="amount">
                          Amount:
                        </Label>

                        <Input
                          type="number"
                          name="amount"
                          id="amount"
                          placeholder={`Input Amount`}
                          className='mb-1'
                          onChange={handleChange}
                          value={formField.amount}
                          required
                        />

                        <Label htmlFor="details">
                          Narration:
                        </Label>
                        <Input
                          type="text"
                          name="details"
                          id="details"
                          placeholder="Input Narration"
                          onChange={handleChange}
                          value={formField.details}
                          required
                        />
                        <div className="col-lg-12">
                          <Button
                            pry
                            disabled={issending}
                            className="btn w-100"
                            onClick={(e) => confirm(e)}
                          >
                            {issending ? 'loading...' : "Proceed"}
                          </Button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </Form>

              {/* Send Form */}

            </>}

        </StyledSendToQr>

      </SendLayout>

      <SuccessQr
        {...data}
        name={'Transfer'}

      />
    </AppWrapper >
  );
};

export default SendToQr;


const StyledSendToQr = styled.div`



`;