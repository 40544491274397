import styled from "styled-components";
import { phone } from "../../assets";
import { Button } from "../../styles";
import { moreAbout } from "../../utils/data";
import { Link } from "react-router-dom";

const MoreAbout = () => {



      return (
            <StyledMoreAbout>
                  <div className="row align-items-center ">
                        <div className="col-md-6 text-center ">
                              <img src={phone} alt="Reni App" />
                        </div>
                        <div className="col-md-6">
                              <h1 className="my-5">
                                    Fund wallet, save and pay in seconds
                              </h1>
                              {
                                    moreAbout?.map(({ title, content }, i) => {
                                          return (
                                                <div className="more-card mb-5" key={i}>
                                                      <h5 className="mb-4">
                                                            {title}
                                                      </h5>
                                                      <p className="mb-4">
                                                            {content}
                                                      </p>
                                                </div>
                                          );
                                    })
                              }
                              <Link to='/register'>
                                    <Button pry className="btn br-md">
                                          Get started
                                    </Button>
                              </Link>
                        </div>
                  </div>
            </StyledMoreAbout>
      );
};

export default MoreAbout;

const StyledMoreAbout = styled.section`
      padding-bottom: 5rem;
      color: var(--home-pry);

img{
      width:50vh;
      
      @media screen and (max-width: 2024px) {
            width:30vh;  
      }
      @media screen and (max-width: 1024px) {
            width:30vh;  
      }
  @media screen and (max-width: 580px) {
            width:100% !important;  
      }
}


      .more-card {
            border-left: 1px solid var(--home-pry);
            padding-left:1rem;

      }
      

`;