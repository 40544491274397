import styled from "styled-components";


export const StyledEscrowSegment = styled.div`

button.initiate {
    height: 77px;
    font-size: 15px;

    svg {
        font-size: 30px;
    }
    :hover, :focus{
    color:var(--light-color) !important;
    }
}

.escrow-history {
    box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);

    .history {
        background-color: var(--sec-color);
        color: var(--pry-color);
        padding: 1rem .5rem;
        margin-bottom: .9rem;
        transition: .2s ease;
        box-shadow: 1.06709px 0px 4.26834px #1561bd70;


        h5{
            font-size: smaller;
        }
        p{
            margin:0;
            font-size:10px;
        }
        svg {
        font-size: 40px;
        }
        :hover {
            background-color: var(--pry-color);
            color: var(--light-color);
            a{
                color: var(--light-color) !important;
            }
        }    
    }
}
`;