import styled from "styled-components";
import { usePayLinkContext } from "../../context/paylink_context";
import { useEffect } from "react";
import AppLoader from "../../utils/AppLoader";
import { Input } from "../../styles";
import { Link } from "react-router-dom";
import EmptyData from "../EmptyData";
import { naira } from "../../utils/data";
import { th } from "../../utils/helpers";
import { ICON_BIN, ICON_EYE_FILL, ICON_LINK } from "../../utils/icons";



const PayLinkHistory = () => {

      const {
            getAllPayLink: { allPayLink },
      } = usePayLinkContext();

      const {
            getAllPayLink: { payLinkLoading, getPayLink, searchTrx }
      } = usePayLinkContext();

      useEffect(() => {
            getPayLink();
      }, []);
      return (
            <StyledPayLinkHistory>
                  <AppLoader load={payLinkLoading} />

                  <div className="mt-4">
                        <Input
                              type="search"
                              name="search"
                              placeholder="Search Transaction (title, amount, trx ID)"
                              onChange={(e) => searchTrx(e.target.value)}
                              className="mb-0 m-0"
                        />
                        <PayLinkList data={allPayLink?.data} />
                  </div>



            </StyledPayLinkHistory>
      );
};

export default PayLinkHistory;


export const PayLinkList = ({ data }) => {

      return (
            <div className="paylink-history mt-3 px-2 px-lg-4 br-md py-4">

                  <EmptyData
                        data={data}
                        title='Payment Link'
                  />
                  {
                        data?.map(({ name, realTime, description, token, amount }) => {
                              return (
                                    <div className="history  border-bottom" key={token}>
                                          <div className="d-flex">
                                                <div className="col-md-6 text-break">
                                                      <div className="d-flex align-items-center">
                                                            <h1 className=" me-2">
                                                                  {ICON_LINK}
                                                            </h1>
                                                            <div className="">

                                                                  <h6 className="fw-bold m-0">
                                                                        {name}
                                                                  </h6>
                                                                  <p className="w-100 fw-light">
                                                                        {description}
                                                                  </p>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="col text-end">
                                                      <h6 className="fw-bold m-0 green-text">
                                                            {naira}{th(amount)}
                                                      </h6>
                                                      <small className="grey-bold-text">
                                                            {realTime}
                                                      </small>
                                                </div>
                                                <div className="col text-end">
                                                      <Link to={`/user/payLink/${ token }`}
                                                            className="green-text"
                                                      >
                                                            {ICON_EYE_FILL}
                                                      </Link>
                                                      <span className="red-text">
                                                            {ICON_BIN}
                                                      </span>

                                                </div>
                                          </div>

                                    </div>
                              );
                        }).reverse()
                  }
            </div>

      );
};



const StyledPayLinkHistory = styled.div`
      

      
.paylink-history {
    box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);

    .history {
        /* background-color: var(--sec-color);
        color: var(--pry-color); */
        padding: 1rem .5rem;
        margin-bottom: .9rem;
        transition: .2s ease;
        /* box-shadow: 1.06709px 0px 4.26834px #1561bd70; */


        h5{
            font-size: smaller;
        }
        p{
            margin:0;
            font-size:10px;
            /* white-space: nowrap; */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3 !important;
            -webkit-box-orient: vertical;
        }

        svg {
        font-size: 20px;
        margin-left:.5rem;
        }
        :hover {
            border-radius: 10px;
            background-color: var(--sec-color);
            color: var(--pry-color);
        }    
    }
}



`;