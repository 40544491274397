import styled from 'styled-components';
import { logo_blue_bg, logo_white } from '../assets';
import { Link } from 'react-router-dom';
import HomeNavbar from '../components/homepage/HomeNavbar';
import { useThemeContext } from '../context/theme_context';


const AuthWrapper = ({ children, title, subTitle }) => {


      const {
            darkMode: { darkTheme }
      } = useThemeContext();


      return (
            <>
                  <HomeNavbar />
                  <Wrapper>
                        <section className='container'>
                              <div className="col-md-6 text-center mx-auto">
                                    <Link to='/'>
                                          <img src={darkTheme === 'dark' ? logo_white : logo_blue_bg} alt="Reni Logo" width="30%" />
                                    </Link>
                                    <h4 className="pry-text mt-4">
                                          {title}
                                    </h4>
                                    <p className="grey-bold-text mx-auto mt-2">
                                          {subTitle}
                                    </p>
                                    <div className="border py-5 px-3 my-5 br-sm">
                                          {children}
                                    </div>
                              </div>
                        </section>
                  </Wrapper>
            </>
      );
};

export default AuthWrapper;


export const Wrapper = styled.main`
padding: 5rem 0;
background: var(--light-color);
min-height: 100vh;
`;