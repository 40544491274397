import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ICON_MARK } from "../../../utils/icons";
import { Button } from "../../../styles";



const SuccessCard = (props) => {

  const { show, onHide } = props;

  const navigate = useNavigate();

  function proceed() {
    navigate('/user/dashboard');
    onHide();
  }

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Body>
          <StyledSuccessModal className="text-center">
            <div className="my-5">
              <span className="success-icon">
                {ICON_MARK}
              </span>
              <div className="my-4">
                <h5 className="">
                  Payment Successful!
                </h5>
                <p className="grey-bold-text col-8 mx-auto">
                  Card charged successfully, your account will be funded soon.
                </p>
              </div>
            </div>

            <Button
              className="btn w-100"
              pry
              onClick={() => proceed()}
            >
              Homepage
            </Button>

          </StyledSuccessModal>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default SuccessCard;

export const StyledSuccessModal = styled.div`
  .success-icon svg {
    font-size: 70px;
    padding:7px;
    background-color: var(--pry-color);
    color: var(--light-color);
    border-radius: 50%;
    margin-bottom: 2rem;

  }
  svg.qr {
  background-color: var(--light-color) !important;
  border-radius: 0 ;
  }
  button{
    margin: 3px !important;
  }
`;
