import { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import { Button } from "../../../styles";
import AppLoader from "../../../utils/AppLoader";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import { StyledConfirmPayBill } from "../Bill.styled";
import SuccessBill from "../SuccessBill";


const ConfirmData = (props) => {

  const { formField, selectedBiller, selectedDataBiller } = props;
  const { phone } = formField;
  const { network, service } = selectedBiller;
  const { amount, code, description, duration, type, value } = selectedDataBiller;
  const { usertoken } = useUserContext();
  const [sending, setsending] = useState(false);
  const [result, setresult] = useState('');

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => { setShow(true); props.onHide(); };
  // Modal


  // Buy Electricity
  function submitFn() {
    if (amount && phone && network) {
      setsending(true);
      const data = {
        usertoken, network, type, code,
        phone, amount
      };
      Api.post(`/bills.buyData`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setsending(false);
          } else {
            toast.success(res.message);
            setresult(res.data.data);
            setsending(false);
            handleShow();
          }
        })
        .catch((error) => {
          setsending(false);
        });
    } else {
      setsending(false);
      toast.warn("Empty Fields!");
    }
  }
  // Buy Electricity

  const data = { title: 'Data', type: `${ service } - ${ duration }`, receipient: phone, name: '', amount, result };

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            YOUR ORDER SUMMARY
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyledConfirmPayBill>
            <AppLoader
              load={sending}
            />

            <div className="text-center">
              <small>
                To:
              </small>
              <h5 className="mb-0 text-uppercase">
                {phone || 'Nil'}
              </h5>
              <small>
                Amount:
              </small>
              <h6 className="fw-bold pry-text">
                {naira}{th(amount)}
              </h6>
              <hr />

              <div className="d-flex mb-2">
                <small className="col-3 text-start">
                  Biller:
                </small>
                <div className="col text-end">
                  <h6 className="text-break fw-bold">
                    {network}
                  </h6>
                </div>
              </div>
              <div className="d-flex mb-2">
                <small className="col-3 text-start">
                  Duration:
                </small>
                <div className="col text-end">
                  <h6 className="text-break fw-bold">
                    {duration}
                  </h6>
                </div>
              </div>
              <div className="d-flex mb-2">
                <small className="col-3 text-start">
                  Value:
                </small>
                <div className="col text-end">
                  <h6 className="text-break fw-bold">
                    {value}
                  </h6>
                </div>
              </div>
              <div className="d-flex mb-2">
                <small className="col-3 text-start">
                  Description:
                </small>
                <div className="col text-end">
                  <h6 className="text-break fw-bold">
                    {description}
                  </h6>
                </div>
              </div>
            </div>

            <Button
              pry
              className="btn w-100"
              onClick={() => submitFn()}
            >
              Confirm Payment
            </Button>

          </StyledConfirmPayBill>
        </Modal.Body>
      </Modal>

      <SuccessBill
        show={show}
        onHide={handleClose}
        {...data}
      />

    </>
  );
};

export default ConfirmData;


