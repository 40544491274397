import { useState, useEffect } from 'react';
import { ICON_X } from '../../utils/icons';
import { CloseBtn, StyledSideNav } from './Nav.styled';

import NavLinks from "./NavLinks";

const SideNav = () => {


  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const closeNav = (e) => {
    document.getElementById("mySidenav").style.width = "0";
  };
  if (document.getElementById("mySidenav")) {
    if (window.innerWidth > 580) {
      document.getElementById("mySidenav").style.width = "250px";
    } else {
      document.getElementById("mySidenav").style.width = "0";
    }
  }

  return (
    <>
      <StyledSideNav id="mySidenav">
        <CloseBtn>
          <span className="pry-text"
            onClick={(e) => closeNav(e)}
          >
            {ICON_X}
          </span>
        </CloseBtn>

        <NavLinks />

      </StyledSideNav>
    </>
  );
};

export default SideNav;
