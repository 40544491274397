import styled from "styled-components";
import { Button } from "../../styles";
import { Link } from "react-router-dom";

const Banner = () => {
      return (
            <StyledBanner>
                  <div className="banner br-md text-md-start text-center">
                        <div className="row">
                              <div className="col-md-9 mb-4 mb-md-0">
                                    <h4 className="light-text">
                                          Sign up for free. <br />
                                          Buy safely from the internet.
                                    </h4>
                              </div>
                              <div className="col-md-3 ">
                                    <Link to='/register'>
                                          <Button light className="btn br-md btn-lg fw-bold">
                                                Get Started
                                          </Button>
                                    </Link>
                              </div>
                        </div>
                  </div>
            </StyledBanner>
      );
};

export default Banner;


const StyledBanner = styled.section`
     padding-bottom: 5rem; 

     .banner{
           background: var(--pry-color);
           box-shadow: 0px 59px 31px -34px rgba(0, 103, 245, 0.3);
           padding: 60px 40px;
           
           @media screen and (max-width: 580px) {
             padding: 60px 25px;
           
          }
      }



`;