import styled from "styled-components";
import HomeNavbar from "../../components/homepage/HomeNavbar";
import Hero from "../../components/homepage/Hero";
import Features from "../../components/homepage/Features";
import Client from "../../components/homepage/Client";
import Customer from "../../components/homepage/Customer";
import About from "../../components/homepage/About";
import Footer from "../../components/homepage/Footer";
import Banner from "../../components/homepage/Banner";
import MoreAbout from "../../components/homepage/MoreAbout";


const Home = () => {

  return (
    <StyledHome>
      <>
        <div className="container">
          <HomeNavbar />
          <Hero />
          <Features />
          <MoreAbout />
        </div>
        <Client />
        <Customer />
        <div className="container">
          <About />
          <Banner />
        </div>
        <Footer />
      </>
    </StyledHome>

  );
};

export default Home;

const StyledHome = styled.main`
background-color: var(--light-color);
color: var(--dark-color);
/* min-height: 100vh; */
align-items: center;
justify-content: center;
overflow-x: hidden;
 margin: 0 auto;
  padding: 0;
  /* height: 100vh; */
  width: 100%;

`;