
import { ICON_SEARCH } from '../utils/icons';
import { StyledEmptyData } from '../styles';

const EmptyData = ({ data, title }) => {
    return (
        <>
            {!data?.length &&
                < StyledEmptyData >
                    {ICON_SEARCH}
                    <p className="">
                        You don’t have {title} yet
                    </p>
                </StyledEmptyData>
                //  : null
            }
        </>
    );
};

export default EmptyData;