
import { useState, useEffect, useRef, React } from 'react';
import styled from 'styled-components';
import { EscrowBuyerCard, EscrowEscrowCard, EscrowSellerCard } from './EscrowDetailsBalCard';

const Carousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef(null);
    const indicatorRefs = useRef([]);

    useEffect(() => {
        function handleScroll() {
            const newIndex = Math.round(carouselRef.current.scrollLeft / carouselRef.current.offsetWidth);
            setActiveIndex(newIndex);
        }
        carouselRef.current.addEventListener('scroll', handleScroll);
        return () => carouselRef.current?.removeEventListener('scroll', handleScroll);
    }, []);

    function handleIndicatorClick(index) {
        carouselRef.current.scroll({
            left: index * carouselRef.current.offsetWidth,
            behavior: 'smooth',
        });
        setActiveIndex(index);
    }

    return (
        <StyledEscrowBal>
            <div className="carousel-container">
                <div className="carousel" ref={carouselRef}>
                    <div className=" col-lg-5 col-10 col-md-7 mb-3">
                        <EscrowBuyerCard />
                    </div>
                    <div className=" col-lg-5 col-10 col-md-7 mb-3">
                        <EscrowEscrowCard />
                    </div>
                    <div className=" col-lg-5 col-10 col-md-7 mb-3">
                        <EscrowSellerCard />
                    </div>
                </div>
            </div>
            <div className="carousel-indicator">
                {Array.from({ length: 3 }).map((_, index) => (
                    <div
                        key={index}
                        className={`indicator ${ activeIndex === index ? 'active' : '' }`}
                        ref={(el) => (indicatorRefs.current[index] = el)}
                        onClick={() => handleIndicatorClick(index)}
                    ></div>
                ))}
            </div>
        </StyledEscrowBal>
    );
};

export default Carousel;


const StyledEscrowBal = styled.div`

.carousel {
display: flex;
gap: 10px;
overflow: scroll;

scrollbar-width: none;
  -ms-overflow-style: none; 
  ::-webkit-scrollbar {
    display: none; 
  }
}

.carousel-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  background-color: lightgray;
}

.indicator.active {
  background-color: var(--pry-color);
}

`;