import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { useDisputeContext } from '../../context/dispute_context';
import { Form, Textarea } from '../../styles';
import AppLoader from '../../utils/AppLoader';
import { ICON_PAPER_CLIP, ICON_SEND } from '../../utils/icons';
import { convertFile } from '../../utils/helpers';


const DisputeSendChat = (props) => {

    const [formField, setformField] = useState({
        chat: '', file: '', type: ''
    });

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field 

    const {
        chat: { sendChat, chatSending },
    } = useDisputeContext();

    function clear() {
        setformField({
            chat: '', file: '', type: ''
        });
    }

    return (
        <>
            <AppLoader
                load={chatSending}
            />
            <Form>
                <StyledSendChat>
                    <div className='col mx-auto d-flex align-items-center'>
                        <div className="row msg-box m-0 col align-items-center">
                            {formField?.file &&
                                <div className="m-2 mb-0" >

                                    <img src={formField?.file} alt='Chat File' />
                                    <span className="cancel pointer"
                                        onClick={() => setformField('')}
                                    >
                                        x
                                    </span>


                                </div>
                            }

                            <div className="d-flex col align-items-center">
                                <Textarea
                                    type='text'
                                    name="chat"
                                    className='px-0 pe-1'
                                    rows='1'
                                    placeholder="Enter your message"
                                    onChange={handleChange}
                                    value={formField.chat}
                                    required
                                />
                                <div class="file-upload">
                                    <input
                                        accept="image/*"
                                        type="file"
                                        onChange={(e) => convertFile(e, setformField, formField)}
                                    />
                                    <div className='ms-0 fit-content px-2 py-1 br-xlg'
                                        title='Only accept (images, audio and docx'
                                    >
                                        {ICON_PAPER_CLIP}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='pry fit-content shadow px-2 py-1 br-xlg light-text ms-2 border-0'
                            onClick={(e) => sendChat(e, formField, { ...props }, clear)}
                        >
                            {ICON_SEND}
                        </button>
                    </div>
                </StyledSendChat>
            </Form>
        </>
    );
};

export default DisputeSendChat;

const StyledSendChat = styled.div`

    align-items: center;
    backdrop-filter: blur(4px);
    bottom: 0;
    display: flex;
    justify-content:center !important;
    padding: 0 1% 20px;
    position: fixed;
    transition: .5s ease-in;
    width: calc(98vw - 250px);
    
    @media  screen and (max-width: 580px) {
        padding: 0px 0px 20px;
        width: 94vw;
        margin-bottom:5rem !important;
    }
    
    .msg-box{
        background: #f1f1f1;
        border-radius:7px;
        
    img {
    transition: 1s ease-in !important;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-size: contain;
    }

    .cancel {
    position: absolute;
    left: 72px;
    
    font-size: 10px;
    font-weight: 600;
    
    padding: 0px 4px;
    background: var(--grey-color);
    border-radius: 50px;
}



    textarea {
        margin-bottom:0 !important;
        margin-top:0 !important;
        background: none !important;
        border:none !important;
        resize: none !important;
        line-height: 1.4;
      
    scrollbar-width: none;
  -ms-overflow-style: none; 
  ::-webkit-scrollbar {
    display: none; 
  }

    }
    
    .file-upload {
        position: relative;
        font-size:20px;
        border-radius: 5rem;
        }
    .file-upload:hover {
           color: var(--pry-color)!important;
            background: var(--light-bold-color);
    }
    
    input[type="file"] {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
  

}


`;