
import DisputeHistory from '../../components/dispute/DisputeHistory';
import AppWrapper from '../../layout/AppWrapper';

const Dispute = () => {
  return (
    <AppWrapper
      pagename={"Dispute"}
    >

      <DisputeHistory />
    </AppWrapper>
  );
};

export default Dispute;