
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { card_bg } from "../../assets";
import { useUserContext } from '../../context/user_context';
import { useWalletContext } from '../../context/wallet_context';
import { naira } from '../../utils/data';
import { CopyItem } from '../../utils/helpers';
import { ICON_SCAN } from '../../utils/icons';

const BalanaceCard = () => {

  const {
    getAcctNo: { acctNo },
    getAcctBal: { acctBalLoading, acctBal }
  } = useWalletContext();

  const { userData } = useUserContext();

  let navigate = useNavigate();

  // Copy Function 
  const [copied, setcopied] = useState(false);
  function copy() {
    setcopied(!copied);
    alert(`Account Number Copied, Make a transfer to ${ acctNo?.nuban || '********' } and select 'Enrich MicroFinance Bank' as the bank.`);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  }
  function noAcct() {
    alert(` Sorry, you do not have an account yet, add your bvn to get an account. If you have added it, please hold on for a while, your account is being created.`);
  }
  // Copy Function
  return (
    <StyledBalanaceCard>
      <div className="row justify-content-around">
        <div className="col">
          <p>
            {/* Account Name */}
            ReniTrust ID
          </p>
          <h6 className='text-uppercase text-break'>
            @{userData?.username || 'JaneDoe'}
          </h6>
        </div>
        <div className="col"
          title="Account Details">
          <p className='text-end'>
            {acctNo?.bankname || 'ReniTrust'}

          </p>
          {acctNo?.nuban ?
            <CopyItem value={acctNo?.nuban}>
              <h6 className='text-end pointer'
                onClick={() => copy()}
              >
                {acctNo?.nuban}
                <br />
                <span className="small">
                  <i>
                    {copied ? ' Copied' : ''}
                  </i>
                </span>
              </h6>
            </CopyItem> :
            <h6 className='text-end pointer'
              onClick={() => noAcct()}
            >
              *********
              <br />
            </h6>
          }
        </div>
      </div>
      <div className="row justify-content-around">
        <div className="col">
          <p>
            Current Balance
          </p>
          <h4>
            {naira}
            {acctBalLoading ?
              <ClipLoader size='20' color={'var(--light-color)'} className='mx-2' />
              :
              (acctBal?.AvailableBalance_th || '0.00')
            }
          </h4>
        </div>
        <div className="col text-end"
          onClick={() => navigate('/user/scan')}
          title="Scan QR Code"
        >
          {ICON_SCAN}
        </div>
      </div>
    </StyledBalanaceCard >
  );
};

export default BalanaceCard;

export const StyledBalanaceCard = styled.div`

  background: url(${ card_bg }) no-repeat ;
  margin: 0;
  background-size: cover;
  overflow-x: hidden;
  border-radius: 1rem;
  color: #ffffff !important;
  padding: 1rem;
  min-height:234px;
// height: 100%;
  display:flex;
  flex-direction:column;
  justify-content: space-between;

  img{
    width: 6rem ;
  }

  p{
    margin:0;
    font-size:10px;
    color: #FFFFFF4D;
  }

  svg {
    font-size: 40px;
  }

`;