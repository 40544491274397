import CryptoJS from 'crypto-js';

const key = process.env.REACT_APP_SECRET_KEY;
const iv = CryptoJS.lib.WordArray.random(16);

export function encrypt(data) {
    if (typeof data === undefined) {
        return '';
    }

    const dataJson = JSON.stringify(data);
    const encryptedData = CryptoJS.AES.encrypt(
        dataJson,
        key,
        {
            mode: CryptoJS.mode.CBC,
            iv: iv,
        }
    ).toString();

    return encryptedData;
}


export function decrypt(encryptedData) {
    if (encryptedData === null) {
        return null;
    }

    // Check that the encryptedData is a string and is not empty
    if (typeof encryptedData !== 'string' || encryptedData.length === 0) {
        return null;
    }

    const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        key,
        {
            mode: CryptoJS.mode.CBC,
            iv: iv,
        }
    ).toString(CryptoJS.enc.Utf8);
    const decrytpedJSON = JSON.parse(decryptedData);
    return decrytpedJSON;
}




export const objectToBase64 = (obj) => {
    const jsonString = JSON.stringify(obj);
    const base64String = btoa(jsonString);
    return base64String;
};


export const base64ToObject = (base64String) => {
    const jsonString = atob(base64String);
    const obj = JSON.parse(jsonString);
    return obj;
};






