import styled from "styled-components";


export const StyledProfile = styled.div`


`;

export const StyledAccount = styled.div`
       .file-upload {
        position: relative;
        font-size:20px;
        border-radius: 5rem;
        }
    
    input[type="file"] {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      cursor: pointer;
    }
    
    img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }

    label {
        color: var(--dark-color);
        font-size: 17px;
    }

    option, select {
        text-transform: capitalize;
    }
    input {
        font-size: 20px;
        margin: 0;
    }

`;