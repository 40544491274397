
import styled from 'styled-components';
import { useState } from 'react';
import { useEffect } from 'react';
import AppWrapper from '../../layout/AppWrapper';
import { Button, Form, Input, Label, NameInput, Select, Textarea, UserDetail } from '../../styles';
import { ICON_EXCLAMATION, ICON_MARK_FILL } from '../../utils/icons';
import { useSendFundContext } from '../../context/send_fund_context';
import BackButton from '../BackButton';
import { useEscrowContext } from '../../context/escrow_context';
import ConfirmNewEscrow from './EscrowDetails/ConfirmNewEscrow';
import { toast } from 'react-toastify';
import { naira } from '../../utils/data';
import FetchApi from '../../api/getAxios';
import { useUserContext } from '../../context/user_context';
import { ClipLoader } from 'react-spinners';
import { useWalletContext } from '../../context/wallet_context';

const NewEscrow = () => {
  const [formField, setformField] = useState({});

  const [recipient, setrecipient] = useState('Recipient');

  const [selectUser, setselectUser] = useState('');
  const [userDisable, setuserDisable] = useState(false);

  const {
  } = useSendFundContext();
  const {
    confirmNewEscrow: { handleShowConfirmNewEscrow },
  } = useEscrowContext();
  const { usertoken } = useUserContext();
  const {
    getCharges: { charges },
  } = useWalletContext();


  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field

  // Success Modal

  // Proceed to confirm
  const proceed = (e) => {
    e.preventDefault();
    if (!selectUser) {
      toast.warn("User not found, input a valid reni user!");
      return;
    }
    if (
      formField.role && formField.title && formField.quantity && formField.price_unit && formField.delivery_cost && formField.description && formField.delivery_date
    ) {
      handleShowConfirmNewEscrow();
    } else {
      toast.warn("Empty Fields!");
    }
  };

  // Proceed to confirm

  // Calculate Total Amount
  const calc = () => {
    formField.amount = ((+formField.price_unit * +formField.quantity) + +formField.delivery_cost);
  };
  calc();
  useEffect(() => {
    calc();
  }, [formField.delivery_cost, formField.quantity, formField.price_unit]);
  // Calculate Total Amount


  // Update Reciepient
  function rStatus() {
    if (formField.role === 'buyer') {
      setrecipient('Seller');
    } else if (formField.role === 'seller') {
      setrecipient('Buyer');
    } else {
      setrecipient('Recipient');
    }
  }
  useEffect(() => {
    rStatus();
  }, [formField.role]);

  // Update Reciepient



  // function searchUser() {

  const [loadingName, setloadingName] = useState(false);
  const [message, setmessage] = useState('');

  function searchUser() {
    if (
      formField?.r_mail
    ) {
      setloadingName(true);
      const data = {
        usertoken,
        mail: formField?.r_mail,
      };
      FetchApi.post(`/check.Email`, data)
        .then((res) => {
          if (res.success === false) {
            setloadingName(false);
            setselectUser('');
            setformField(prevState => ({
              ...prevState,
              r_name: '',
              r_phone: '',
              usertoken: ''
            }));
            setmessage(res.message);
            setuserDisable(false);
          } else {
            setuserDisable(true);
            setloadingName(false);
            setselectUser(res.data.data);
            setformField(prevState => ({
              ...prevState,
              r_name: res.data.data.fullname,
              r_phone: res.data.data.phone,
              usertoken: res.data.data.usertoken
            }));

          }
        })
        .catch((error) => {
          setloadingName(false);
        });
    } else {
      setloadingName(false);
    }
  }

  useEffect(() => {
    searchUser();
  }, [formField.r_mail]);

  // Search User Function


  const data = { formField, recipient };

  return (
    <AppWrapper
      pagename={"Initiate Escrow"}
    >

      <StyledNewEscrow>
        <BackButton />

        <p className='grey-bold-text mt-4'>Input the information below to initiate Escrow</p>

        <Form>

          <div className="justify-content-center text-left">
            <div className="col-lg-6">
              <Label htmlFor="role">
                Your Role:
              </Label>
              <Select
                type="text"
                name="role"
                id="role"
                onChange={handleChange}
                required
              >
                <option value="">Select your role</option>
                <option value="buyer">Buyer</option>
                <option value="seller">Seller</option>
              </Select>
            </div>

            {formField.role &&
              <>
                <div className="col-lg-6 ">

                  <Label htmlFor="title">
                    Transaction Title:
                  </Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Give the transaction a title"
                    onChange={handleChange}
                    value={formField.title}
                    required
                  />

                  <Label htmlFor="r_mail">
                    {recipient}'s Email:
                  </Label>
                  {loadingName &&
                    <ClipLoader size='20' color={'var(--pry-color)'} className='mx-2' />
                  }
                  <NameInput
                    type="email"
                    name="r_mail"
                    id="r_mail"
                    placeholder={`Please Input ${ recipient }’s Email`}
                    className='mb-1'
                    onChange={handleChange}
                    value={formField.r_mail}
                    required
                  />
                  {formField.r_mail &&
                    <>
                      <br />
                      <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                        {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                        {selectUser ? selectUser?.fullname : (message || 'loading...')}
                      </UserDetail>
                    </>
                  }


                  <Label htmlFor="r_name">
                    {recipient}'s Name:
                  </Label>
                  <Input
                    type="text"
                    name="r_name"
                    id="r_name"
                    placeholder={`Please Input ${ recipient }’s Name`}
                    onChange={handleChange}
                    value={formField.r_name}
                    disabled={userDisable}
                    required
                  // value={formField.email}
                  />

                  <Label htmlFor="r_phone">
                    {recipient}’s Phone Number:
                  </Label>
                  <Input
                    type="number"
                    name="r_phone"
                    id="r_phone"
                    placeholder={`Please Input ${ recipient }’s Phone Number`}
                    onChange={handleChange}
                    value={formField.r_phone}
                    disabled={userDisable}
                    required
                  />

                  <Label htmlFor="description">
                    Description of Goods:
                  </Label><br />
                  <small className='grey-bold-text'>This will serve as your terms of contract</small>
                  <Textarea
                    type="text"
                    name="description"
                    id="description"
                    placeholder="Detailed description of goods or service"
                    onChange={handleChange}
                    value={formField.description}
                    required
                    rows='3'
                  />
                </div>
                <div className="col-lg-8 row">
                  <p className="grey-bold-text">We encourage sellers use legitimate delivery channels/outfits. We might provide options in the near future.</p>
                  <div className="col-4">
                    <Label htmlFor="quantity">
                      No of Goods:
                    </Label>
                    <Input
                      type="number"
                      name="quantity"
                      id="quantity"
                      onChange={handleChange}
                      value={formField.quantity}
                      required
                    />
                  </div>
                  <div className="col-4">
                    <Label htmlFor="price_unit">
                      Unit Price ({naira}):
                    </Label>
                    <Input
                      type="number"
                      name="price_unit"
                      id="price_unit"
                      onChange={handleChange}
                      value={formField.price_unit}
                      required
                    />
                  </div>
                  <div className="col-4">
                    <Label htmlFor="delivery_cost ">
                      Delivery Cost ({naira}):
                    </Label>
                    <Input
                      type="number"
                      name="delivery_cost"
                      id="delivery_cost"
                      onChange={handleChange}
                      value={formField.delivery_cost}
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-flex justify-content-between">
                    <Label htmlFor="amount">
                      Total Amount ({naira}):
                    </Label>
                    {' '}
                    <div className="badge badge-light">
                      fee: {charges?.local_transfer?.amount}%
                    </div>
                  </div>
                  <br />
                  <small className="grey-bold-text">
                    You will be charged a commission on this contract. You can check our commissions
                    page for more information
                  </small>
                  <Input
                    type="number"
                    name="amount"
                    id="amount"
                    placeholder="Please input item price above"
                    disabled
                    value={formField.amount}
                    required
                  />

                  <Label htmlFor="delivery_date">
                    Expected date of delivery:
                  </Label>
                  <Input
                    type="date"
                    name="delivery_date"
                    id="delivery_date"
                    onChange={handleChange}
                    value={formField.delivery_date}
                    required
                  />
                </div>

                <div className="col-lg-6">
                  <Button
                    pry
                    className="btn w-100"
                    type="submit"
                    onClick={(e) => proceed(e)}
                  >
                    Proceed
                  </Button>
                </div>
              </>
            }
          </div>
        </Form>
      </StyledNewEscrow>

      <ConfirmNewEscrow
        {...data}
      />

    </AppWrapper >
  );
};

export default NewEscrow;

const StyledNewEscrow = styled.div`

label {
  font-weight: 500;
  color: var(--dark-color);
  font-size: 18px;
  
}
`;