import { Link } from "react-router-dom";
import { logo_blue_bg, logo_white } from "../../assets";
import { StyledHomeNav } from "../../layout/userNav/Nav.styled";
import { useUserContext } from "../../context/user_context";
import { Button } from "../../styles";
import { ICON_LIST, ICON_LOGOUT, ICON_MOON, ICON_SUN } from "../../utils/icons";
import styled from "styled-components";
import { useThemeContext } from "../../context/theme_context";
import { ThemeToggle } from "../../utils/ThemeToggle";

const HomeNavbar = () => {

      const {
            usertoken
      } = useUserContext();

      const navLink = [
            { title: 'Stories', to: '#' },
            { title: 'Career', to: '#' },
            { title: 'FAQs', to: '#' },
      ];

      const {
            darkMode: { darkTheme }
      } = useThemeContext();


      return (
            <StyledHomeNav className="navbar shadow-none navbar-expand-lg fixed-to light-bg " id="navbar">
                  <div className="container">
                        <Link to="/" className="navbar-brand mt-2 mb-3 pry-text fit-content">
                              <img src={darkTheme === 'dark' ? logo_white : logo_blue_bg} width="40%" alt="Reni" loading="lazy" />
                        </Link>
                        <div className="d-flex mb-2">
                              <StyledSlider className="d-lg-none d-block ps-1 mt-2" >
                                    <ThemeToggle />
                              </StyledSlider>
                              <a
                                    className="navbar-toggler"
                                    type="button"
                                    data-mdb-toggle="collapse"
                                    data-mdb-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                              >
                                    <span className="navbar-toggler-icon toggler">
                                          <span className="h3 m-0 pry-text">
                                                {ICON_LIST}
                                          </span>
                                    </span>
                              </a>
                        </div>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                              <ul className="navbar-nav ms-auto text-center">
                                    {navLink?.map(({ title, to }, i) => {
                                          return (
                                                <li className="nav-item" key={i}>
                                                      <Link ClassName="active" to={to} key={i}>
                                                            {title}
                                                      </Link>
                                                </li>
                                          );
                                    })}
                              </ul>
                              <ul className="navbar-nav ms-auto text-center">
                                    <StyledSlider className="d-none d-lg-block me-2"
                                    >
                                          <ThemeToggle />
                                    </StyledSlider>

                                    {(usertoken === undefined || null) ? (
                                          <>
                                                <li className="mb-2">
                                                      <Link to="/login" ClassName=" pry-text">
                                                            <Button className="br-md btn pry-text">
                                                                  Login
                                                            </Button>
                                                      </Link>
                                                </li>
                                                <li className="mb-2">
                                                      <Link to="/register">
                                                            <Button pry className="br-md btn">
                                                                  Get Started
                                                            </Button>
                                                      </Link>
                                                </li>
                                          </>
                                    ) : (
                                          <>
                                                <li className="mb-2">
                                                      <Link to="/user/dashboard" ClassName=" pry-text">
                                                            <Button pry className="br-md btn">
                                                                  Dashboard
                                                            </Button>
                                                      </Link>
                                                </li>
                                                <li className="mb-2 mx-auto">
                                                      <Button
                                                            // onClick={HandleLogout}
                                                            className="br-md btn d-flex pry-text">
                                                            {ICON_LOGOUT}
                                                            Logout
                                                      </Button>
                                                </li>
                                          </>
                                    )}
                              </ul>
                        </div>
                  </div>
            </StyledHomeNav>

      );
};

export default HomeNavbar;


const StyledSlider = styled.div`

margin-top: 7px;
`;