import { Navigate } from 'react-router-dom';
import { decrypt } from '../encrypt';
import { useLocalStorage } from '../utils/useLocalStorage';

const ProtectUser = ({ children }) => {

  const [user_data] = useLocalStorage('userData');
  let userData = decrypt(user_data);


  if (!userData?.usertoken) {
    return <Navigate to="/noAccess" replace />;
  }
  return children;
};

export default ProtectUser;
