import styled from "styled-components";
import { customer } from "../../assets";

const Customer = () => {
      return (
            <StyledCustomer>
                  <div className="customer-card light-text">
                        <div className="row pry align-items-center">
                              <div className="col-md-8 customer-text py-5 ">
                                    <h1 className="mb-5">
                                          Meet the customer of
                                          the month!
                                    </h1>
                                    <p className="small col-md-7">
                                          Every month, we shine a spotlight on users and we learn about how Renitrust  is helping the them in their business and personal life. We also discover new ways to solve our customers better.
                                    </p>
                              </div>
                              <div className="col-md-4 p-0">
                                    <img src={customer} alt="Customer" width='100%' />
                              </div>
                        </div>
                  </div>
            </StyledCustomer>
      );
};

export default Customer;


const StyledCustomer = styled.section`

width: 100vw;

.customer-text {
      padding-left: 10rem;
      
      @media screen and (max-width: 925px) {
            padding-left: 5rem;
           
        
       }
      @media screen and (max-width: 580px) {
            padding-left: 2rem;
            padding-right: 2rem;
        
       }
}

`;