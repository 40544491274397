import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_EXCLAMATION } from "../../../utils/icons";
import { Button, Form, PinInput } from "../../../styles";
import SuccessTransfer from "../SuccessTransfer";
import { StyleConfirmFunds } from "../Wallet.styled";
import { useSendFundContext } from "../../../context/send_fund_context";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import AppLoader from "../../../utils/AppLoader";
import PendingTransfer from "../PendingTransfer";


const ConfirmSendToBank = (props) => {
  const [issending, setissending] = useState(false);
  const [trxPin, setTrxPin] = useState('');
  const [response, setResponse] = useState('');
  const { usertoken } = useUserContext();

  const { formField, selectedBank } = props;

  const {
    confirmBank: { showConfirmBank, handleCloseConfirmBank },
    success: { handleShowSuccess },
    pending: { handleShowPending },
  } = useSendFundContext();
  const openSuccess = () => handleShowSuccess();
  const openPending = (res) => { setResponse(res); handleShowPending(); };

  // Send Money to Bank
  function submitFn(e) {
    e.preventDefault();
    if (trxPin.length !== 4) {
      toast.warn('PIN must be 4 digits');
      return;
    }
    if (
      selectedBank.value, formField.u_name, formField.acct_no, formField.amount, formField.narration
    ) {
      setissending(true);
      const data = {

        usertoken,
        amount: formField.amount,
        r_name: formField.u_name,
        r_accountNumber: formField.acct_no,
        bankCode: selectedBank.value,
        narration: formField.narration,
        pin: trxPin,
        SessionID: formField.SessionID
      };
      Api.post(`/interbankTransfer`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setissending(false);
            if (res.data.status_code === 400) {
              openPending(res.message);
            }
          } else {
            toast.success(res.message);
            setissending(false);
            openSuccess();
          }
        })
        .catch((error) => {
          setissending(false);
        });
    } else {
      setissending(false);
      toast.warn("Empty Fields!");
    }
  }
  // Send Money to Bank

  return (
    <>
      <Modal
        show={showConfirmBank}
        onHide={handleCloseConfirmBank}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <p className="title pry-text m-0">
            CONFIRM PAYMENT
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyleConfirmFunds>
            <div className="mb-5 pb-5">
              <h1 className="yellow-text display-2">
                {ICON_EXCLAMATION}
              </h1>

              <h5 className="">
                Are you sure?
              </h5>
              <h5 className="fw-light">
                You are about to send
                <span className="fw-bold"> {naira}{th(formField.amount)}</span> to
                <span className="fw-bold"> {formField.u_name}, </span>
                {selectedBank.label} via Bank Transfer
              </h5>
            </div>

            <Form onSubmit={(e) => submitFn(e)}>

              <div className="col-md-12">
                <div className="input-group">
                  <PinInput
                    type='password'
                    name="pin"
                    placeholder="PIN"
                    onChange={(e) => setTrxPin(e.target.value)}
                    value={trxPin}
                    autoComplete="off"
                    maxLength='4'
                    required
                  />
                </div>
              </div>



              <Button
                pry
                type="submit"
                disabled={issending}
                className="btn w-100"
              >
                {issending ? 'loading...' : "Proceed"}
              </Button>
            </Form>


          </StyleConfirmFunds>
        </Modal.Body>
      </Modal>

      <SuccessTransfer
        title={'Transfer'}
        data={formField}
      />
      <PendingTransfer
        response={response}
        data={formField}
      />
      <AppLoader
        load={issending}
      />


    </>
  );
};

export default ConfirmSendToBank;


