import React from 'react';
import Router from '../router/router';
import { useThemeContext } from '../context/theme_context';

function App() {

	const {
		darkMode: { darkTheme }
	} = useThemeContext();

	return (
		<div data-theme={darkTheme}>
			<Router />
		</div>
	);
}
export default App;
