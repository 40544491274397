import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { Input } from "../../../styles";
import { useState } from "react";
import { useUserContext } from "../../../context/user_context";
import AppLoader from "../../../utils/AppLoader";


const AuthorizeForm = ({ auth, otp, setOtp, pin, setPin, phone, setPhone, avs, handleChange, }) => {

      const [issending, setissending] = useState(false);

      const { usertoken } = useUserContext();

      const [countdown, setCountdown] = useState(0);

      function countItDown() {
            setCountdown(60);
            const interval = setInterval(() => {
                  setCountdown((prevCountdown) => {
                        if (prevCountdown === 1) {
                              clearInterval(interval);
                              return 0;
                        } else {
                              return prevCountdown - 1;
                        }
                  });
            }, 1000);
            return () => {
                  clearInterval(interval);
            };
      }


      function resendOTP(e) {
            if (
                  usertoken
            ) {
                  const data = {
                        usertoken,
                        transaction_reference: auth?.transaction_reference,
                  };
                  setissending(true);
                  Api.post(`/pay.Card.ResendOTP`, data)
                        .then((res) => {
                              if (res.success === false) {
                                    toast.warn(res.message);
                                    setissending(false);
                                    countItDown();
                              } else {
                                    countItDown();
                                    toast.success(res.message);
                                    setissending(false);
                              }
                        })
                        .catch((error) => {
                              setissending(false);
                        });
            } else {
                  setissending(false);
                  toast.warn("Empty Fields!");
            }
      }

      return (

            <>
                  <AppLoader
                        load={issending}
                  />

                  {!auth?.auth_model &&
                        <div className="my-5">
                              <p className="">
                                    An error occured, please try again later!
                              </p>
                        </div>
                  }

                  {/* PIN AUTHORIZATION */}
                  {auth?.auth_model === 'PIN' &&
                        <>
                              <div className="row justify-content-center">
                                    <div className="col-md-10 mt-5">
                                          <Input
                                                type="text"
                                                name="card"
                                                placeholder="Enter PIN"
                                                onChange={(e) => setPin(e.target.value)}
                                                value={pin} />
                                    </div>
                              </div>
                        </>
                  }
                  {/* PIN AUTHORIZATION */}

                  {/* OTP AUTHORIZATION */}
                  {auth?.auth_model === 'OTP' &&
                        <>

                              <div className="row justify-content-center">

                                    <div className="col-md-10 mt-5">
                                          {countdown > 0 ?
                                                <p className="grey-bold-text pointer text-end fw-bold"
                                                >
                                                      Resend after {countdown} seconds
                                                </p>
                                                :
                                                <p className="pry-text pointer text-end fw-bold"
                                                      onClick={() => resendOTP()}
                                                >
                                                      Resend OTP
                                                </p>

                                          }
                                          <Input
                                                type="text"
                                                name="card"
                                                placeholder="Enter OTP"
                                                onChange={(e) => setOtp(e.target.value)}
                                                value={otp} />
                                    </div>

                              </div>
                        </>
                  }
                  {/* OTP AUTHORIZATION */}

                  {/* CARD_ENROLL AUTHORIZATION */}
                  {auth?.auth_model === 'CARD_ENROLL' &&
                        <>
                              <div className="row justify-content-center">
                                    <div className="col-md-10 mt-5">
                                          <Input
                                                type="text"
                                                name="number"
                                                placeholder="Phone number (08012345678)"
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone} />
                                    </div>

                              </div>
                        </>
                  }
                  {/* CARD_ENROLL AUTHORIZATION */}

                  {/* CARD_ENROLL AUTHORIZATION */}
                  {auth?.auth_model === 'AVS' &&
                        <>
                              <div className="row justify-content-center">
                                    <div className="col-md-10">
                                          <Input
                                                type="text"
                                                name="state"
                                                placeholder="Enter State"
                                                onChange={handleChange}
                                                value={avs.state} />
                                    </div>
                                    <div className="col-md-10">
                                          <Input
                                                type="text"
                                                name="city"
                                                placeholder="Enter City"
                                                onChange={handleChange}
                                                value={avs.city} />
                                    </div>
                                    <div className="col-md-10">
                                          <Input
                                                type="text"
                                                name="country"
                                                placeholder="Enter Country"
                                                onChange={handleChange}
                                                value={avs.country} />
                                    </div>
                                    <div className="col-md-10">
                                          <Input
                                                type="text"
                                                name="address"
                                                placeholder="Enter Address"
                                                onChange={handleChange}
                                                value={avs.address} />
                                    </div>
                                    <div className="col-md-10">
                                          <Input
                                                type="text"
                                                name="zip_code"
                                                placeholder="Enter Zip Code"
                                                onChange={handleChange}
                                                value={avs.zip_code} />
                                    </div>

                              </div>
                        </>
                  }
                  {/* CARD_ENROLL AUTHORIZATION */}
            </>
      );
};

export default AuthorizeForm;