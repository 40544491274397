import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { ICON_HASH } from "../../../utils/icons";


const USSD = () => {
  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <SendLayout
        title={'Fund with USSD'}>
        <div className="text-center mb-5">
          <h1 className="yellow-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
            {ICON_HASH}
          </h1>
          <p>
            Tap the USSD code below to dial it.
          </p>
        </div>
        <div className="my-5">
          <h5 className="mx-auto shadow-sm fit-content p-2 br-md">
            Coming soon !!!
          </h5>
        </div>
      </SendLayout>
    </AppWrapper >
  );
};

export default USSD;
