import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_KEY } from "../../../utils/icons";
import { Button, Form } from "../../../styles";
import { StyleConfirmFunds } from "../Wallet.styled";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";
import AppLoader from "../../../utils/AppLoader";
import SuccessCard from "./SuccessCard";
import AuthorizeForm from "./AuthorizeForm";


const AuthorizeCard = (props) => {
      const [issending, setissending] = useState(false);
      const { usertoken } = useUserContext();

      const { show, onHide, auth, setAuth } = props;

      const [pin, setPin] = useState('');
      const [otp, setOtp] = useState('');
      const [phone, setPhone] = useState('');
      const [avs, setAvs] = useState({
            state: '', city: '', country: '', address: '', zip_code: ''
      });
      const handleChange = (e) => {
            setAvs((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value
            }));
      };

      const data = {
            auth, otp, setOtp, pin, setPin, phone, setPhone, avs, handleChange,
      };

      // Success Modal
      const [showSuccess, setShowSuccess] = useState(false);
      const handleCloseSuccess = () => setShowSuccess(false);
      const handleShowSuccess = () => {
            onHide();
            setShowSuccess(true);
      };

      // Authorize Card
      function submitFn(e) {
            e.preventDefault();
            var authorization = {};
            if (auth?.auth_model === 'PIN') {
                  var authorization = { pin };
            } else if (auth?.auth_model === 'OTP') {
                  var authorization = { otp };
            } else if (auth?.auth_model === 'AVS') {
                  var authorization = { avs };
            } else if (auth?.auth_model === '3DS') {
                  var authorization = {
                        redirect_url: auth?.redirect_url
                  };
            } else if (auth?.auth_model === 'CARD_ENROLL') {
                  var authorization = { phone };
            }
            if (
                  authorization
            ) {
                  const data = {
                        usertoken,
                        transaction_reference: auth?.transaction_reference,
                        authorization
                  };
                  setissending(true);
                  Api.post(`/pay.Card.Authorize`, data)
                        .then((res) => {
                              if (res.success === false) {
                                    toast.warn(res.message);
                                    setissending(false);
                              } else {
                                    if (res.data?.data?.status === "success") {
                                          toast.success(res.message);
                                          setissending(false);
                                          handleShowSuccess();
                                    } else if (res.data?.data?.status === "processing") {
                                          setAuth(res.data.data);
                                          setissending(false);
                                    }
                              }
                        })
                        .catch((error) => {
                              setissending(false);
                        });
            } else {
                  setissending(false);
                  toast.warn("Empty Fields!");
            }
      }
      // Authorize Card

      return (
            <>
                  <Modal
                        show={show}
                        onHide={onHide}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="md"
                  >
                        <Modal.Header closeButton>
                              <p className="title pry-text m-0">
                                    CONFIRM PAYMENT
                              </p>
                        </Modal.Header>
                        <Modal.Body>
                              <AppLoader
                                    load={issending}
                              />
                              <StyleConfirmFunds>
                                    <div className="mb-5 pb-5 text-center">
                                          <h1 className="text-success display-2">
                                                {ICON_KEY}
                                          </h1>
                                          <h5 className="">
                                                {auth?.auth_model} Authentication
                                          </h5>
                                          <small>
                                                {auth?.response_message}
                                          </small>
                                          <Form onSubmit={(e) => submitFn(e)}>

                                                <AuthorizeForm
                                                      {...data}
                                                />


                                                <Button
                                                      pry
                                                      disabled={issending}
                                                      className="btn w-100"
                                                >
                                                      {issending ? 'loading...' : "Proceed"}
                                                </Button>
                                          </Form>

                                    </div>


                              </StyleConfirmFunds>
                        </Modal.Body>
                  </Modal>

                  <SuccessCard
                        show={showSuccess}
                        onHide={handleCloseSuccess}
                  />
                  <AppLoader
                        load={issending}
                  />

            </>
      );
};

export default AuthorizeCard;


