import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../../api/axios';
import { ICON_EYE_FILL, ICON_EYE_SLASH_FILL, ICON_PEN } from '../../utils/icons';
import { Button, Form, Label, PasswordInput } from "../../styles";
import { PASSWORD_REGEX } from '../../utils/data';
import { useUserContext } from '../../context/user_context';

const UpdatePassword = () => {

    const [formField, setformField] = useState({});
    const [sending, setsending] = useState(false);
    const [pwordVal, setpwordVal] = useState(true);
    const [toggleShow, settoggleShow] = useState(false);

    const {
        usertoken
    } = useUserContext();

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields

    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (pwordVal) {
            toast.warn("Password must be at least 7 characters and must contain a letter, a number and a special character");
            return;
        }
        if (formField.npword !== formField.cpword) {
            toast.warn("New Password and Confirm Password does not match");
            return;
        }
        if (
            formField.cpword &&
            formField.npword
        ) {
            setsending(true);
            const data = {
                usertoken,
                opword: formField.opword,
                npword: formField.npword,
            };
            Api.post(`/changePassword`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        setsending(false);
                        clear();
                        settoggleShow(false);
                        toast.success(res.message);

                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }

    }
    function clear() {
        setformField({ opword: "", npword: "", cpword: "" });
    }
    // Submit Function

    //Show Password Function 
    const [eye, seteye] = useState(true);


    useEffect(() => {
        PASSWORD_REGEX.test(formField.npword) ? setpwordVal(false) : setpwordVal(true);
    }, [formField.npword]);
    return (
        <>

            <div className="mt-5">
                <div className="row ">
                    <div className="col-md-8">
                        <h5 className="grey-bold-text">
                            Update Password
                        </h5>
                    </div>
                    {!toggleShow &&
                        <div className="col-md-4">
                            <h6 className="pry-text fw-bold pointer"
                                onClick={() => settoggleShow(true)}>
                                Change Password {ICON_PEN}
                            </h6>
                        </div>
                    }
                </div>
                {toggleShow &&
                    <Form onSubmit={(e) => submitFn(e)}>

                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    Old Password
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 password-input">
                                <div className="input-group">
                                    <PasswordInput
                                        type={eye ? 'password' : 'text'}
                                        name="opword"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        value={formField.opword}
                                        required
                                    />
                                    <span
                                        className="toggle-eye"
                                        onClick={() => seteye(!eye)}
                                    >
                                        {eye ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}
                                    </span>

                                </div>
                            </div>


                        </div>
                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    New Password
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 password-input">
                                <div className="input-group">
                                    <PasswordInput
                                        type={eye ? 'password' : 'text'}
                                        name="npword"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        value={formField.npword}
                                        required
                                    />
                                    <span
                                        className="toggle-eye"
                                        onClick={() => seteye(!eye)}
                                    >
                                        {eye ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">
                                <Label>
                                    Confirm Password
                                </Label>
                            </div>
                            <div className="col-md-7 col-lg-7 password-input">
                                <div className="input-group">
                                    <PasswordInput
                                        type={eye ? 'password' : 'text'}
                                        name="cpword"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        value={formField.cpword}
                                        required
                                    />
                                    <span
                                        className="toggle-eye"
                                        onClick={() => seteye(!eye)}
                                    >
                                        {eye ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-start">
                            <div className="col-md-5 col-lg-2">

                            </div>
                            <div className="col-md-7 col-lg-7">
                                <Button
                                    pry
                                    type="submit"
                                    disabled={sending}
                                    className="btn m-0 w-100"  >
                                    {sending ? 'loading...' : "Save Changes"}
                                </Button>
                            </div>
                        </div>
                    </Form>
                }

            </div>
        </>
    );
};

export default UpdatePassword;