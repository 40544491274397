import { Link } from "react-router-dom";
import styled from "styled-components";
import { username } from "../../assets";
import { walletLinks } from "../../utils/data";



const WalletLinks = () => {

    return (
        <div className="row mt-4">
            {walletLinks?.map(({ title, link, img, bgColor }, i) =>
                <div className="col-md-4 mb-3" key={i}>
                    <Link to={link}>
                        <StyledLinks className="br-md h-100"
                            style={{
                                background: `url(${ img }) no-repeat right/contain`,
                                backgroundColor: `${ bgColor }`
                            }}
                        >
                            {title}
                        </StyledLinks>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default WalletLinks;

export const StyledLinks = styled.div`
    padding: 3rem 1rem 1rem;
    font-size: 1.5rem;
    color: var(--pry-color);
    font-weight: 600;
`;