import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ICON_MARK } from "../../utils/icons";
import { Button } from "../../styles";
import { StyledSuccessModal } from "../wallet/SuccessTransfer";
import { naira } from "../../utils/data";
import { shortDate } from "../../utils/helpers";


const SuccessBill = (props) => {

  const { title, type, amount, receipient, name, result } = props;
  const navigate = useNavigate();

  function proceed() {
    props.onHide();
    navigate('/user/dashboard');
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Body>
          <StyledSuccessModal className="text-center">
            <div className="my-5">
              <span className="success-icon">
                {ICON_MARK}
              </span>
              <div className="my-4">
                <h5 className="">
                  {title} Purchase Successful!
                </h5>
                <p className="grey-bold-text mx-auto">
                  Below are the details of your {title} purchase:
                </p>

                <div className="d-flex mb-2">
                  <small className="col-3 text-start">
                    Amount:
                  </small>
                  <div className="col text-end">
                    <h6 className="text-break fw-bold">
                      {naira}{amount}
                    </h6>
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <small className="col-3 text-start">
                    To:
                  </small>
                  <div className="col text-end">
                    <h6 className="text-break fw-bold">
                      {receipient} {' '}
                      ({name && name})

                    </h6>
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <small className="col-3 text-start">
                    Type:
                  </small>
                  <div className="col text-end">
                    <h6 className="text-break fw-bold">
                      {type}
                    </h6>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <small className="col-3 text-start">
                    Date:
                  </small>
                  <div className="col text-end">
                    <h6 className="text-break fw-bold">
                      {shortDate()}
                    </h6>
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <small className="col-4 text-start">
                    Reference ID:
                  </small>
                  <div className="col text-end">
                    <h6 className="text-break fw-bold">
                      {result?.reference}
                    </h6>
                  </div>
                </div>

                {title === 'Electricity' &&
                  <>
                    <div className="d-flex mb-2">
                      <small className="col-4 text-start">
                        Transaction ID:
                      </small>
                      <div className="col text-end">
                        <h6 className="text-break fw-bold">
                          {result?.data?.transId}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <small className="col-3 text-start">
                        Token:
                      </small>
                      <div className="col text-end">
                        <h6 className="text-break fw-bold">
                          {result?.data?.token}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex mb-2">
                      <small className="col-3 text-start">
                        Unit:
                      </small>
                      <div className="col text-end">
                        <h6 className="text-break fw-bold">
                          {result?.data?.unit}
                        </h6>
                      </div>
                    </div>
                  </>
                }



              </div>
            </div>

            <Button
              className="btn w-100"
              pry
              onClick={() => proceed()}
            >
              Homepage
            </Button>

          </StyledSuccessModal>

        </Modal.Body>
      </Modal>

    </>
  );
};

export default SuccessBill;

