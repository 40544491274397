import { Link } from "react-router-dom";
import styled from "styled-components";
import { ICON_EMAIL, ICON_FACEBOOK, ICON_INSTAGRAM, ICON_LINK, ICON_TWITTER } from "../../utils/icons";

const Footer = () => {
      return (
            <StyledFooter>
                  <footer className="page-footer font-small">
                        <div className="container text-center text-md-left mb-5">
                              <div className="row text-center links">

                                    <div className=" connect col-md-6 col-lg-2 mb-5 ">
                                          <h6 className="fw-bold light-text text-center mb-4">
                                                Connect with us
                                          </h6>
                                          <div className="text-center mb-3 boxicons">
                                                <a
                                                      href="https://www.facebook.com/ReniSuite/"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                >
                                                      {ICON_FACEBOOK}
                                                </a>

                                                <a href="https://www.instagram.com/ReniSuite"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      className=''>
                                                      {ICON_INSTAGRAM}
                                                </a>

                                                <a
                                                      href="https://twitter.com/ReniSuite"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                >
                                                      {ICON_TWITTER}
                                                </a>
                                                <a
                                                      href='mailto:hello@reni.tech'
                                                      target="_blank"
                                                      rel="noreferrer"
                                                >
                                                      {ICON_EMAIL}
                                                </a>
                                          </div>
                                    </div>
                                    <div className=" connect col-md-6 col-lg-2 mb-5 ">
                                          <h6 className="fw-bold light-text text-center mb-4">
                                                Our Address
                                          </h6>
                                          <div className="text-center mb-3 boxicons">
                                                <h6 className="">
                                                      No 5, Orogun, along UI Ojoo road, Ibadan, Oyo State, Nigeria.
                                                </h6>
                                          </div>
                                    </div>

                                    <div className="col-lg-2 col-md-4 mx-auto d-none d-md-block">
                                          <h6 className="fw-bold mb-4">Quick Links</h6>

                                          <ul className="list-unstyled h6">
                                                <li className='mb-3'>
                                                      <Link to="/register">Sign up</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="/login">Sign In</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="#">FAQs</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="/terms">Privacy</Link>
                                                </li>

                                          </ul>
                                    </div>
                                    <div className="col-lg-2 col-md-4 mx-auto d-none d-md-block">
                                          <h6 className="fw-bold mb-4">Resources</h6>

                                          <ul className="list-unstyled h6">
                                                <li className='mb-3'>
                                                      <Link to="/terms">Terms and Conditions</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="/terms">Privacy Policy</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="/terms">Cookie Policy</Link>
                                                </li>
                                                <li className='mb-3'>
                                                      <Link to="/#">About us</Link>
                                                </li>
                                          </ul>
                                    </div>

                                    <div className="col-lg-2 col-md-4 mx-auto d-none d-md-block products">
                                          <h6 className="fw-bold mb-4">Products</h6>

                                          <ul className="list-unstyled h6">
                                                <li className='mb-3'>
                                                      <a target='_blank' href='https://renitrust.com'>ReniTrust</a>
                                                      {ICON_LINK}

                                                </li>
                                                <li className='mb-3'>
                                                      <a target='_blank' href='https://reni.store'>ReniStore</a>
                                                      {ICON_LINK}
                                                </li>
                                                <li className='mb-3'>
                                                      <a target='_blank' href='https://reniaccounts.com'>ReniAccounts</a>
                                                      {ICON_LINK}
                                                </li>

                                                <li className='mb-3'>
                                                      <a target='_blank' href='https://renicloud.com'>ReniCloud</a>
                                                      {ICON_LINK}
                                                </li>

                                          </ul>
                                    </div>
                              </div>
                        </div>

                        <div className="footer-copyright light-text text-center py-3">
                              © 2022{" "}
                              <a href="https://renitrust.com" className="light-text text-decoration-underline">               {" "}
                                    renitrust.com
                              </a>{" "}
                              || All rights reserved

                              <hr className="my-1 mx-auto w-50" />

                              <div style={{ fontSize: "smaller" }}>
                                    A product of {' '}
                                    <a href="https://fireswitch.tech" className="light-text text-decoration-underline">
                                          FireSwitch Technologies Ltd{" "}
                                    </a>
                              </div>
                        </div>
                  </footer>
            </StyledFooter>
      );
};

export default Footer;


const StyledFooter = styled.section`
      margin: 0;
  padding-top: 5rem;
  background: var(--home-pry);
  background-size: cover;
  width: 100%;
  overflow-x: hidden;

  .links, .links a{
      color: var(--light-color);

  }
.products svg{
      margin-left: .3rem;
      margin-bottom: .3rem;
}

 .connect svg {
      font-size:25px;
      margin: 0 5px;
  color: var(--grey-color) !important;

}
 .connect svg:hover {
  color: var(--light-color) !important;

}

`;