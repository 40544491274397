import { Modal } from "react-bootstrap";
import { naira } from "../../utils/data";
import EmptyData from "../EmptyData";
import { useUserContext } from "../../context/user_context";




const ReferralTrx = (props) => {

      const { onShow, onHide } = props;

      const { referrals } = useUserContext();

      return (
            <>
                  <Modal
                        show={onShow}
                        onHide={onHide}
                        keyboard={false}
                        centered
                        size="lg"
                  >
                        <Modal.Header closeButton className="pry-text">
                              REFERRAL EARNINGS
                        </Modal.Header>
                        <Modal.Body >
                              <div className="border br-md card-body py-2">
                                    <div className="row">
                                          <div className="col-6">
                                                <small className="grey-bold-text">
                                                      Total Earned
                                                </small>
                                                <h6 className="fw-bold">{naira} 0.00  </h6>
                                          </div>
                                          <div className="col-6">
                                                <small className="grey-bold-text">
                                                      Completed
                                                </small>
                                                <h6 className="fw-bold">{naira} 0.00  </h6>
                                          </div>
                                          <div className="col-6">
                                                <small className="grey-bold-text">
                                                      Pending
                                                </small>
                                                <h6 className="fw-bold">{naira} 0.00  </h6>
                                          </div>
                                    </div>
                              </div>
                              <div className="border br-md card mt-3">
                                    <div className="card-header small">
                                          Your Invitees
                                    </div>
                                    <div className="card-body small pt-2">

                                          <EmptyData
                                                title='any Referral'
                                                data={referrals}
                                          />

                                          {referrals?.map(({ fullname, real_time, mail, id }, i) =>
                                                <>
                                                      <div className="d-flex justify-content-between" key={id}>
                                                            <div className="d-flex">
                                                                  <h6 className="m-0 pe-1 pt-2 text-uppercase">
                                                                        {1 + i}.
                                                                  </h6>
                                                                  <div className="">
                                                                        <h6 className="m-0 pt-2 text-uppercase">
                                                                              {fullname}
                                                                        </h6>
                                                                        <small className="grey-bold-text">
                                                                              {mail}
                                                                        </small>
                                                                  </div>
                                                            </div>
                                                            <div className="">
                                                                  {real_time}
                                                            </div>
                                                      </div>
                                                      <hr className="m-0 mt-1" />

                                                </>
                                          )
                                          }

                                    </div>

                              </div>
                        </Modal.Body>

                  </Modal>
            </>
      );
};

export default ReferralTrx;