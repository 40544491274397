import styled from "styled-components";


export const StyledBill = styled.div`
gap: 15px;
display:flex ;
flex-direction: column;


h6{
font-weight: 600;
font-size: 25px;
color: #1560BD;
}
svg{
font-size: 50px;
color: #1560BD;

}


`;


export const StyledPayBillPage = styled.div`

    form {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
.input-col {

    background-color: var(--sec-color);
    padding: 1rem;
    border-radius: 10px;

    label {
    font-weight: 400;
    font-size: 17px !important;
    line-height: 26px;
    letter-spacing: 0.07em;
    color: var(--dark-color);
    }

    .input-style {
    width: 100%;
    padding: 12px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-color);
    background-color: transparent;
    
       :hover,:focus{
       border: 0!important;
       border-bottom: 1px solid var(--pry-color) !important;
     
        }
        ::placeholder{
       font-size: 15px;
       font-weight: 400;    
        } 

        :disabled{
        color: var(--grey-bold-color);
    } 
    }

}
   
/* Focus and Out of Focus */
.css-t3ipsp-control, .css-13cymwt-control {
    width: 100%;
    padding: 6px 1px;
    margin: 8px 0;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-color) !important;
    background-color: transparent;
    text-transform: uppercase;
    
       :hover,:focus{
       border: 0!important;
       border-bottom: 1px solid var(--pry-color) !important;
     
        }
        ::placeholder{
       font-size: 15px;
       font-weight: 400;    
        } 
    }

/* Disabled */
.css-16xfy0z-control {   
     width: 100%;
    padding: 12px 1px;
    margin: 8px 0;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    background-color: transparent;
    text-transform: uppercase;
    
       :hover,:focus{
       border: 0!important;
       border-bottom: 1px solid var(--pry-color) !important;
     
        }
        ::placeholder{
       font-size: 15px;
       font-weight: 400;    
        } 
    }

    /* Menu List */
    .css-1nmdiq5-menu {
    padding: 12px 1px;
    margin: 8px 0;
    font-size: 12px;
    font-weight: 600;
    color: var(--dark-color);
    text-transform: uppercase;

    }

`;

export const StyledConfirmPayBill = styled.div`
 
   button{
    margin: 10px 0 !important;
  }
`;