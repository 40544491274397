import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../api/axios";
import { useSendFundContext } from "../../../context/send_fund_context";
import { useUserContext } from "../../../context/user_context";
import { Button } from "../../../styles";
import AppLoader from "../../../utils/AppLoader";
import { naira } from "../../../utils/data";
import { th } from "../../../utils/helpers";
import { ICON_EXCLAMATION } from "../../../utils/icons";
import SuccessTransfer from "../SuccessTransfer";
import { StyleConfirmFunds } from "../Wallet.styled";


const ClaimQrFunds = (props) => {
    const [issending, setissending] = useState(false);
    const { usertoken } = useUserContext();

    const { fullname: u_name, amount, sendid } = props;
    const data = { u_name, amount };
    const {
        claimQrFund: { showClaimQrFund, handleCloseClaimQrFund },
        success: { handleShowSuccess },
    } = useSendFundContext();


    const openSuccess = () => handleShowSuccess();
    let navigate = useNavigate();

    // cLAIM MONEY FROM QR
    function submitFn() {
        if (sendid) {
            setissending(true);
            const data = {
                usertoken,
                sendid
            };
            Api.post(`/claimSendToQRCode`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setissending(false);
                    } else {
                        toast.success(res.message);
                        setissending(false);
                        openSuccess();
                    }
                })
                .catch((error) => {
                    setissending(false);
                });
        } else {
            setissending(false);
            toast.warn("Not completed, Try Again!");
        }
    }
    // cLAIM MONEY FROM QR

    function closeModal() {
        handleCloseClaimQrFund();
        navigate(-1);
    }


    return (
        <>
            <Modal
                show={showClaimQrFund}
                onHide={handleCloseClaimQrFund}
                backdrop="static"
                keyboard={false}
                centered
                size="md"
            >
                <Modal.Header>
                    <p className="title pry-text m-0">
                        CLAIM MONEY
                    </p>
                </Modal.Header>
                <Modal.Body>
                    <StyleConfirmFunds>
                        <div className="mb-5 pb-5">
                            <h1 className="yellow-text display-2">
                                {ICON_EXCLAMATION}
                            </h1>
                            <h5 className="">
                                Are you sure?
                            </h5>
                            <h5 className="fw-light">
                                You are about to claim
                                <span className="fw-bold"> {naira}{th(amount)}</span> from
                                <span className="fw-bold"> {u_name}</span> via QR send money.
                            </h5>
                        </div>
                        <div className="d-flex">
                            <Button
                                pry
                                disabled={issending}
                                className="btn w-50"
                                onClick={() => submitFn()}
                            >
                                {issending ? 'loading...' : "Claim"}
                            </Button>
                            <Button
                                className="btn w-50"
                                onClick={() => closeModal()}
                            >
                                Cancel
                            </Button>
                        </div>

                    </StyleConfirmFunds>

                </Modal.Body>
            </Modal>
            <SuccessTransfer
                title={'Claim'}
                data={data}
            />
            <AppLoader
                load={issending}
            />
        </>
    );
};

export default ClaimQrFunds;