import { Modal } from "react-bootstrap";
import { Button, Form, Label, Textarea, Input } from "../../styles";
import { useState } from "react";
import { toast } from "react-toastify";
import { placeholder } from "../../assets";
import { ICON_X } from "../../utils/icons";
import { naira } from "../../utils/data";
import { StyledNewPayLink } from "./NewPayLink";
import Api from "../../api/axios";
import { usePayLinkContext } from "../../context/paylink_context";
import { useUserContext } from "../../context/user_context";
import AppLoader from "../../utils/AppLoader";
import { useEffect } from "react";
import { convertPhoto } from "../../utils/helpers";

const EditPayLink = (props) => {


      const { onShow, onHide, name, description, amount, image, redirect_url, realTime, token } = props;

      const [sending, setsending] = useState(false);
      const [formField, setformField] = useState({
            title: '', description: '', photo: '', redirect_link: '', amount: ''
      });

      useEffect(() => {
            if (props) {
                  setformField({
                        title: name, description, amount, photo: image, redirect_link: redirect_url
                  });
            }
      }, [props]);

      const {
            payLink: { getPayLinkData },
      } = usePayLinkContext();
      const { usertoken } = useUserContext();


      // Form Fields
      function handleChange(e) {
            setformField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value,
            }));
      }
      // Form Field

      // Submit Function
      function submitFn(e) {
            e.preventDefault();
            if (
                  formField.title && formField.amount && formField.description
                  && formField.photo
            ) {
                  setsending(true);
                  const data = {
                        usertoken,
                        name: formField.title,
                        amount: formField.amount,
                        desc: formField.description,
                        redirect_url: formField.redirect_link,
                        image: formField.photo,
                        token
                  };


                  Api.post(`/paymentLink.edit`, data)
                        .then((res) => {
                              if (res.success === false) {
                                    toast.warn(res.message);
                                    setsending(false);
                              } else {
                                    setsending(false);
                                    getPayLinkData(token);
                                    onHide();
                                    toast.success(res.message);
                              }
                        })
                        .catch((error) => {
                              setsending(false);
                        });
            } else {
                  setsending(false);
                  toast.warn("Empty Fields!");
            }
      }
      // Submit Function

      return (
            <>
                  <AppLoader
                        load={sending}
                  />
                  <Modal
                        show={onShow}
                        onHide={onHide}
                        keyboard={false}
                        centered
                        size="lg"
                  >
                        <Modal.Header closeButton className="pry-text">
                              EDIT PAYMENT LINK
                        </Modal.Header>
                        <Modal.Body >
                              <StyledNewPayLink>
                                    <p className='grey-bold-text mt-4'>Input the information below to edit this payment link</p>

                                    <Form onSubmit={(e) => submitFn(e)}>

                                          <Label htmlFor="title">
                                                Payment Link Title:
                                          </Label>
                                          <Input
                                                type="text"
                                                name="title"
                                                id="title"
                                                placeholder="Title"
                                                onChange={handleChange}
                                                value={formField.title}
                                                required
                                          />

                                          <Label htmlFor="description">
                                                Description:
                                          </Label><br />
                                          <Textarea
                                                type="text"
                                                name="description"
                                                id="description"
                                                placeholder="Description"
                                                onChange={handleChange}
                                                value={formField.description}
                                                required
                                                rows='3'
                                          />

                                          <Label htmlFor="amount">
                                                Cover Photo:
                                          </Label>
                                          <div className="col d-flex mx-auto justify-content-center mt-2 mb-4">
                                                <div class="file-upload">
                                                      <input
                                                            accept="image/*"
                                                            type="file"
                                                            name='photo'
                                                            onChange={(e) => convertPhoto(e, setformField)}
                                                            className="w-100 h-100"
                                                      />
                                                      <div className="w-100" >
                                                            {formField?.photo ?
                                                                  <div className="cover-container">
                                                                        <img src={formField?.photo}
                                                                              className="cover"
                                                                              alt="cover " />
                                                                  </div>
                                                                  :
                                                                  <img src={placeholder} alt="cover " />
                                                            }
                                                            {formField?.photo &&
                                                                  <div className="cancel"
                                                                        onClick={() => setformField(prev => ({ ...prev, photo: '' }))}
                                                                  >
                                                                        {ICON_X}
                                                                  </div>
                                                            }
                                                      </div>
                                                </div>
                                          </div>




                                          <Label htmlFor="amount">
                                                Amount ({naira}):
                                          </Label>
                                          <Input
                                                type="number"
                                                name="amount"
                                                id="amount"
                                                onChange={handleChange}
                                                value={formField.amount}
                                                required
                                          />
                                          <Label htmlFor="title">
                                                Redirect Link:
                                          </Label>
                                          <Input
                                                type="text"
                                                name="redirect_link"
                                                id="redirect_link"
                                                placeholder="redirect link (optional)"
                                                onChange={handleChange}
                                                value={formField.redirect_link}

                                          />
                                          <div className="col-lg-8 row">
                                                <div className="col-lg-6">
                                                      <Button
                                                            pry
                                                            className="btn w-100"
                                                            type="submit"
                                                      >
                                                            Submit
                                                      </Button>
                                                </div>
                                          </div>
                                    </Form>
                              </StyledNewPayLink>
                        </Modal.Body>

                  </Modal>
            </>
      );
};

export default EditPayLink;