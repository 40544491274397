import styled from "styled-components";
import AppWrapper from "../../layout/AppWrapper";
import BackButton from "../BackButton";
import { Button, Form, Label, Textarea, Input } from "../../styles";
import { useEffect, useState } from "react";
import { URL_REGEX, naira } from "../../utils/data";
import { toast } from "react-toastify";
import { ICON_X } from "../../utils/icons";
import { placeholder } from "../../assets";
import ConfirmNewPayLink from "./ConfirmNewPayLink.js";
import { usePayLinkContext } from "../../context/paylink_context";
import { convertPhoto } from "../../utils/helpers";



const NewPayLink = () => {

      const [formField, setformField] = useState({
            title: '', description: '', amount: '', photo: '', redirect_link: ''
      });
      const [urlVal, seturlVal] = useState(false);

      const {
            confirmNewPayLink: { handleShowConfirmNewPayLink },
      } = usePayLinkContext();


      // Form Fields
      function handleChange(e) {
            setformField((inputs) => ({
                  ...inputs,
                  [e.target.name]: e.target.value,
            }));
      }
      // Form Field

      useEffect(() => {
            URL_REGEX.test(formField.redirect_link) ? seturlVal(true) : seturlVal(false);
      }, [formField.redirect_link]);

      // Proceed to confirm
      const proceed = (e) => {
            e.preventDefault();
            if (formField.redirect_link) {
                  if (!urlVal) {
                        toast.warn('URL must include https://');
                        return;
                  }
            }
            if (
                  formField.title && formField.amount && formField.description && formField.photo
            ) {
                  handleShowConfirmNewPayLink();
            } else {
                  toast.warn("Empty Fields!");
            }
      };

      // Proceed to confirm

      const data = { formField };

      return (
            <AppWrapper
                  pagename={"Payment Link"}
            >
                  <StyledNewPayLink>
                        <div className="col-md-10 col-lg-8 light-bg border py-4 px-3 br-md">
                              <BackButton />

                              <h5 className='mt-4'>New payment links</h5>

                              <Form onSubmit={(e) => proceed(e)}>



                                    <Label htmlFor="title">
                                          Payment Link Title:
                                    </Label>
                                    <Input
                                          type="text"
                                          name="title"
                                          id="title"
                                          placeholder=" Title"
                                          onChange={handleChange}
                                          value={formField.title}
                                          required
                                    />

                                    <Label htmlFor="description">
                                          Description:
                                    </Label><br />
                                    <Textarea
                                          type="text"
                                          name="description"
                                          id="description"
                                          placeholder="Description"
                                          onChange={handleChange}
                                          value={formField.description}
                                          required
                                          rows='3'
                                    />

                                    <Label htmlFor="amount">
                                          Cover Photo:
                                    </Label>
                                    <div className="col d-flex mx-auto justify-content-center mt-2 mb-4">
                                          <div class="file-upload">
                                                <input
                                                      accept="image/*"
                                                      type="file"
                                                      name='photo'
                                                      onChange={(e) => convertPhoto(e, setformField)}
                                                      className="w-100 h-100"
                                                />
                                                <div className="w-100" >
                                                      {formField?.photo ?
                                                            <div className="cover-container">
                                                                  <img src={formField?.photo}
                                                                        className="cover"
                                                                        alt="cover " />
                                                            </div>
                                                            :
                                                            <img src={placeholder} alt="cover " />
                                                      }
                                                      {formField?.photo &&
                                                            <div className="cancel"
                                                                  onClick={() => setformField(prev => ({ ...prev, photo: '' }))}
                                                            >
                                                                  {ICON_X}
                                                            </div>
                                                      }
                                                </div>
                                          </div>
                                    </div>




                                    <Label htmlFor="amount">
                                          Amount ({naira}):
                                    </Label>
                                    <Input
                                          type="number"
                                          name="amount"
                                          id="amount"
                                          onChange={handleChange}
                                          value={formField.amount}
                                          required
                                    />
                                    <Label htmlFor="title">
                                          Redirect Link:
                                    </Label>
                                    <Input
                                          type="text"
                                          name="redirect_link"
                                          id="redirect_link"
                                          placeholder="https://example.com"
                                          onChange={handleChange}
                                          value={formField.redirect_link}

                                    />
                                    <div className="col-lg-8 row">

                                          <div className="col-lg-6">
                                                <Button
                                                      pry
                                                      className="btn w-100"
                                                      type="submit"
                                                >
                                                      Proceed
                                                </Button>
                                          </div>
                                    </div>
                              </Form>
                        </div>
                  </StyledNewPayLink>

                  <ConfirmNewPayLink
                        {...data}
                  />
            </AppWrapper>
      );
};

export default NewPayLink;


export const StyledNewPayLink = styled.div`


      label {
            font-size: 14px;
      }
      
   .file-upload {
        position: relative;

        input[type="file"] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
        
        img {
            width: 100%;
        }
    
        .cancel {
          position:absolute;
          top:0;
          right: 0;
    
          svg {
                font-size: 25px;
                background-color: #ffffff90;
                border-radius: 50%;
                margin:.3rem;
          }
    
        }
  
        .cover-container{
              position: relative;
              width:100%;
              height:300px;
              img.cover {
                    border-radius: 1rem;
                    width: 100%;
                    height:100%;
                    margin:auto;
                    object-fit:cover;
              }
        }
      }

      
      
            `;