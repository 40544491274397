import React, { useState, useEffect } from 'react';

import ProtectUser from '../app/protectUser';
import ContactSupport from '../components/ContactSupport';
import Navbar from '../layout/userNav/Navbar';
import { DashboardWrapper } from '../styles';
import MainAlert from '../utils/MainAlert';
import { useUserContext } from '../context/user_context';
import AddBVN from '../components/dashboard/AddBVN';

const AppWrapper = ({ children, pagename }) => {

    const {
        userData
    } = useUserContext();


    const [isBVNAdded, setIsBVNAdded] = useState((userData?.bvn && userData?.isBVN));


    const [showBVN, setShowBVN] = useState(false);
    const handleCloseBVN = () => setShowBVN(false);
    const handleShowBVN = () => setShowBVN(true);


    useEffect(() => {
        if (isBVNAdded) {
            handleCloseBVN();
        } else {
            handleShowBVN();
            // setIsBVNAdded(false);
        }
    }, [isBVNAdded]);

    return (

        <main>
            <ProtectUser>
                <div className="dark-text">
                    <Navbar
                        pagename={pagename}
                    />
                    <DashboardWrapper>
                        <div className='container-fluid'>
                            <MainAlert />
                            {children}
                        </div>
                        <ContactSupport />
                        <AddBVN
                            show={showBVN}
                            onHide={handleCloseBVN}
                            setIsBVNAdded={setIsBVNAdded}
                        />
                    </DashboardWrapper>

                </div>
            </ProtectUser>
        </main>
    );
};

export default AppWrapper;