import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ICON_MARK } from "../../utils/icons";
import { Button } from "../../styles";


const SuccessfulEscrow = (props) => {
  const navigate = useNavigate();

  function proceed() {
    navigate(
      `/user/escrow/${ props.id }`,
    );
  }

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
      >
        <Modal.Body>
          <StyledSuccessfulEscrow className="text-center">
            {ICON_MARK}
            <h5 className="my-4">
              Escrow Successful!
            </h5>
            <Link to='/user/dashboard'>
              <Button
                className="btn"
                pry>
                Homepage
              </Button>
            </Link>
            <Button
              className="btn"
              light
              onClick={proceed}>
              Proceed
            </Button>
          </StyledSuccessfulEscrow>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default SuccessfulEscrow;

const StyledSuccessfulEscrow = styled.div`
  
  svg {
    font-size: 50px;
    padding:7px;
    background-color: var(--pry-color);
    color: var(--light-color);
    border-radius: 50%;
  }
  button{
    margin: 3px !important;
  }
`;
