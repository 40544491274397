import { Button } from '../../styles';
import styled from 'styled-components';
import { ICON_PLANE, ICON_PLUS } from '../../utils/icons';
import { Link } from 'react-router-dom';
import { useWalletContext } from '../../context/wallet_context';
import { naira } from '../../utils/data';


const DashboardHeader = () => {
    const {
        getAcctBal: { acctBal }
    } = useWalletContext();

    return (
        <StyledDashboardHeader>
            <Link to='/user/wallet/fund'>
                <Button className='btn w-100 br-md' pry>
                    {ICON_PLUS} Add Money
                </Button>
            </Link>
            <div className="row">
                <div className="col-sm-6">
                    <EscrowBalance>
                        <div className="col-2 sec me-2 d-flex justify-content-center align-items-center br-sm">
                            <span className="paper-plane green-bg ">
                                {ICON_PLANE}
                            </span>
                        </div>
                        <div className="col mt-2">
                            <p className='text-success'>
                                Available Balance
                            </p>
                            <h4 className='balance text-break'>
                                {naira}{acctBal?.WithdrawableBalance_th || '0.00'}
                            </h4>
                        </div>
                    </EscrowBalance>
                </div>
                <div className="col-sm-6">
                    <EscrowBalance>
                        <div className="col-2 sec me-2 d-flex justify-content-center align-items-center br-sm">
                            <span className="paper-plane red-bg ">
                                {ICON_PLANE}
                            </span>
                        </div>
                        <div className="col mt-2">
                            <p className='red-text'>
                                Ledger Balance
                            </p>
                            <h4 className='balance text-break'>
                                {naira}{acctBal?.LedgerBalance_th || '0.00'}
                            </h4>
                        </div>
                    </EscrowBalance>
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

export default DashboardHeader;



export const StyledDashboardHeader = styled.div`
button {
    height: 116px;
    font-size: 15px;

    svg {
        font-size: 30px;
    }
}
`;

export const EscrowBalance = styled.div`
  margin-top:2rem;
  display: flex;

  p{
    font-size: 10px;
    margin:0;
  }

  .paper-plane {
    border-radius:50%;
    color:var(--light-color);
    display: flex;
    justify-content:center;
    align-items: center;
    height:30px;
    width:30px;
  }

  .green-bg {
    background-color:#498F00;
  }
  .text-success {
    color:#498F00;
  }

.balance {
    /* font-size:2vw; */
}
`;
