import { limit, naira } from "../../utils/data";
import { tier1, tier2, tier3 } from "../../assets";


const AccountLimits = () => {


    return (
        <>
            <div className="mt-5">
                <div className="row ">
                    <div className="col-md-8">
                        <div className="">
                            <div className=' sec br-md py-4 px-3 mb-4 d-flex justify-content-between'>
                                <h5 className="m-0">
                                    Account Tier
                                </h5>
                                <div className="d-flex">
                                    <img src={tier1} alt="Tier" width='25px' />
                                    <p className="fw-bold m-0">
                                        Tier1
                                    </p>
                                </div>
                            </div>

                        </div>
                        {limit?.map(({ logo, name, min, max, tier }) =>
                            <div className={`${ tier ? 'pry-border' : 'border' } br-md py-4 px-3 mb-4`} key={name}>
                                <div className="d-flex align-items-center">
                                    <img src={logo} alt={name} width='25px' />
                                    <h5 className="m-0">
                                        {name}
                                    </h5>
                                </div>
                                <hr />
                                <div className="">
                                    <div className="d-flex justify-content-between">
                                        <p className="small">
                                            Daily transaction limit
                                        </p>
                                        <p className="small fw-bold">
                                            {naira}{min}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="small">
                                            Maximum account balance
                                        </p>
                                        <p className="small fw-bold">
                                            {name !== 'Tier 3' && naira}{max}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountLimits;