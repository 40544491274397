import BackButton from "../components/BackButton";
import { StyledPayBillPage } from "../components/bills/Bill.styled";
import AppLoader from "../utils/AppLoader";
import AppWrapper from "./AppWrapper";


const BillWrapper = ({ children, title, load }) => {

    return (
        <AppWrapper
            pagename={title}
        >
            <BackButton />
            <AppLoader
                load={load}
            />
            <StyledPayBillPage>
                {children}
            </StyledPayBillPage>
        </AppWrapper>
    );
};

export default BillWrapper;