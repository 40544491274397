
import { useState } from 'react';
import { StyledProfile } from '../../components/Profile/Profile.styled';
import SecuritySettings from '../../components/settings/SecuritySettings';
import AppWrapper from '../../layout/AppWrapper';
import { StyledTab } from '../../styles';
import KycSettings from '../../components/settings/KycSettings';
import AccountLimits from '../../components/settings/AccountLimits';

const Settings = () => {
  const [selectedLink, setselectedLink] = useState('security');


  function selectData(e) {
    if (e === 'KYC') {
      setselectedLink('KYC');
    } else if (e === 'limits') {
      setselectedLink('limits');

    } else {
      setselectedLink('security');
    }
  }

  const links = ['security', 'KYC', 'limits'];

  return (
    <AppWrapper
      pagename={"Settings"}
    >

      <StyledProfile>
        <StyledTab className=''>
          {links.map((e, i) =>
            <div className={`links ${ e === selectedLink && 'active' }`}
              key={i}
              onClick={() => selectData(e)}
            >{e}</div>
          )}
        </StyledTab>

        <div className="my-4">
          {selectedLink === 'KYC' ?
            <KycSettings /> :
            selectedLink === 'limits' ?
              <AccountLimits /> :
              <SecuritySettings />
          }

        </div>

      </StyledProfile>
    </AppWrapper>
  );
};

export default Settings;