import { Link } from 'react-router-dom';
import { gift } from '../../assets';
import { ICON_MONEY_BILL, ICON_SHARE } from '../../utils/icons';
import { StyledReferral } from '../../components/referral/Referral.styled';
import AppWrapper from '../../layout/AppWrapper';
import { Button, Form, Input, Label } from '../../styles';
import AppLoader from '../../utils/AppLoader';
import Api from '../../api/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useUserContext } from '../../context/user_context';
import { shareItem } from '../../utils/helpers';
import { naira } from '../../utils/data';
import ReferralTrx from '../../components/referral/ReferralTrx';

const Referral = () => {

  const [mail, setmail] = useState('');
  const [sending, setsending] = useState(false);
  const { usertoken, userData } = useUserContext();
  const { ref, fullname } = userData || {};


  // Send Invites
  function submitFn(e) {
    e.preventDefault();
    if (mail) {
      setsending(true);
      const data = {
        usertoken, mail
      };
      Api.post(`/sendInvite`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setsending(false);
          } else {
            toast.success(res.message);
            setsending(false);
            setmail('');
          }
        })
        .catch((error) => {
          setsending(false);
        });
    } else {
      setsending(false);
      toast.warn("Input mail!");
    }
  }
  // Send Invites


  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (file) => setShow(true);;
  // Modal


  return (
    <AppWrapper
      pagename={"Referral"}
    >
      <AppLoader
        load={sending}
      />
      <StyledReferral>
        <div className="pry-text pointer fw-bold d-flex align-items-center fit-content mx-auto"
          onClick={() => handleShow()}
        >
          {ICON_MONEY_BILL}
          <h6 className='m-0 ps-1'>
            Check Your Referral Earnings
          </h6>
        </div>
        <div className="container col-md-8 mx-auto">
          <img src={gift} alt="Referral" width='20%' />

          <div className="my-4">
            <h5>
              Invite a friend to Renitrust and you will both get {naira}500
            </h5>
            <p className="">
              Know someone curious about escrow? You’ll both receive {naira}500 when they initiate a successful contract. <Link to='#'> Learn more </Link>
            </p>

            <Form onSubmit={(e) => submitFn(e)}>
              <div className="row align-items-center">
                <div className="col-md-8">
                  <Input
                    type="email"
                    name="mail"
                    placeholder='Enter email address'
                    onChange={(e) => setmail(e.target.value)}
                    value={mail}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <Button
                    type='submit'
                    pry className='btn'>
                    Send Invites
                  </Button>
                </div>
              </div>
            </Form>

            <div className="text-center mb-3">
              <Label className='dark-text'>
                Share invite link
              </Label>
            </div>
            <div className="row align-items-center ">
              <div className="col-md-8">
                <div className="light-bold-bg br-sm py-3 px-3 fw-bold small mb-2 h6-md text-break">
                  renitrust.com/ref/{ref || 'null'}
                </div>
              </div>
              <div className="col-md-4">
                <Button pry className='btn'
                  onClick={() => shareItem({
                    title: 'Referral Invite',
                    text: `Hi there, ${ fullname } has invited you to try out ReniTrust. Register with the link below or with this Referral Code: ${ ref }`,
                    url: `https://renitrust.com/ref/${ ref }`
                  })}
                >
                  {ICON_SHARE}{' '}
                  Share
                </Button>
              </div>
            </div>
          </div>
        </div>

      </StyledReferral>

      <ReferralTrx
        onShow={show}
        onHide={handleClose}
      />

    </AppWrapper>
  );
};

export default Referral;