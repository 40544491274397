import styled from "styled-components";
import AppWrapper from "../../layout/AppWrapper";
import AllNotifications from "../../components/Notifications/AllNotifications";
import AllRequest from "../../components/Notifications/AllRequest";
import { useState } from "react";
import { useUserContext } from "../../context/user_context";

const Notification = () => {

    const [tabLink, settabLink] = useState('notification');

    function switchTab(e) {
        if (e === 'notification') {
            settabLink('notification');
        } else {
            settabLink('request');
        }
    }

    const {
        stats
    } = useUserContext();

    const pendingRequests = stats?.pendingRequests || '0';
    const unreadNotes = stats?.unreadNotes || '0';

    return (
        <AppWrapper
            pagename='Notification'
        >
            <StyledNotification>

                <div className="d-flex fit-content shadow-sm br-sm grey-bg py-2 mx-auto">
                    <p
                        onClick={() => switchTab('notification')}
                        className={`pointer br-sm px-3 m-0 mx-2 ${ tabLink === 'notification' ? 'light-bg' : '' }`}>
                        Notifications
                        <span className="pry light-text px-1 ms-1 br-md small">
                            {unreadNotes}
                        </span>
                    </p>
                    <p
                        onClick={() => switchTab('request')}
                        className={`pointer br-sm px-3 m-0 mx-2 ${ tabLink === 'request' ? 'light-bg' : '' }`}>
                        Requests
                        <span className="pry light-text px-1 ms-1 br-md small">
                            {pendingRequests}
                        </span>
                    </p>
                </div>

                {tabLink === 'notification' ?
                    <AllNotifications />
                    :
                    <AllRequest />
                }
            </StyledNotification>


        </AppWrapper>
    );
};

export default Notification;

const StyledNotification = styled.section`
    .all-notify {
        box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);
        
        span {
            border-radius: 50%;
           padding:.4rem ;
        }
        span svg {
            font-size:16px ;
            /* margin: 9px; */
        }
        small.date {
            font-size:12px;
        }
    }


`;