import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button } from "../../../styles";
import { ICON_QR, ICON_SWITCH_CAMERA } from "../../../utils/icons";
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import QrReader from "modern-react-qr-reader";
import AppLoader from "../../../utils/AppLoader";
import { useQrContext } from "../../../context/qr_context";
import ClaimQrFunds from "../fundAccount/ClaimQrFunds";


const ScanQr = () => {
  const [issending, setissending] = useState(false);

  const [scan, setScan] = useState(false);
  const [scanResult, setScanResult] = useState("");
  const [buttonUpload, setButtonUpload] = useState(false);
  const [cameraType, setcameraType] = useState(true);

  const { readQr, claimData } = useQrContext();
  const qrRef = useRef(null);


  const handleScanFile = async (result) => {
    if (result) {
      setScanResult(JSON.parse(result));
    }
  };

  const handleErrorFile = (error) => {
    toast.error(error.message);
  };

  const handleButtonUpload = () => {
    qrRef?.current?.openImageDialog();
  };

  function readQRCode() {
    if (scanResult?.qrCodeData) {
      readQr(scanResult, setissending);
    } else {
      toast('scanned failed');
    }
  }

  useEffect(() => {
    if (scanResult) {
      setScan(!scan);
      readQRCode();
    }
  }, [scanResult]);

  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <AppLoader
        load={issending}
      />
      <SendLayout
        title={'Scan QR Code'}>
        <div className="text-center mb-5">
          <h1 className="yellow-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
            {ICON_QR}
          </h1>
          <p>
            Scan the QR code or upload it to proceed.
          </p>
          <Button pry
            className="btn"
            onClick={() => setScan(!scan)}
          >
            {scan ? "Stop Scan" : " Scan QR Code "} {ICON_QR}
          </Button>
        </div>

        <div className="text-center">
          {scan && (
            <>
              <div className="my-4">
                {buttonUpload ? (
                  <>
                    <QrReader
                      ref={qrRef}
                      delay={100}
                      style={{ width: "100%" }}
                      onError={handleErrorFile}
                      onScan={handleScanFile}
                      legacyMode={"true"}
                    />
                  </>
                ) : (
                  <>
                    {scan && (
                      <div className="qr-scanner">
                        <h4 className="pry fit-content mx-auto p-2 br-xlg light-text shadow-sm"
                          onClick={() => setcameraType(!cameraType)}
                        >
                          {ICON_SWITCH_CAMERA}
                        </h4>
                        <QrReader
                          delay={100}
                          style={{ width: "100%", height: "100%" }}
                          onError={handleErrorFile}
                          onScan={handleScanFile}
                          facingMode={cameraType ? 'environment' : 'user'}
                        />

                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="">
                <Button
                  pry
                  onClick={() => setButtonUpload(!buttonUpload)}
                  type="button"
                  className="btn"
                >
                  {buttonUpload ? "Prev" : "Upload From Device"}
                </Button>
                {buttonUpload && (
                  <Button
                    pry
                    onClick={handleButtonUpload}
                    type="button"
                    className="btn"
                  >
                    Click To Upload

                  </Button>
                )}
              </div>
            </>
          )}
        </div>

      </SendLayout>

      <ClaimQrFunds
        {...claimData}
      />
    </AppWrapper >
  );
};

export default ScanQr;
