import { Modal } from "react-bootstrap";
import UpdateBVN from "../settings/UpdateBVN";


const AddBVN = (props) => {

      return (
            <Modal
                  show={props.show}
                  onHide={props.onHide}
                  backdrop="static"
                  keyboard={false}
                  centered
                  size="md"
            >
                  {/* <Modal.Header closeButton>
                        <p className="title pry-text m-0">
                              NOTIFICATION DETAILS
                        </p>
                  </Modal.Header> */}
                  <Modal.Body>
                        <UpdateBVN
                              setIsBVNAdded={props.setIsBVNAdded}
                        />
                        {/* <h6 className="fw-bold">
                              {subject}
                        </h6>
                        <small className="grey-bold-text">
                              {real_time}
                        </small>
                        <hr />
                        <p className=""
                              dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(body)
                              }}
                        /> */}
                  </Modal.Body>
            </Modal>
      );
};

export default AddBVN;