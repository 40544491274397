import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ICON_BANK, ICON_MARK_FILL } from "../../../utils/icons";
import { Button } from "../../../styles";
import { StyleConfirmFunds } from "../Wallet.styled";
import { useSendFundContext } from "../../../context/send_fund_context";
import AddBeneficiary from "./AddBeneficiary";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import EmptyData from "../../EmptyData";
import AppLoader from "../../../utils/AppLoader";
import Api from "../../../api/axios";
import { useUserContext } from "../../../context/user_context";


const AllBeneficiary = (props) => {

  const [toggleAdd, settoggleAdd] = useState(false);
  const [loadingName, setloadingName] = useState(false);

  const navigate = useNavigate();

  const { formField, selectedBank, setformField, setselectedBank, setselectUser } = props;

  const {
    beneficiary: { benList },
    confirmBen: { showConfirmBen, handleCloseConfirmBen },
  } = useSendFundContext();

  const { usertoken } = useUserContext();


  function getAcctName(form, bank) {
    if (
      bank && form?.acct_no?.length === 10
    ) {
      setloadingName(true);
      const data = {
        usertoken,
        recipientAccount: form.acct_no,
        bankCode: bank.value
      };
      Api.post(`/bankNameEnquiry`, data)
        .then((res) => {
          if (res.success === false) {
            setloadingName(false);
          } else {
            setloadingName(false);
            setselectUser(res.data);
            setformField(prevState => ({ ...prevState, u_name: res.data.name, SessionID: res.data.data.data.SessionID }));
          }
        })
        .catch((error) => {
          setloadingName(false);
        });
    } else {
      setloadingName(false);
    }
  }

  // useEffect(() => {
  // }, [selectedBank, formField.acct_no.length === 10]);
  // // Get Account NAme



  function selectBen(e) {
    const { form, bank } = e;

    setformField(form);
    setselectedBank(bank);
    setselectUser(form);
    getAcctName(form, bank);
  }

  function closeModal(e) {
    if (selectedBank && formField) {
      handleCloseConfirmBen();
    } else {
      toast.warn('select a beneficiary!');
    }
  }

  return (
    <>
      <Modal
        show={showConfirmBen}
        onHide={handleCloseConfirmBen}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header>
          <p className="title pry-text m-0">
            BENEFICIARIES
          </p>
        </Modal.Header>
        <Modal.Body>
          <StyleConfirmFunds>
            <AppLoader
              load={loadingName}
            />
            <div className="mb-5">
              <div className="text-end">
                <h6
                  className=" btn pry text-light"
                  onClick={() => settoggleAdd(!toggleAdd)}
                >
                  {toggleAdd ? ` All Beneficiary` : ` Add Beneficiaries`}
                </h6>
              </div>

              {toggleAdd ?
                <AddBeneficiary
                  toggleAdd={toggleAdd}
                  settoggleAdd={settoggleAdd}
                />
                :

                <div className=" my-4">
                  <EmptyData
                    data={benList?.data}
                    title='beneficiary'
                  />
                  {!benList?.data?.length &&
                    <Button
                      className="btn w-100 border"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                  }
                  {benList?.data?.length &&
                    <>
                      <p>
                        Select the Beneficiary you will like to send money.
                      </p>
                      {benList?.data?.map(({ name, nuban, bankcode, bankname }, i) =>
                        <div className="shadow-sm container py-2 mb-2 br-sm pointer"
                          key={i}
                          onClick={() => selectBen({
                            form: {
                              name: name,
                              acct_no: nuban,
                            },
                            bank: {
                              value: bankcode,
                              label: bankname,
                            }
                          })}
                        >
                          <div className="d-flex">
                            <h2 className=" m-0 me-3 shadow-sm br-xlg px-2 text-success">
                              {ICON_BANK}
                            </h2>
                            <div className="">
                              <h6 className="fw-bold m-0">
                                {name}
                              </h6>
                              <small className="m-0">
                                {nuban},
                              </small>
                              {' '}
                              <small className="m-0 grey-bold-text">
                                {bankname}
                              </small>
                            </div>
                            {nuban === formField.acct_no &&
                              <h3 className="ms-auto text-success">
                                {ICON_MARK_FILL}
                              </h3>
                            }
                          </div>
                        </div>
                      )}

                      <div className="mt-5 d-flex">
                        <Button
                          pry
                          className="btn w-50"
                          onClick={() => closeModal()}
                        >
                          Next
                        </Button>
                        <Button
                          className="btn w-50"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  }

                </div>
              }

            </div>

          </StyleConfirmFunds>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default AllBeneficiary;


