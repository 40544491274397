import { Link } from "react-router-dom";
import AppWrapper from "../../layout/AppWrapper";
import SendLayout from "../../layout/SendLayout";
import { StyledSendFund } from "./Wallet.styled";
import { addMoneyLinks } from '../../utils/data';
import styled from "styled-components";

const AddMoney = () => {
  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <StyledSendFund>
        <SendLayout
          title={'Add Money 💸'}>
          {
            addMoneyLinks.map(({ link, name, color, icon }, i) =>
              <Link to={link}>
                <div className={`shadow-sm d-flex align-items-center p-3 br-sm my-3 ${ color }`}>
                  <p className="m-0 br-lg p shadow-sm p-2 px-3">
                    {icon}
                  </p>
                  <h5 className="m-0 ps-4 fw-light">
                    {name}
                  </h5>
                </div>
              </Link>
            )
          }
        </SendLayout>

      </StyledSendFund>
    </AppWrapper >
  );
};

export default AddMoney;

export const StyledFundAccount = styled.div`

`;
