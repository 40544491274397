import { StyledAccount } from "../Profile/Profile.styled";
import UpdateBVN from "./UpdateBVN";
import UpdateNIN from "./UpdateNIN";
import UpdateAddress from "./UpdateAddress";
import UpdateId from "./UpdateId";


const KycSettings = () => {

    return (
        <StyledAccount>

            <div className="mt-5">
                <UpdateBVN />
                <UpdateNIN />
                <UpdateAddress />
                <UpdateId />

            </div>
        </StyledAccount>
    );
};

export default KycSettings;