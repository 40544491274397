import { useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../../api/axios';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, PasswordInput, Button } from '../../styles';
import { ICON_EYE_FILL, ICON_EYE_SLASH_FILL } from '../../utils/icons';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { encrypt } from '../../encrypt';
// import { PASSWORD_REGEX } from '../../utils/data';
import AuthWrapper from '../../layout/AuthWrapper';

const Login = () => {
    const [formField, setformField] = useState({});
    const navigate = useNavigate();
    const [sending, setsending] = useState(false);
    // const [pwordVal, setpwordVal] = useState(true);
    const [userData, setuserData] = useLocalStorage('userData');


    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields


    // useEffect(() => {
    //     PASSWORD_REGEX.test(formField.pword) ? setpwordVal(false) : setpwordVal(true);
    // }, [formField.pword]);

    // Submit Function
    function submitFn(e) {
        e.preventDefault();
              if (
            formField.pword &&
            formField.email
        ) {
            setsending(true);
            const data = {
                pword: formField.pword,
                email: formField.email,
            };
            Api.post(`/login`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        setsending(false);
                        navigate("/user/dashboard");
                        setuserData(encrypt(res.data));
                        setTimeout(() => {
                            toast.success(res.message);
                        }, 10);
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.warn("Empty Fields!");
        }
    }

    // Submit Function

    //Show Password Function 
    const [eye, seteye] = useState(true);

    return (
        <AuthWrapper
            title='Sign In to ReniTrust'
            subTitle='Your information are all safe and secure'
        >


            <Form onSubmit={(e) => submitFn(e)}>

                <div className="row justify-content-center text-start">
                    <div className="col-md-12 ">
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            value={formField.email}
                            required
                        />
                    </div>
                    <div className="col-md-12 password-input">
                        <div className="input-group">
                            <PasswordInput
                                type={eye ? 'password' : 'text'}
                                name="pword"
                                placeholder="Password"
                                onChange={handleChange}
                                value={formField.pword}
                                required
                            />
                            <span
                                className="toggle-eye"
                                onClick={() => seteye(!eye)}
                            >
                                {eye ? ICON_EYE_FILL : ICON_EYE_SLASH_FILL}
                            </span>

                        </div>
                        <span className=''>
                            <Link to="/reset-password" className="pry-text fw-bold small">
                                Forgot your password? </Link>
                        </span>
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="my-4">

                            <Button
                                pry
                                type="submit"
                                disabled={sending}
                                className="btn m-0 w-100"  >
                                {sending ? 'loading...' : "Login"}
                            </Button>
                        </div>
                        <div className="text-center dark-text">
                            New User?
                            <Link to="/register" className="pry-text fw-bold"> Create account </Link>
                        </div>
                    </div>
                </div>
            </Form>
        </AuthWrapper>

    );
};

export default Login;
