import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input, Label, NameInput, UserDetail } from '../../../styles';
import { ICON_BANK, ICON_EXCLAMATION, ICON_MARK_FILL } from '../../../utils/icons';
import ConfirmSendToBank from "./ConfirmSendToBank";
import AppLoader from "../../../utils/AppLoader";
import Api from '../../../api/axios';
import { useSendFundContext } from "../../../context/send_fund_context";
import { useUserContext } from '../../../context/user_context';
import { StyledSendFund } from '../Wallet.styled';
import { useWalletContext } from '../../../context/wallet_context';
import { Fee } from '../../../utils/helpers';
import CustomSelect from '../../../utils/CustomSelect';




const SendToBank = () => {

  const [formField, setformField] = useState({
    acct_no: '', amount: '', name: '', SessionID: '', narration: ''
  });
  const [proceed, setproceed] = useState(false);
  const [loadingName, setloadingName] = useState(false);
  const [selectUser, setselectUser] = useState('');
  const [selectedBank, setselectedBank] = useState('');
  const [disableSelectUser, setdisableSelectUser] = useState(false);


  const { usertoken } = useUserContext();

  const {
    getCharges: { charges },
  } = useWalletContext();

  const {
    banks: { allBanks, getBank },
    confirmBank: { handleShowConfirmBank }
  } = useSendFundContext();

  // Form Fields
  function handleChange(e) {
    setformField((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
  }
  // Form Field

  // Get Account NAme
  function getAcctName() {
    if (
      selectedBank && formField?.acct_no?.length === 10
    ) {
      setloadingName(true);
      const data = {

        usertoken,
        recipientAccount: formField.acct_no,
        bankCode: selectedBank.value
      };
      Api.post(`/bankNameEnquiry`, data)
        .then((res) => {
          if (res.success === false) {
            setloadingName(false);
          } else {
            setloadingName(false);
            setselectUser(res.data);
            setformField(prevState => ({ ...prevState, u_name: res.data.name, SessionID: res.data.data.data.SessionID }));
          }
        })
        .catch((error) => {
          setloadingName(false);
        });
    } else {
      setloadingName(false);
    }
  }

  useEffect(() => {
    getAcctName();
  }, [selectedBank, formField.acct_no.length === 10]);
  // Get Account NAme

  useEffect(() => {
    getBank();
  }, []);

  function checkSendDetails(e) {
    e.preventDefault();
    if (!selectedBank) {
      toast.warn('Select Bank!');
      return;
    }
    if (formField?.acct_no?.length !== 10) {
      toast.warn('Input Correct Account Number!');
      return;
    }
    if (!selectUser) {
      toast.warn('Unable to verify Bank Account!');
      return;
    }

    setproceed(true);
    setdisableSelectUser(true);
  }

  const data = { formField, selectedBank, selectUser };

  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <AppLoader load={loadingName} />
      <StyledSendFund>
        <SendLayout
          title={'Send to Bank Account'}>

          <div className="text-center">
            <h1 className="text-success display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
              {ICON_BANK}
            </h1>
          </div>
          {/* Send Form */}
          <Form
            className="my-5"
          >

            <div className="justify-content-center text-left">
              <div className="col-lg-12">
                <Label htmlFor="bank">
                  Bank:
                </Label>
                <CustomSelect
                  id="bank"
                  options={allBanks}
                  isSearchable={true}
                  onChange={(e) => setselectedBank(e)}
                  value={selectedBank}
                  placeholder='Select Banks'
                  required
                  isDisabled={disableSelectUser}
                />

              </div>

              <div className="col-lg-12 ">
                <Label htmlFor="acct_no">
                  Account Number:
                </Label>

                <NameInput
                  type="number"
                  name="acct_no"
                  id="acct_no"
                  placeholder="12345127890"
                  onChange={handleChange}
                  value={formField.acct_no}
                  required
                  disabled={disableSelectUser}
                />
                {formField.acct_no?.length === 10 &&
                  <>
                    <br />
                    <UserDetail className={selectUser ? 'text-success' : 'grey-bold-text -'}>
                      {selectUser ? ICON_MARK_FILL : ICON_EXCLAMATION}
                      {selectUser ? selectUser?.name : 'No User Found'}
                    </UserDetail>

                  </>
                }
                {!proceed &&
                  <div className="col-lg-12">
                    <Button
                      pry
                      className="btn w-100"
                      onClick={(e) => checkSendDetails(e)}
                    >
                      Next
                    </Button>
                  </div>
                }

                {proceed &&
                  <>
                    <div className="d-flex justify-content-between">
                      <Label htmlFor="amount">
                        Amount:
                      </Label> {' '}
                      <Fee fee={charges?.inter_transfer?.amount} />
                    </div>

                    <Input
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder={`Input Amount`}
                      className='mb-1'
                      onChange={handleChange}
                      value={formField.amount}
                      required
                    />

                    <Label htmlFor="narration">
                      Narration:
                    </Label>
                    <Input
                      type="text"
                      name="narration"
                      id="narration"
                      placeholder="Input Narration"
                      onChange={handleChange}
                      value={formField.narration}
                      required
                    />

                    <div className="col-lg-12">
                      <Button
                        pry
                        className="btn w-100"
                        onClick={(e) => handleShowConfirmBank(e, data)}
                      >
                        Proceed
                      </Button>
                    </div>
                  </>
                }
              </div>
            </div>
          </Form>

          {/* Send Form */}

        </SendLayout>
      </StyledSendFund>

      <ConfirmSendToBank
        {...data}
      />
    </AppWrapper >
  );
};

export default SendToBank;
