import Select from "react-select";
import styled from "styled-components";

export default function CustomSelect({
      options,
      placeholder,
      variant = "",
      id,
      isSearchable,
      onChange,
      value,
      required,
      isDisabled,
      ...props
}) {
      return (
            <StyledSelect>
                  <Select
                        {...props}
                        placeholder={placeholder}
                        options={options}
                        onChange={onChange}
                        defaultValue={value}
                        id={id}
                        isSearchable={isSearchable}
                        required={required}
                        isDisabled={isDisabled}

                        unstyled
                        className={"select-styles" + variant}
                        classNamePrefix={"select-prefix" + variant}
                  />
            </StyledSelect>
      );
}


const StyledSelect = styled.div`

/* Default Select STyle   */

.select-styles {
  display: block;
  width:100%;
  color: var(--pry-color);
  /* padding: 1rem 0; */
  border-color: var(--pry-color) 1px solid;
  
}

.select-prefix__value-container:focus {
  border-width: 2px;
  border-color: var(--pry-color) 1px solid;
}

.select-prefix__control--is-disabled{
      background-color: var(--grey-color) !important ;

}

.select-prefix__control {
  width: 100%;
  padding: 12px 10px;
  margin: 8px 0;
  border: 1px solid var(--grey-bold-color);
  box-sizing: border-box;
  border-radius: 5px !important;
  font-size: 13px;
  line-height: 24px;
  color: var(--dark-color);
  box-shadow: none;
  
  :hover,:focus{
        border: 1px solid var(--pry-color) !important;
        color: var(--pry-color);
    }
    ::placeholder{
        color: var(--grey-bold-color);
    } 

  }

.select-prefix__menu {
   background-color: var(--light-color) ;
  border: 1px var(--grey-color) solid;
  padding: 10px  ;
  border-radius: 8px;
  color: var(--pry-color);
  box-shadow: 0px 10px 24px 0px #6061701A;
  margin-top: 0.5rem;
  
}

.select-prefix__menu-list {
  
  overflow-y: auto;
  max-height: 200px;
  padding: 0.1rem;
  border-radius: 8px;
  color: var(--pry-color);
  background-color: var(--light-color) ;
  
}

.select-prefix__option {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  :hover {
    color: var(--pry-color);
    background-color: var(--sec-color) ;
  }
  
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 0.4;
 
}
.select-prefix__option--is-selected {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: var(--light-color);
  background-color: var(--pry-color) ;
}
.select-prefix__option--is-focused {
  padding: 0.5rem 1rem;
  border-radius: 8px;
   color: var(--pry-color);
    background-color: var(--sec-color) ;
}


/* Bill Variatnt */


.select-styles-bill {
  display: block;
  width:100%;
  color: var(--pry-color);
  /* padding: 1rem 0; */
  border-color: var(--pry-color) 1px solid;
  
}

.select-prefix-bill__value-container:focus {
  border-width: 2px;
  border-color: var(--pry-color) 1px solid;
}

.select-prefix-bill__control--is-disabled{
      background-color: var(--grey-color) !important ;

}

.select-prefix-bill__control {
  width: 100%;
    padding: 6px 1px;
    margin: 8px 0;
    border: 0;
    border-bottom: 1px solid var(--grey-bold-color);
    box-sizing: border-box;
    border-radius:0 !important;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--dark-color) !important;
    background-color: transparent;
    text-transform: uppercase;
  
     :hover,:focus{
       border: 0!important;
       border-bottom: 1px solid var(--pry-color) !important;
     
        }
        ::placeholder{
       font-size: 15px;
       font-weight: 400;    
        } 
  }

.select-prefix-bill__menu {
  width: 100%;
  background-color: var(--light-color) ;
  border: 1px var(--grey-color) solid;
  padding: 10px  ;
  border-radius: 8px;
  box-shadow: 0px 10px 24px 0px #6061701A;
  margin-top: 0.5rem;
  
}

.select-prefix-bill__menu-list {
  
  overflow-y: auto;
  max-height: 200px;
  padding: 0.1rem;
  border-radius: 8px;
  color: var(--pry-color);
  background-color: var(--light-color) ;
  
}

.select-prefix-bill__option {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  :hover {
    color: var(--pry-color);
    background-color: var(--sec-color) ;
  }
  
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 0.4;
 
}
.select-prefix-bill__option--is-selected {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  color: var(--light-color);
  background-color: var(--pry-color) ;
}
.select-prefix-bill__option--is-focused {
  padding: 0.5rem 1rem;
  border-radius: 8px;
   color: var(--pry-color);
    background-color: var(--sec-color) ;
}
/* Bill Variatnt */

`;
