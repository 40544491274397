import { useRef, useState } from "react";
import AppWrapper from "../../../layout/AppWrapper";
import SendLayout from "../../../layout/SendLayout";
import { Button, Form, Input } from "../../../styles";
import { ICON_CARD } from "../../../utils/icons";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Api from "../../../api/axios";
import { CARD_REGEX, CVV_REGEX, MONTH_REGEX, YEAR_REGEX, naira } from "../../../utils/data";
import AuthorizeCard from "./AuthorizeCard";
import { useUserContext } from "../../../context/user_context";
import { th } from "../../../utils/helpers";
import AppLoader from "../../../utils/AppLoader";
import SuccessCard from "./SuccessCard";
import { useNavigate } from "react-router-dom";


const FundCard = () => {

  const [formField, setformField] = useState('');
  const [sending, setsending] = useState(false);

  const [showCard, setShowCard] = useState(false);
  const [showPin, setShowPin] = useState(false);

  const [cardVal, setcardVal] = useState(true);
  const [cvvVal, setcvvVal] = useState(true);
  const [monthVal, setmonthVal] = useState(true);
  const [yearVal, setyearVal] = useState(true);

  // Authorization Modal
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  // Success Modal
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);
  const handleShowSuccess = () => setShowSuccess(true);

  const [auth, setAuth] = useState({
    payment_reference: '', auth_model: ''
  });

  const formRef = useRef(null);
  const { usertoken } = useUserContext();
  const navigate = useNavigate();

  // Form Fields
  function handleChange(e) {
    if (e.target.name === 'card') {
      const cardNumber = e.target.value.replace(/\D/g, '');
      setformField((inputs) => ({
        ...inputs,
        [e.target.name]: cardNumber,
      }));
    } else if (e.target.name === 'month') {
      const monthNumber = e.target.value.replace(/\D/g, '');
      setformField((inputs) => ({
        ...inputs,
        [e.target.name]: monthNumber,
      }));
    } else if (e.target.name === 'year') {
      const yearNumber = e.target.value.replace(/\D/g, '');
      setformField((inputs) => ({
        ...inputs,
        [e.target.name]: yearNumber,
      }));
    } else {
      setformField((inputs) => ({
        ...inputs,
        [e.target.name]: e.target.value,
      }));
    }
  }
  // Form Fields

  useEffect(() => {
    CARD_REGEX.test(formField.card) ? setcardVal(false) : setcardVal(true);
  }, [formField.card]);
  useEffect(() => {
    CVV_REGEX.test(formField.cvv) ? setcvvVal(false) : setcvvVal(true);
  }, [formField.cvv]);
  useEffect(() => {
    MONTH_REGEX.test(formField.month) ? setmonthVal(false) : setmonthVal(true);
  }, [formField.month]);
  useEffect(() => {
    YEAR_REGEX.test(formField.year) ? setyearVal(false) : setyearVal(true);
  }, [formField.year]);

  function formVal() {
    if (cardVal && !CARD_REGEX.test(formField.card)) {
      toast.warn("Invalid card number");
      return;
    }
    if (monthVal && !MONTH_REGEX.test(formField.month)) {
      toast.warn("Invalid month");
      return;
    }
    if (yearVal && !YEAR_REGEX.test(formField.year)) {
      toast.warn("Invalid year");
      return;
    }
    if (cvvVal && !CVV_REGEX.test(formField.cvv)) {
      toast.warn("Invalid CVV");
      return;
    }
    if (!formField.amount) {
      toast.warn("Input Amount");
      return;
    }
    return true;
  }


  // Submit Function
  function submitFn(e) {
    e.preventDefault();

    if (!formVal()) {
      return;
    }
    if (!formField.pin) {
      toast.warn("Input PIN");
      return;
    }
    if (window.confirm(`Fund your account with ${ naira }${ th(formField?.amount) }, Confirm?`)) {
      setsending(true);
      const data = {
        usertoken,
        number: formField.card,
        cvv: formField.cvv,
        expiry_month: formField.month,
        expiry_year: formField.year,
        amount: formField.amount,
        pin: formField.pin,
      };
      Api.post(`/pay.Card`, data)
        .then((res) => {
          if (res.success === false) {
            toast.warn(res.message);
            setsending(false);
          } else {
            setsending(false);
            if (res.data?.data?.status === "success") {
              toast.success(res.message);
              setsending(false);
              handleShowSuccess();
            } else if (res.data?.data?.status === "processing") {
              setAuth(res.data.data);
              if (res.data?.data?.auth_model === '3DS') {
                toast.success(res.data?.data?.response_message);
                setTimeout(() => {
                  window.open(res.data?.data?.redirect_url, '_blank');
                  alert('Your payment is processing, complete your payment and return to homepage...');
                  navigate('/user/dashboard');
                }, 1500);
              } else {
                setsending(false);
                handleShowModal();
              }
            } else {
              setsending(false);
              toast.success(res.message);
            }
          }
        })
        .catch((error) => {
          setsending(false);
        });
    }
  }

  // Submit Function

  function pinConfirm() {
    if (!formVal()) {
      return;
    }
    setsending(true);
    setTimeout(() => {
      setsending(false);
      setShowPin(true);
    }, 2000);
  }



  return (
    <AppWrapper
      pagename={"Wallet"}
    >
      <AppLoader
        load={sending}
      />
      <SendLayout
        title={'Fund with Card'}>
        <div className="text-center mb-5">
          <h1 className="red-text display-1 shadow-sm fit-content br-xlg p-2 px-3 mx-auto mb-4">
            {ICON_CARD}
          </h1>
          <p>
            Fund your ReniTrust account using a debit card
          </p>
        </div>
        <div className="my-5 text-center">

          {!showCard &&
            <Button pry className="btn"
              onClick={() => setShowCard(true)}
            >
              Fund with Card
            </Button>
          }
          {showCard &&

            <StyledFundCard>

              <Form ref={formRef} onSubmit={(e) => submitFn(e)} >
                <div className="row justify-content-center">
                  <div className="col-md-10 ">
                    <Input
                      type="text"
                      name="card"
                      placeholder="0000 0000 0000 0000"
                      onChange={handleChange}
                      value={formField.card}
                      // required
                      maxLength={16}
                    />
                  </div>
                  <div className="col-6 col-md-5 ">
                    <StyledExpiry>
                      <input
                        type="text"
                        name="month"
                        placeholder="MM"
                        onChange={handleChange}
                        value={formField.month}
                        // required
                        maxLength={2}

                      />
                      /
                      <input
                        type="text"
                        name="year"
                        placeholder="YY"
                        onChange={handleChange}
                        value={formField.year}
                        // required
                        maxLength={2}

                      />

                    </StyledExpiry>
                  </div>
                  <div className="col-6 col-md-5 ">
                    <Input
                      type="number"
                      name="cvv"
                      placeholder="CVC/CVV"
                      onChange={handleChange}
                      value={formField.cvv}
                    // required
                    />
                  </div>
                  <div className="col-md-10 ">
                    <Input
                      type="text"
                      name="name"
                      placeholder="Card Holder's Name"
                      onChange={handleChange}
                      value={formField.name}
                    />
                  </div>
                  <div className="col-md-10 ">
                    <Input
                      type="number"
                      name="amount"
                      placeholder="Amount"
                      onChange={handleChange}
                      value={formField.amount}
                      autoComplete="off"
                    // required
                    />
                  </div>

                  {!showPin &&
                    <div className="col-md-12 text-center">
                      <div className="my-4">
                        <a className="btn w-100 pry light-text"
                          onClick={() => pinConfirm()}
                        >
                          Proceed
                        </a>
                      </div>
                    </div>
                  }

                  {showPin &&
                    <>
                      <div className="col-md-10 ">
                        <Input
                          type="password"
                          name="pin"
                          placeholder="Pin"
                          onChange={handleChange}
                          value={formField.pin}
                          autoComplete="off"
                        // required
                        />
                      </div>
                      <div className="col-md-12 text-center">
                        <div className="my-4">
                          <Button
                            pry
                            type="submit"
                            disabled={sending}
                            className="btn m-0 w-100"  >
                            {sending ? 'loading...' : "Confirm"}
                          </Button>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </Form>
            </StyledFundCard>
          }

        </div>
      </SendLayout>

      <AuthorizeCard
        show={showModal}
        onHide={handleCloseModal}
        auth={auth}
        setAuth={setAuth}
      />

      <SuccessCard
        show={showSuccess}
        onHide={handleCloseSuccess}
      />
    </AppWrapper >
  );
};

export default FundCard;

const StyledFundCard = styled.div`
input {
  font-size: 15px;
  font-weight: 600;   
}
`;

const StyledExpiry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-size: 30px;
  border: 1px solid var(--grey-bold-color);
  width: 100%;
  margin: 8px 5px;
  box-sizing: border-box;
  border-radius: 5px !important;
  color: var(--dark-color);
  box-shadow: none;
  margin-bottom: 1.5rem !important;
  background-color: var(--light-color);
  
  
  
  input {
    width: 100%;
    padding: 8px 20px;
    display: inline-block;
    box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 5px !important;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--dark-color);
  box-shadow: none;
  background-color: var(--light-color);

}
`

