import styled from "styled-components";
import { hero } from "../../assets";
import { Button } from "../../styles";
import { Link } from "react-router-dom";

const Hero = () => {
      return (
            <StyledHero>
                  <div className="row align-items-center my-5">
                        <div className="col-md-6 text-center">
                              <img src={hero} alt="Hero" />
                        </div>
                        <div className="col-md-6 text-center text-md-start">
                              <h1>
                                    Payment powered by escrow
                              </h1>
                              <p>
                                    Pay for product and services seemless and safely protected by an escrow system.
                              </p>
                              <Link to='/register'>
                                    <Button pry className='btn br-md'>
                                          Get Started
                                    </Button>
                              </Link>
                        </div>
                  </div>
            </StyledHero>
      );
};

export default Hero;


const StyledHero = styled.section`

.row {
      @media screen and (max-width: 768px) {
      flex-direction: column-reverse !important;
      
      img {
            margin-top: 4rem;
      }
      }
}
img{
      width:50vh;
      
      @media screen and (max-width: 2024px) {
            width:30vh;  
      }
      @media screen and (max-width: 1024px) {
            width:28vh;  
      }
  @media screen and (max-width: 580px) {
            width:100% !important;  
      }
}

h1 {
font-size: 3rem;
color: var(--home-pry);
}
 p{
 font-size: 20px;
 line-height: 30px;
 color: var(--home-pry);
}

`;