import styled from "styled-components";


export const StyledWallet = styled.div`

`;

export const StyledSendFund = styled.div`
        .recent-name p{
          margin: 0;
        font-size: 10px;
        line-height: 1.1;
}

        .name-head{
          width: 25px;
        height: 25px;
        font-weight: 700 !important;
        font-size: larger; 
}

        .name_label{
         font-size: 20px;
}

        img {
        width: 75px;
        height: 75px;
        border-radius: 50%;
}

label {
  font-size: 14px;
}

`;


export const StyleConfirmFunds = styled.div`

`;