import { useEffect, useState } from "react";
import { useBillsContext } from "../../../context/bill_context";
import { Form, Label, Input, Button } from "../../../styles";
import Select from 'react-select';
import { toast } from "react-toastify";
import ConfirmAirtime from "./ConfirmAirtime";
import { naira } from "../../../utils/data";
import BillWrapper from "../../../layout/BillWrapper";
import { useWalletContext } from "../../../context/wallet_context";
import { Fee } from "../../../utils/helpers";
import CustomSelect from "../../../utils/CustomSelect";


const BuyAirtime = () => {

    const [formField, setformField] = useState({ phone: '', amount: '' });
    const [selectedBiller, setselectedBiller] = useState('');

    const {
        airtime: { allAirtimeBiller, allAirtimeBillerLoad, getAirtimeBiller }
    } = useBillsContext();

    const {
        getCharges: { charges },
    } = useWalletContext();

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Field

    // Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        if (!selectedBiller?.value) {
            toast.warn('Select a Service Provider!');
            return;
        }
        if (!formField?.phone || formField?.phone.length !== 11) {
            toast.warn('Input Valid Phone Number!');
            return;
        }
        if (!formField?.amount) {
            toast.warn('Input Amount!');
            return;
        }
        setShow(true);
    };
    // Modal

    const data = { formField, selectedBiller };

    useEffect(() => {
        getAirtimeBiller();
    }, []);

    return (
        <BillWrapper
            title='Airtime'
            load={allAirtimeBillerLoad}
        >

            <Form>
                <div className="col-lg-6 input-col">
                    <Label htmlFor="operator">
                        SERVICE PROVIDER:
                    </Label>
                    <CustomSelect
                        variant="-bill"
                        id="biller"
                        options={allAirtimeBiller}
                        isSearchable={true}
                        onChange={(e) => setselectedBiller(e)}
                        value={selectedBiller}
                        placeholder='Select Service Provider'
                        required
                    />
                </div>

                <div className="col-lg-6 input-col">
                    <Label htmlFor="number">
                        PHONE NUMEBR
                    </Label>
                    <Input
                        type="number"
                        name="phone"
                        placeholder="08012345678"
                        onChange={handleChange}
                        value={formField.phone}
                        required
                        className="input-style"

                    />
                </div>
                <div className="col-lg-6 input-col">
                    <div className="d-flex justify-content-between">
                        <Label htmlFor="amount">
                            AMOUNT({naira}) ?
                        </Label>
                        {' '}
                        <Fee fee={charges?.local_transfer?.amount} />
                    </div>
                    <Input
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        onChange={handleChange}
                        value={formField.amount}
                        required
                        className="input-style"
                    />
                </div>

                <div className="my-4 col-lg-6">
                    <Button
                        pry
                        className="btn m-0 w-100"
                        onClick={(e) => handleShow(e)}
                    >
                        Proceed
                    </Button>
                </div>
            </Form>
            <ConfirmAirtime
                show={show}
                onHide={handleClose}
                {...data}
            />
        </BillWrapper>
    );
};

export default BuyAirtime;