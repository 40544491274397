import React, { useContext } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../api/axios';
import FetchApi from '../api/getAxios';
import { useUserContext } from './user_context';

export const PayLinkContext = React.createContext();


export const PayLinkProvider = ({ children }) => {


    // Create new PayLink Actions
    const [payLinkData, setpayLinkData] = useState('');
    const [payLinkDataLoading, setpayLinkDataLoading] = useState(false);

    const { usertoken } = useUserContext();

    const [historyData, sethistoryData] = useState([]);
    const [payLinkLoading, setpayLinkLoading] = useState(false);
    const [allPayLink, setallPayLink] = useState([]);

    // Get all PayLink Trx
    const getPayLink = async (e) => {
        setpayLinkLoading(true);
        const data = {
            usertoken,
        };
        FetchApi.post(`/paymentLink.FetchAll`, data)
            .then((res) => {
                if (res.success === false) {
                    setpayLinkLoading(false);
                } else {
                    setpayLinkLoading(false);
                    setallPayLink(res.data);
                }
            })
            .catch((error) => {
                setpayLinkLoading(false);
            });
    };

    // Get all PayLink Trx

    // Search PayLink Trx
    function searchTrx(key) {
        if (key) {
            const filteredTrx = allPayLink.filter((trx) => {
                const titleMatch = trx?.title.toLowerCase().includes(key.toLowerCase());
                const amountMatch = trx?.amount.includes(key);
                const tokenMatch = trx?.token.includes(key);
                return titleMatch || amountMatch || tokenMatch;
            });
            sethistoryData(filteredTrx);
        } else {
            sethistoryData(allPayLink);
        }
    }
    // Search PayLink Trx

    // getPayLink Data Fn
    function getPayLinkData(token) {
        if (token) {
            setpayLinkDataLoading(true);
            const data = { usertoken, token };
            FetchApi.post(`/paymentLink.FetchData`, data)
                .then((res) => {
                    if (res.success === false) { setpayLinkDataLoading(false); } else {
                        setpayLinkDataLoading(false);
                        setpayLinkData(res.data.data);
                    }
                }).catch((error) => { setpayLinkDataLoading(false); });
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewPayLink(false); }
    }
    // getPayLink Data Fn


    // cancel PayLink Fn
    function cancelPayLink(payLinkToken) {
        if (payLinkToken) {
            if (window.confirm(`You are about to cancel this PayLink transaction, this is not reversible. Confirm?`)) {
                setpayLinkDataLoading(true);
                const data = { usertoken, payLinkToken };
                Api.post(`/cancelPayLink`, data)
                    .then((res) => {
                        if (res.success === false) { setpayLinkDataLoading(false); toast.warn(res.message); } else {
                            setpayLinkDataLoading(false);
                            toast.success(res.message);
                            getPayLinkData(payLinkToken);
                        }
                    }).catch(() => { setpayLinkDataLoading(false); });
            }
        } else { toast.warn('Trx ID not set!'); setShowConfirmNewPayLink(false); }
    }
    // cancel PayLink Fn

    // Create new PayLink Actions


    // ConfirmNewPayLink
    const [showConfirmNewPayLink, setShowConfirmNewPayLink] = useState(false);
    const handleCloseConfirmNewPayLink = () => setShowConfirmNewPayLink(false);
    const handleShowConfirmNewPayLink = () => setShowConfirmNewPayLink(true);
    // ConfirmNewPayLink


    return (
        <PayLinkContext.Provider value={{
            getAllPayLink: { payLinkLoading, allPayLink, historyData, getPayLink,  searchTrx, setallPayLink },
            payLink: { payLinkData, payLinkDataLoading, getPayLinkData },
            payLinkAction: { cancelPayLink},

            confirmNewPayLink: { showConfirmNewPayLink, handleCloseConfirmNewPayLink, handleShowConfirmNewPayLink },

        }}>
            {children}
        </PayLinkContext.Provider>
    );
};

// make sure use
export const usePayLinkContext = () => {
    return useContext(PayLinkContext);
};
