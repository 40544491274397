import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../api/useFetch';
import { useLocalStorage } from '../utils/useLocalStorage';
import { decrypt, encrypt } from '../encrypt';
import FetchApi from '../api/getAxios';

export const UserContext = React.createContext();


export const UserProvider = ({ children }) => {
    const [user_data, setuser_data] = useLocalStorage('userData');
    const userdata = JSON.parse(localStorage.getItem('userData'));
    const verdata = JSON.parse(localStorage.getItem('verData'));
    const [userPhoto, setUserPhoto] = useState('');

    let userData = decrypt(userdata);
    let verData = decrypt(verdata);

    const location = useLocation();
    let path = location.pathname;

    function getUserData() {
        const data = {
            usertoken: userData?.usertoken,
        };
        FetchApi.post(`/getUserData-bio`, data)
            .then((res) => {
                if (res.success === false) {
                } else {
                    setuser_data(encrypt(res?.data?.data || ''));
                }
            });
    }

    function getUserPhoto() {
        const data = {
            usertoken: userData?.usertoken,
        };
        FetchApi.post(`/getUserData-photo`, data)
            .then((res) => {
                if (res.success === false) {
                } else {
                    setUserPhoto(res?.data?.data?.photo);
                }
            });
    }

    const { data: stats, fetchData: getStats } = useFetch('fetchStats');
    const { data: referrals } = useFetch('fetchReferrals');

    useEffect(() => {
        getUserData();
        getStats();
    }, [path]);

    useEffect(() => {
        getUserPhoto();
    }, []);


    return (
        <UserContext.Provider value={{
            userData, getUserData,
            userPhoto, getUserPhoto,
            verData,
            usertoken: userData?.usertoken,
            stats,
            referrals: referrals?.data
        }}>
            {children}
        </UserContext.Provider>
    );
};

// make sure use
export const useUserContext = () => {
    return useContext(UserContext);
};
