import styled from "styled-components";
import { about } from "../../utils/data";

const About = () => {


      return (
            <StyledAbout>
                  <div className="col-md-7 mb-5">
                        <h1 className="mb-4">
                              Keeping your money
                              safe with escrow is our business.
                        </h1>
                        <p>
                              We are committed to providing the most secure, simple and trustworthy systems.
                        </p>
                  </div>

                  <div className="row py-5">
                        {
                              about?.map(({ title, content }, i) => {
                                    return (
                                          <div className="col-md-6 about-card mb-4" key={i}>
                                                <h5 className="mb-4">{title} </h5>
                                                <p className="mb-">{content} </p>
                                          </div>
                                    );
                              })
                        }
                  </div>

            </StyledAbout>
      );
};

export default About;


const StyledAbout = styled.section`
      padding: 5rem 0;
      color: var(--home-grey-bold);

`;