import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Api from '../../api/axios';
import { Form, Input, Button } from '../../styles';
import AuthWrapper from '../../layout/AuthWrapper';

const ForgetPassword = () => {
    const [formField, setformField] = useState({});
    const navigate = useNavigate();
    const [sending, setsending] = useState(false);

    // Form Fields
    function handleChange(e) {
        setformField((inputs) => ({
            ...inputs,
            [e.target.name]: e.target.value,
        }));
    }
    // Form Fields

    // Submit Function
    function submitFn(e) {
        e.preventDefault();
        if (
            formField.email
        ) {
            setsending(true);
            const data = {

                email: formField.email,
            };
            Api.post(`/forgotPassword`, data)
                .then((res) => {
                    if (res.success === false) {
                        toast.warn(res.message);
                        setsending(false);
                    } else {
                        setsending(false);
                        navigate("/login");
                        setTimeout(() => {
                            toast.success(`${ res.message }`);
                        }, 10);
                    }
                })
                .catch((error) => {
                    setsending(false);
                });
        } else {
            setsending(false);
            toast.error("enter valid email");
        }
    }

    // Submit Function

    return (
        <AuthWrapper
            title='Forget Password'
            subTitle='Forgotten your ReniTrust Password? Enter your email to reset your password.'
        >
            <Form onSubmit={(e) => submitFn(e)}>

                <div className="row justify-content-center text-start">
                    <div className="col-md-12 ">
                        <Input
                            type="email"
                            name="email"
                            placeholder="Enter Email"
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className="col-md-12 text-center">
                        <div className="mb-4">
                            <Button
                                pry
                                type="submit"
                                disabled={sending}
                                className="btn m-0 w-100"  >
                                {sending ? 'loading...' : "submit"}
                            </Button>
                        </div>
                        <div className="text-center">
                            Back to
                            <Link to="/login" className="pry-text fw-bold"> Login </Link>
                        </div>

                    </div>
                </div>
            </Form>
        </AuthWrapper>

    );
};

export default ForgetPassword;
