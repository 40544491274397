import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import Api from '../api/axios';
import { useFetch } from '../api/useFetch';
import { naira } from '../utils/data';
import { th } from '../utils/helpers';
import { useUserContext } from './user_context';


export const BillsContext = React.createContext();


export const BillsProvider = ({ children }) => {

    const { usertoken } = useUserContext();

    // Electricity
    const { loading: allElectricityBillerLoad, data: electricityBiller, fetchData: getElectricityBill } = useFetch('bills-getElectricityBillers');

    const allElectricityBiller = electricityBiller?.data?.billers?.map(({ short_name, type, image }, i) => {
        return (
            { label: short_name, value: type, image: image }
        );
    });

    const [meterLoading, setmeterLoading] = useState(false);
    const [message, setmessage] = useState('');

    // Get Meter Name
    function getMeterName(setselectedMeter, formField, setformField, selectedBiller) {
        if (
            selectedBiller && formField?.number > 10
        ) {
            setmeterLoading(true);
            const data = {
                usertoken,
                number: formField.number,
                type: selectedBiller.value
            };
            Api.post(`/bills-validateMeter`, data)
                .then((res) => {
                    if (res.success === false) {
                        setmeterLoading(false);
                        toast.warn(res.data.data.message);
                        setmessage(res.data.data.message);
                    } else {
                        setmessage(res.data.message);
                        setmeterLoading(false);
                        setselectedMeter(res.data.data.customer);
                        setformField(prevState => ({
                            ...prevState,
                            name: res.data.data.customer?.customerName,
                            disco: res.data.data.customer?.disco,
                            type: res.data.data.customer?.billerType,
                            address: res.data.data.customer?.customerAddress,

                        }));
                    }
                })
                .catch((error) => {
                    setmeterLoading(false);
                });
        } else {
            setmeterLoading(false);
        }
    }

    // Get Meter Name

    // Electricity

    // Airtime
    const { loading: allAirtimeBillerLoad, data: airtimeBiller, fetchData: getAirtimeBiller } = useFetch('bills.fetchTelecomProviders');

    const allAirtimeBiller = airtimeBiller?.data?.map(({ network, service, data }, i) => {
        return (
            { label: network, value: service, data, network, service }
        );
    });

    // Airtime

    // Data
    const [dataPack, setdataPack] = useState([]);
    const [dataLoader, setdataLoader] = useState(false);

    function getDataPack(e, setselectedDataBiller) {
        const data = {
            usertoken, data: e
        };
        setselectedDataBiller({ label: '', value: '', amount: '' });
        setdataLoader(true);
        Api.post(`/bills.dataLookUp`, data)
            .then((res) => {
                if (res.success === false) {
                    setdataLoader(false);
                } else {
                    setdataLoader(false);
                    setdataPack(res.data.data.data);
                }
            }).catch(() => {
                setdataLoader(false);
            });
    }

    const allDataBiller = dataPack?.map(({ amount, code, description, duration, type, value, price }, i) => {
        return (
            { label: `${ duration } - ${ value } (${ naira }${ th(amount) })`, amount, code, description, duration, type, value, price }
        );
    });
    // Data

    // Tv
    const { loading: allTvProviderLoad, data: tvProvider, fetchData: getTVProvider } = useFetch('bills-cableTVProviders');

    const allTvProvider = tvProvider?.data?.map(({ name, type, image, narration }, i) => {
        return (
            { label: name, value: type, name, type, image, narration }
        );
    });

    const [tvPack, settvPack] = useState([]);
    const [tvLoader, settvLoader] = useState(false);

    function getTvPack(e, setselectedTvBiller) {
        const data = {
            usertoken, type: e
        };
        setselectedTvBiller({ label: '', value: '', amount: '' });
        settvLoader(true);
        Api.post(`/bills-fetchCableBillers`, data)
            .then((res) => {
                if (res.success === false) {
                    settvLoader(false);
                } else {
                    settvLoader(false);
                    settvPack(res.data.data);
                }
            });
    }
    const allTvBiller = tvPack?.map(({ name, amount, price, code, description, }, i) => {
        return (
            { label: `${ name } - (${ naira }${ th(amount) })`, value: code, name, amount, price, code, description }
        );
    });
    // Tv

    return (
        <BillsContext.Provider value={{
            electricity: { allElectricityBiller, getMeterName, meterLoading, allElectricityBillerLoad, getElectricityBill, message },
            airtime: { allAirtimeBiller, getAirtimeBiller, allAirtimeBillerLoad },
            data: { allDataBiller, getDataPack, dataLoader },
            tv: { allTvBiller, allTvProvider, allTvProviderLoad, getTvPack, tvLoader, getTVProvider },

        }}>
            {children}
        </BillsContext.Provider>
    );
};

// make sure use
export const useBillsContext = () => {
    return useContext(BillsContext);
};
