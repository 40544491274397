import { Link } from 'react-router-dom';
import { user } from '../../assets';
import { useUserContext } from '../../context/user_context';
import { ICON_BELL, ICON_LIST } from '../../utils/icons';
import { Hamburger, StyledTopNav } from './Nav.styled';
import { ThemeToggle } from '../../utils/ThemeToggle';

const TopNav = ({ pagename }) => {

    // Open Navbar 
    const openNav = (e) => {
        document.getElementById("mySidenav").style.width = "90vw";
    };
    // Open Navbar

    const {
        userPhoto,
        stats
    } = useUserContext();


    const unread = (stats?.unreadNotes + stats?.pendingRequests) || '0';

    return (
        <StyledTopNav
            className="navbar bottom-border navbar-expand-sm navbar-light fixed-top"
        >
            <div className="container-fluid">
                <div className="d-flex">
                    <Hamburger
                        onClick={(e) => openNav(e)}
                    >
                        {ICON_LIST}
                    </Hamburger>

                    <span className="page-title">
                        {pagename}
                    </span>
                </div>
                <li className="list-unstyled mr-auto ml-5 pl-4 d-flex">
                    <div className="d-none d-sm-block">
                        <ThemeToggle />
                    </div>
                    <Link to='/user/notification'
                        className='grey-bold-text mx-2'
                    >
                        <span className="position-relative" >
                            {ICON_BELL}
                            {unread >= 1 &&
                                <span className="position-absolute bell translate-middle badge m-0 rounded-pill pry light-text "
                                >
                                    {unread > 99 ? '99+' : unread}
                                </span>
                            }
                        </span>
                    </Link>
                    <Link to='/user/profile'
                        className='grey-bold-text mx-2'
                    >
                        <img src={userPhoto || user} alt="user_picture" />
                    </Link>
                </li>

            </div>
        </StyledTopNav>
    );
};

export default TopNav;