import axios from 'axios';
import { toast } from 'react-toastify';
import { base64ToObject, objectToBase64 } from '../encrypt';

const Api = axios.create({
    baseURL: process.env.REACT_APP_ENDPOINT,
    headers: {
        'Authorization': `Bearer ${ objectToBase64(process.env.REACT_APP_APPTOKEN) }`
    }
});

Api.interceptors.request.use(
    (request) => {
        let encPayload = objectToBase64(request.data);
        let newRequest = {
            ...request, data: encPayload
        };
        return newRequest;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Api.interceptors.response.use(
    (response) => {
        return base64ToObject(response.data);
    },
    (error) => {
        if (error.response.data) {
            toast.warn(error.response.data.message);
        } else {
            toast.error(error.message === "Request failed with status code 500" ? "Internal Error!" : error.message);
        }
        return Promise.reject(error);
    }
);

export default Api;
