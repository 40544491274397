import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { naira } from '../../utils/data';
import { ICON_ARROW_DOWN_CIRCLE, ICON_ARROW_UP_CIRCLE } from '../../utils/icons';
import { Button, Form, Input, StyledTab } from '../../styles';
import EmptyData from '../EmptyData';
import { useWalletContext } from '../../context/wallet_context';
import { Link, useLocation } from 'react-router-dom';

const TransactionHistory = ({ reduce }) => {

    const {
        getTrxHist: { getTrxHist, selectData, historyData, searchTrx }
    } = useWalletContext();
    const [selectedLink, setselectedLink] = useState('all');


    const links = ['all', 'Debit', 'Credit'];

    useEffect(() => {
        getTrxHist();
    }, []);

    return (
        <StyledHistory>
            <div className="transaction-history px-2 px-lg-4 br-md py-4">
                <div className="d-flex align-items-center">
                    <div className="col">
                        <p className="fw-bold pt-3">
                            Transaction History
                        </p>
                    </div>
                    <div className="col">
                        <StyledTab className='ms-auto'>
                            {links.map((e, i) =>
                                <div className={`links ${ e === selectedLink && 'active' }`}
                                    key={i}
                                    onClick={() => selectData(e, setselectedLink)}
                                >{e}</div>
                            )}
                        </StyledTab>
                    </div>
                </div>

                <div className="">
                    <Input
                        type="search"
                        name="search"
                        placeholder="Search Transaction (name, title, amount, trx ID)"
                        onChange={(e) => searchTrx(e.target.value, setselectedLink)}
                    // value={formField.email}
                    />
                </div>

                <EmptyData
                    data={historyData}
                    title='transaction'
                />

                {historyData?.slice(0, reduce)?.map((data) => {
                    return <TrxList key={historyData.id} {...data} />;
                })}

                {reduce &&
                    <>
                        {
                            historyData?.length !== 0 &&
                            <div className="text-center">
                                <Link to='/user/wallet/transactions'>
                                    <Button
                                        className='btn border border-primary shadow-none pry-text fw-bold'
                                    >
                                        View More
                                    </Button>
                                </Link>
                            </div>
                        }
                    </>
                }
            </div>
        </StyledHistory >
    );
};

export default TransactionHistory;

export const TrxList = ({ reference_number, title, r_userData, amount_th, type, id, real_time }) => {

    const { name } = r_userData;

    return (
        <>
            <Link to={`/user/wallet/transactions/${ reference_number }`} className='dark-text'>
                <div className="history d-flex justify-content-between py-2" key={id}>
                    <div className="d-flex justify-content-center">
                        <div className=" me-2 ">
                            {type === "Credit" ?
                                <span className="text-success h3">
                                    {ICON_ARROW_DOWN_CIRCLE}
                                </span>
                                :
                                <span className="red-text h3">
                                    {ICON_ARROW_UP_CIRCLE}
                                </span>
                            }
                        </div>
                        <div className="col">
                            <h6 className="fw-bold m-0">

                                {name}
                                - {title}

                            </h6>
                            <p className='m-0'>
                                Trx ID: {' '}
                                <span className="fw-bold">
                                    {reference_number}
                                </span>
                            </p>
                            <p className='m-0'>
                                {real_time}
                            </p>
                        </div>
                    </div>
                    <div className="col-3 text-end">
                        <h6 className={type === "Credit" ? "text-success" : "red-text"}>
                            {naira}{amount_th}
                        </h6>
                    </div>
                </div>
            </Link>
        </>
    );
};






export const StyledHistory = styled.div`

.transaction-history {
    box-shadow: 1.06709px 0px 4.26834px rgba(0, 0, 0, 0.4);
    
    .history {
        h6{
            font-size: 12px;
        }
        p{
            font-size: 8px;
        }
    }

    .history:hover {
        background: var(--light-bold-color);
    }
}



`;